import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/stores/outlet.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";

const OutletsList = () => {
  const { hasPermission, isVendorOrOutlet } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);
  const navigate = useNavigate();
  const getOutletsList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    1;
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .outletList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
      id: idFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if(!pagination){
      pagination = initialPagination
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getOutletsList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    if (isVendorOrOutlet) {
      getOutletsList();
    } else {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];

      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
          vendor.business_name.toLowerCase().includes("near")
        );
        if (vendor) {
          selectedVendor = vendor;
        }
      }

      setSelectedVendor({
        ...selectedVendor,
        value: selectedVendor?.id,
        label: selectedVendor?.business_name,
      });
    }
  }, []);

  const onConfirmDelete = async (id) => {
    await httpService
      .deleteOutlet(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("outlet.outletDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getOutletsList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewOutlet = () => {
    navigate(all_routes.outletsCreate);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams.pagination?.current - 1) *
          paginationParams.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.outletId"),
      dataIndex: "outlet_code",
      sorter: true,
    },
    {
      title: t("inputField.label.outletName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.image}
            alt="Outlet"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.ownerName"),
      dataIndex: "owner_name",
      sorter: true,
    },
    {
      title: t("tableColumnName.phone"),
      dataIndex: "phone",
    },
    {
      title: t("tableColumnName.email"),
      dataIndex: "email",
      sorter: true,
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.active")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      align:"center",
      hidden: !hasPermission(["outlet-view", "outlet-edit", "outlet-delete"]),
      render: (text, record) => (
        <div className="action-table-data" style={{minWidth:"140px"}}>
          <div className="edit-delete-action ">
            {(hasPermission(["outlet-view"]) && record.nvr_url) && (
              <a
                className="p-2"
                href={record.nvr_url}
                target="blank"
                title={`UserName:${record.nvr_username} Password:${record.nvr_password}`}
              >
                <ImageWithBasePath
                  src="assets/img/icons/cctv-camera-icon.png"
                  alt="img"
                  height={"18px"}
                />
              </a>
            )}
            {/* {hasPermission(["outlet-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/outlets/${record.id}/layout`)}
              >
                <i className="feather-align-justify"></i>
              </a>
            )} */}
            {hasPermission(["outlet-view"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/outlets/${record.id}/details`)}
              >
                <i className="feather-eye"></i>
              </a>
            )}
            {hasPermission(["outlet-edit"]) && (
              <a
                className="p-2"
                onClick={() => navigate(`/outlets/${record.id}/edit`)}
              >
                <i className="feather-edit-2 text-black"></i>
              </a>
            )}
            {hasPermission(["outlet-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </div>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("outlet.outlet"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("outlet.outletList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["outlet-add"]) && (
              <div className="page-btn">
                <button onClick={addNewOutlet} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {!isVendorOrOutlet && (
                <div className="row" style={{ padding: "24px 24px 0" }}>
                  <div className="mb-0 col-lg-5 col-sm-5 col-12">
                    <div className="unlimit-uikit-select">
                      <label className="mb-2">{t("vendor.vendor")}</label>
                      <Select
                        options={vendorOptions}
                        className="select custom-select"
                        placeholder={t("inputField.placeholder.chooseVendor")}
                        value={selectedVendor}
                        formatOptionLabel={({ label, logo_url }) => (
                          <div className="d-flex">
                            <img
                              src={logo_url}
                              alt=""
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                objectFit: "contain",
                              }}
                            />
                            <span style={{ marginLeft: 10 }}>{label}</span>
                          </div>
                        )}
                        onChange={(selectedOption) =>
                          setSelectedVendor(selectedOption)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="table-top row">
                <div className="col-lg-4 col-sm-4 col-12">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder={t("inputField.placeholder.enterID")}
                      className="form-control form-control-sm formsearch-custom ps-2"
                      value={idFilter}
                      onChange={(e) => setIdFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleTableChange();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterOutletName")}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default OutletsList;
