import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/products/variant.service";
import usePagination from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VarientList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [variants, setVariants] = useState([]);
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getVariantList = async (pagination = paginationParams.pagination) => {
    const data = {
      page: pagination?.current,
    };
    setLoading(true);
    await httpService
      .variantsList(data)
      .then((response) => {
        if (response.status == 200) {
          setVariants(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getVariantList(pagination);
  };
  useEffect(() => {
    setVendor(vendors?.data?.data);
    getVariantList();
  }, []);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = async () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
    // if(isFilterVisible && (emailFilter || cvrNoFilter)){
    //   setCvrNoFilter("")
    //   setEmailFilter("")
    //   const filters = {
    //     email: emailFilter,
    //     cvr_no: cvrNoFilter,
    //   };
    //   await getVendorsList(filters);
    // }
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteVariant(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Variant has been delete successfully.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getVariantList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewVariant = () => {
    navigate(all_routes.variantCreate);
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: "Product Name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.product?.product_images[0]?.file}
            alt="Variant"
            style={{ width: 40, height: 40, marginRight: 10 }}
          />
          {record.product.name}
        </div>
      ),
    },
    {
      title: "Variant Name",
      dataIndex: "name",
    },
    {
      title: "Brand",
      dataIndex: "vendor.logo",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.brand?.image}
            alt="Variant"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
        </div>
      ),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier.name",
      render: (outlet, record) =>
        record.supplier ? record.supplier.name : null,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
    },
    {
      title: "Status",
      dataIndex: "percentage",
      align: "center",
      render: (value, record) => (
        <span
          className={`btn text-${
            record.is_active == 1 ? "primary" : "danger"
          } border-${record.is_active == 1 ? "primary" : "danger"} bg-light-${
            record.is_active == 1 ? "primary" : "danger"
          } align-self-end mb-3`}
        >
          {record.is_active == 1 ? "Active" : "Deactivate"}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              //  onClick={() => navigate(`/variant/${record.id}/details`)}
            >
              <i className="feather-eye"></i>
            </a>
            <a
              className="p-2"
              onClick={() => navigate(`/outlets/${record.id}/edit`)}
            >
              <i className="feather-edit-2 text-black"></i>
            </a>
          </div>
        </div>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Variant List", active: true },
  ];

  const vendorOptions = vendor?.map((vendor) => ({
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4> Variant List </h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div className="page-btn">
              <button onClick={addNewVariant} className="btn btn-added">
                <Plus className="me-2 iconsize" />
                Add New
              </button>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-path">
                    <a
                      className={`btn btn-filter ${
                        isFilterVisible ? "setclose" : ""
                      }`}
                      id="filter_search"
                      onClick={toggleFilterVisibility}
                    >
                      <Filter className="filter-icon" />
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </a>
                  </div>
                  {/* <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div> */}
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder="Product Name"
                          className="form-control form-control-sm formsearch-custom ps-2"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="text"
                        placeholder="Supplier Name"
                        className="form-control form-control-sm formsearch-custom ps-2"
                      />
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <div className="input-blocks">
                        <Select
                          options={vendorOptions}
                          className="select custom-select"
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 col-12 mb-2">
                      <input
                        type="number"
                        placeholder="Unit Price"
                        className="form-control form-control-sm formsearch-custom ps-2"
                      />
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12 mb-2 ms-auto">
                      <div className="input-blocks">
                        <Link
                          className="btn btn-filters ms-auto px-2"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <i
                            data-feather="search"
                            className="feather-search me-0 fs-6"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={variants}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default VarientList;
