import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/settings/notification.service";
import customerHttpService from "../../../services/http/peoples/customers.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import UnlimitMultiSelect from "../../../components/ui-kit/unlimit-multi-select";

const NotificationForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const { register, handleSubmit, formState, setValue, setError, watch,control } = useForm();
  const { errors } = formState;
  const { id } = useParams();

  const getCustomerList = async () => {
    setCustomerLoading(true);
    try {
      const response = await customerHttpService.customerList();
      if (response.status === 200) {
        setCustomers(response.data.data?.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setCustomerLoading(false);
    }
  };

  const getNotification = async () => {
    try {
      const response = await httpService.getNotification(id);
      if (response.status === 200) {
        const data = response.data.data;
        setValue("title", data.title);
        setValue("body", data.body);
        setValue("notification_types", data.notification_types);
        setValue("customer_ids", data?.customers?.map((cus)=>{
          return {
            value: cus.id,
            label: cus.name,
          }
        }) || []);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setPageLoading(true);
      getNotification();
    }
    getCustomerList();
  }, [id]);

  const onSubmitForm = async (data) => {
    console.log(data.customer_ids)
    const requestData = {
      title: data.title,
      body: data.body,
      notification_types: data.notification_types,
      customer_ids: data.customer_ids.map((cust)=>cust.value) || [],
    };

    try {
      setIsLoading(true);
      if (id) {
        requestData.id = id;
        await updateNotification(requestData);
      } else {
        await createNotification(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotification = async (formData) => {
    try {
      const response = await httpService.updateNotification(formData, true);
      if (response.status === 200) {
        notificationService.successMessage(t("notification.notificationUpdateSuccess"));
        setTimeout(() => {
          navigate(all_routes.notificationList);
        }, 1000);
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const createNotification = async (formData) => {
    try {
      const response = await httpService.createNotification(formData, true);
      if (response && response.status === 200) {
        notificationService.successMessage(t("notification.notificationCreateSuccess"));
        setTimeout(() => {
          navigate(all_routes.notificationList);
        }, 500);
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("notification.notification"), link: "/notification-list" },
    {
      label: !id
        ? t("notification.addNotification")
        : t("notification.editNotification"),
      active: true,
    },
  ];

  const selectedNotificationTypes = watch("notification_types", []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("notification.notification")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body add-product pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <UnlimitInput
                          label={t("inputField.label.notificationSubject")}
                          id="title"
                          type="text"
                          placeholder={t(
                            "inputField.placeholder.enterNotificationSubject"
                          )}
                          register={register}
                          rules={{
                            required: {
                              value: true,
                              message: t("inputField.validation.required"),
                            },
                            
                            maxLength: {
                              value: 50,
                              message: t("inputField.validation.maxLength", {
                                value: 50,
                              }),
                            },
                          }}
                          error={errors.title}
                          errorMessage={errors.title?.message}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <label className="form-label">
                          {t("inputField.label.notificationType")}
                        </label>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex justify-content-center align-items-center">
                            <input
                              type="checkbox"
                              id="SMS"
                              value="SMS"
                              {...register("notification_types", {
                                validate: {
                                  required: (value) =>
                                    value.length > 0 ||
                                    t("inputField.validation.required"),
                                },
                              })}
                            />
                            <label
                              className="form-check-label ms-2 mb-0"
                              htmlFor="sms"
                              >
                              {t("tableColumnName.sms")}
                            </label>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <input
                              type="checkbox"
                              id="EMAIL"
                              value="EMAIL"
                              {...register("notification_types", {
                                validate: {
                                  required: (value) =>
                                    value.length > 0 ||
                                    t("inputField.validation.required"),
                                },
                              })}
                            />
                            <label
                              className="form-check-label ms-2 mb-0"
                              htmlFor="email"
                            >
                              {t("tableColumnName.email")}
                            </label>
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <input
                              type="checkbox"
                              id="PUSH_NOTIFICATION"
                              value="PUSH_NOTIFICATION"
                              {...register("notification_types", {
                                validate: {
                                  required: (value) =>
                                    value.length > 0 ||
                                    t("inputField.validation.required"),
                                },
                              })}
                            />
                            <label
                              className="form-check-label ms-2 mb-0"
                              htmlFor="push"
                            >
                              {t("notification.pushNotification")}
                            </label>
                          </div>
                        </div>
                        {errors.notification_types && (
                          <div className="text-danger">
                            {t("inputField.validation.required")}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <UnlimitMultiSelect
                        label={t("inputField.label.selectCustomer")}
                        id="customer_ids"
                        options={customers}
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        onChange={(selectedIds) =>
                          setValue("customer_ids", selectedIds)
                        }
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <UnlimitTextarea
                          label={t("inputField.label.notificationDescription")}
                          id="body"
                          placeholder={t(
                            "inputField.placeholder.enterNotificationDescription"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            maxLength: {
                              value: 500,
                              message: t("inputField.validation.maxLength", {
                                value: 500,
                              }),
                            },
                          }}
                          error={errors.body}
                          errorMessage={errors.body?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <Link to={all_routes.notificationList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                      {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton label={id ? t("general.button.update") : t("general.button.submit")} isLoading={isLoading} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NotificationForm;
