import { useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/variant.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { useTranslation } from "react-i18next";

export default function VarientDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [variant, setVariant] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} ${t('pagination.of')} ${total} ${t('pagination.items')}`,
    },
  });
  const navigate = useNavigate();
  const getVariant = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getVariant(id);
      if (response && response.status === 200) {
        setVariant(response.data.data);
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getVariant();
    }
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Variants", link: "/variant-list" },
    { label: "Variant Details", active: true },
  ];
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteVariant(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "Deleted!",
            text: "Variant has been delete successfully.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          navigate(`/variant-list`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const purchaseList = [
    {
      refernceId: "REF123456",
      createdAt: "2024-03-10",
      name: "John Doe",
      grandTotal: "$1000",
      id: "1",
    },
    {
      refernceId: "REF123457",
      createdAt: "2024-03-09",
      name: "Jane Doe",
      grandTotal: "$2000",
      id: "2",
    },
    {
      refernceId: "REF123458",
      createdAt: "2024-03-08",
      name: "Mike Smith",
      grandTotal: "$1500",
      id: "3",
    },
    {
      refernceId: "REF123459",
      createdAt: "2024-03-07",
      name: "Alice Johnson",
      grandTotal: "$2500",
      id: "4",
    },
    {
      refernceId: "REF123460",
      createdAt: "2024-03-06",
      name: "Chris Evans",
      grandTotal: "$3000",
      id: "5",
    },
  ];

  const columns = [
    {
      title: "Reference",
      dataIndex: "refernceId",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Created By",
      dataIndex: "name",
    },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              onClick={() => navigate(`/variant/${id}/details`)}
            >
              <i className="feather-eye"></i>
            </a>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">Variant</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                variant && (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="border p-3 mb-3">
                          <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                            <span className="btn text-primary border-primary bg-light-primary align-self-end mb-3">
                              Active
                            </span>
                            <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                              <ImageWithBasePath
                                src={variant?.image}
                                height={120}
                                width={120}
                                type="link"
                              ></ImageWithBasePath>
                              <div className="mt-4 text-center">
                                <div className="text-black text-center fs-6">
                                  <strong>{variant?.name}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              className="btn btn-primary me-2 w-100"
                              onClick={() => navigate(`/variant/${id}/edit`)}
                            >
                              Edit
                            </button>
                            <button className="btn text-white bg-red-brand w-100">
                              Deactivate
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="p-3">
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Variant Name"}
                                details={variant.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Variant Phone"}
                                details={variant.phone}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Variant Email"}
                                details={variant.email}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Address"}
                                details={variant.address}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Zip Code"}
                                details={variant.zip_code}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"City"}
                                details={variant.phone}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Country"}
                                details={variant.email}
                              />
                            </div>
                          </div>
                          <div className="row mb-md-3">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Created At"}
                                details={variant.name}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Created By"}
                                details={variant.phone}
                              />
                            </div>
                            <div className="col-sm"></div>
                          </div>
                          <div className="row">
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={"Description"}
                                details={variant.description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={purchaseList}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
