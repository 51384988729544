import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const GetWidgetData = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.dashboard.widgets}`,
    true,
    true,
    data,
  );
};
const salePurchaseGraph = async (data,selectedYear) => {
  return await API.getMethod(
    `${ENDPOINT.dashboard.salePurchaseGraph+'/'+selectedYear}`,
    true,
    true,
    data,
  );
};
const topOutlets = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.dashboard.topOutlets}`,
    true,
    true,
    data,
  );
};

const topProducts = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.dashboard.topProducts}`,
    true,
    true,
    data,
  );
};

export default {
topProducts,
topOutlets,
salePurchaseGraph,
GetWidgetData
};
