import React, { useEffect, useState } from "react";
import supplierHttpService from "../../../services/http/peoples/supplier.service";
import categoryHttpService from "../../../services/http/products/category.service";
import httpService from "../../../services/http/products/catalog.service";
import usePagination from "../../../custom-hooks/usePagination";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import { useForm } from "react-hook-form";
import UnlimitButton from "../../../components/ui-kit/unlimit-button";
import { useTranslation } from "react-i18next";
import notificationService from "../../../services/toaster.service";

const ProductFromCatalogSearch = ({ setIsListVisible, setCatalogList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [category, setCategory] = useState([]);
  const { register, handleSubmit, formState, control } = useForm();

  const { errors } = formState;
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const getProductList = async (queryData) => {
    const data = {
      ...queryData,
      page: paginationParams?.pagination?.current,
    };
    await httpService
      .catalogList(data)
      .then((response) => {
        if (response.status == 200) {
          setCatalogList(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {});
  };
  async function fetchSupplier() {
    try {
      const supplier = await supplierHttpService.supplierList();
      const activeSuppliers = supplier?.data?.data?.data?.filter(supplier => supplier.is_active == '1');
      setSupplier(activeSuppliers);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchCategory() {
    try {
      const category = await categoryHttpService.categoriesList();
      setCategory(category?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchSupplier();
    fetchCategory();
  }, []);
  const onSubmitForm = async (data) => {
    const hasAtLeastOneFieldFilled =
    data.ean || data.category_id?.id || data.name;

  if (!hasAtLeastOneFieldFilled) {
    notificationService.warningMessage(t("inputField.validation.AtleastOneField"));
    return;
  }
    try {
      setIsLoading(true);
      const queryData = {
        ean: data.ean,
        category_id: data.category_id?.id,
        name: data.name,
      };
      await getProductList(queryData);
    } finally {
      setIsLoading(false);
      setIsListVisible(true);
    }
  };

  return (
    <div>
      {/* /Search */}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="card">
          <div className="card-body">
            <div className="row mb-2">
              <div className="col-md-4">
                <UnlimitInput
                  label={t("inputField.placeholder.enterEAN")}
                  id="ean"
                  type="text"
                  placeholder={t("inputField.placeholder.enterEAN")}
                  register={register}
                  rules={{
                    pattern: {
                      value: /^\d{4,14}$/,
                      message: t("inputField.validation.eanPattern"),
                    },
                  }}
                  error={errors.ean}
                  errorMessage={errors.ean?.message}
                />
              </div>
              <div className="col-md-4">
                <UnlimitInput
                  label={t("inputField.label.enterProductName")}
                  id="name"
                  type="text"
                  placeholder={t("inputField.placeholder.enterProductName")}
                  register={register}
                  error={errors.name}
                  errorMessage={errors.name?.message}
                />
              </div>
              <div className="col-md-4">
                <UnlimitSelect
                  label={t("inputField.label.productCategory")}
                  id="category_id"
                  errors={errors}
                  control={control}
                  register={register}
                  placeholder={t("inputField.placeholder.chooseCategory")}
                  options={category}
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
            </div>
            <UnlimitButton
              text={t("general.button.search")}
              isLoading={isLoading}
            />

            {/* /Filter */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductFromCatalogSearch;
