import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, privateRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCountries } from "../core/redux/reducers/locations.slice";
import locationService from "../services/http/location.service";
import { fetchVendors } from "../core/redux/reducers/vendors.slice";
import { NotificationContainer } from "react-notifications";
import LoadingSpinner from "../InitialPage/Sidebar/LoadingSpinner";
import { store } from "../core/redux/store";
import { all_routes } from "./all_routes";
import Error403 from "../pages/ErrorPages/Error403";
import Error404 from "../pages/ErrorPages/Error404";
import ChangePassword from "../components/change-password";
import { useGetCountries } from "../services/http/side-Effects/location";
import { useGetSystemLanguage } from "../services/http/side-Effects/systemLanguageActions";

const AllRoutes = () => {
  const location = useLocation();
  const isAuthRoute = pagesRoute.some(
    (route) => route.path === location.pathname
  );
  const [isLoading, setIsLoading] = useState(false);
  const data = useSelector((state) => state.toggle_header);
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useSelector((state) => state.vendors.status);
  const fetchCountries = useGetCountries();
  const fetchSystemLanguage = useGetSystemLanguage();

  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  let permissions = [];
  roles.forEach((obj) => {
    permissions = permissions.concat(obj.permissions);
  });

  const isAssigned = (name) => {
    if (name === "exempt") return true;
    return !!permissions.find((permission) => permission.name === name);
  };

  useEffect(() => {
    // Redirect to Login if user is not authenticated
    if (!isAuthenticated) {
      if (!isAuthRoute && location.pathname !== '/login') navigate("/login");
    } else if(isAuthenticated && isAuthRoute){
      fetchCountries();
      dispatch(fetchVendors(isAuthenticated));
      navigate("/")
    } else {
      fetchCountries();
      dispatch(fetchVendors(isAuthenticated));
    }
    fetchSystemLanguage();
    setIsLoading(true);
  }, [isAuthenticated]);

  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Outlet />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
    </div>
  );

  if (!isLoading) return <LoadingSpinner />;

  return (
    <div>
      <NotificationContainer />
      {isAuthRoute ? null : (
        <>
          {" "}
          <Sidebar /> <Header />
        </>
      )}
      <Routes>
        <Route path="/" element={<HeaderLayout />}>
          <Route path="*" element={<Error404 />} />
          {privateRoutes.map((route, id) => (
            <Route
              path={route.path}
              element={isAssigned(route.perm) ? route.element : <Error403 />}
              key={id}
            />
          ))}
        </Route>
        <Route path="/" element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
      <ChangePassword />
    </div>
  );
};

export default AllRoutes;
