import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import authService from "../../../services/http/auth.service";
import { authSuccess } from "../../../core/redux/reducers/auth.slice";
import { useForm } from "react-hook-form";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import notificationService from "../../../services/toaster.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/LanguageSelecter";

const SigninTwo = () => {
  const route = all_routes;
  const [token, setToken] = useState("");
  const { register, handleSubmit, formState, reset } = useForm();
  const { errors } = formState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(true);

  const handleLoginClick = async (data) => {
    if (!captchaCompleted) {
      notificationService.errorMessage("Please complete the CAPTCHA");
      return;
    }

    const { email, password } = data;
    try {
      setIsLoading(true);
      const res = await authService.login(email, password, token);
      if (res) {
        const { token } = res.data.data;
        localStorage.setItem("accessToken", token);
        dispatch(authSuccess({ accessToken: token, user: res.data.data }));
        navigate("/");
        notificationService.successMessage("Logged-in successfully!");
      }
      reset();
    } catch (e) {
      notificationService.errorMessage(
        e?.response?.data?.message || "Email or Password is Incorrect!"
      );
      // Reset CAPTCHA on login failure
      // setCaptchaCompleted(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="language-change-dropdown">
          <ul className="nav user-menu">
            <LanguageSelector />
          </ul>
        </div>
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-img-text">
              <img
                src="assets/img/Logo01.png"
                className="login-logo-img"
                alt="img"
              />
              <p>{t("general.dummyText")}</p>
            </div>
            <div className="login-img">
              <img
                src="assets/img/authentication/login-screen.jpg"
                alt="img"
              />
            </div>
            <div className="login-content">
              <form
                className="form-login-width"
                onSubmit={handleSubmit(handleLoginClick)}
              >
                <div className="login-userset">
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath
                      src="assets/img/logo-white.png"
                      alt="img"
                    />
                  </Link>
                  <div className="login-userheading">
                    <h3>{t("general.actions.login")}</h3>
                    <h4>{t("general.loginText")}</h4>
                  </div>
                  <UnlimitInput
                    label={t("inputField.label.email")}
                    id="email"
                    type="email"
                    placeholder={t("inputField.placeholder.enterEmail")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("inputField.validation.emailPattern"),
                      },
                    }}
                    error={errors.email}
                    errorMessage={errors.email?.message}
                  />
                  <UnlimitInput
                    label={t("inputField.label.password")}
                    id="password"
                    type="password"
                    placeholder={t("inputField.placeholder.enterPassword")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    error={errors.password}
                    errorMessage={errors.password?.message}
                  />

                  <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-control custom-checkbox"></div>
                      </div>
                      <div className="col-6 text-end">
                        <Link
                          className="forgot-link"
                          to={`${route.forgotPasswordTwo}`}
                        >
                          {t("general.forgotPassword") + "?"}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA
                    key={i18n.language}
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={() => setCaptchaCompleted(true)}
                    onExpired={() => setCaptchaCompleted(false)}
                    asyncScriptOnLoad={() => setCaptchaCompleted(false)}
                    hl={i18n.language}
                  />
                  <div className="form-login">
                    <button
                      className="btn btn-login d-flex justify-content-center"
                      disabled={isLoading || !captchaCompleted}
                      type="submit"
                    >
                      {isLoading ? (
                        <UnlimitLoading
                          width="25px"
                          height="25px"
                          color="#fdb518"
                        />
                      ) : (
                        t("general.actions.login")
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninTwo;
