import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts"; // assuming you're using react-apexcharts

const SalesChart = ({ selectedYear,handleYearChange,dashboardData, t }) => {
  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: t("tableColumnName.sales"),
        data: [], // initially empty
      },
      {
        name: t("purchase.purchase"),
        data: [], // initially empty3
      },
    ],
    colors: ["#28C76F", "#EA5455"],
    chart: {
      type: "bar",
      height: 320,
      stacked: true,
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: {
            position: "bottom",
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    // yaxis: {
    //   min: -200,
    //   max: 300,
    //   tickAmount: 5,
    // },
    xaxis: {
      categories: [], // initially empty
    },
    legend: { show: false },
    fill: {
      opacity: 1,
    },
  });

  useEffect(() => {
    if (dashboardData?.salePurchaseGraphData) {
      const salePurchaseGraphData = dashboardData.salePurchaseGraphData;

      const salesData = Object.values(salePurchaseGraphData).map(
        (item) => item?.sales || 0
      );
      const purchasesData = Object.values(salePurchaseGraphData).map(
        (item) => -(item?.purchases || 0) // Negative values for purchases
      );
      const months = Object.keys(salePurchaseGraphData).map((month) =>
        t(`months.${month.substring(0, 3)}`)
      );

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: t("tableColumnName.sales"),
            data: salesData,
          },
          {
            name: t("purchase.purchase"),
            data: purchasesData,
          },
        ],
        xaxis: {
          ...prevOptions.xaxis,
          categories: months,
        },
      }));
    }
  }, [dashboardData, t]);

  return (
    <div className="card flex-fill">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="card-title mb-0">
          {t("purchase.purchase")} &amp; {t("tableColumnName.sales")}
        </h5>
        <div className="graph-sets">
          <ul className="mb-0">
            <li>
              <span>{t("tableColumnName.sales")}</span>
            </li>
            <li>
              <span>{t("purchase.purchase")}</span>
            </li>
          </ul>
          <div className="dropdown dropdown-wraper">
            <button
              className="btn btn-light btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedYear}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {[2024, 2023, 2022, 2021].map((year) => (
                <li key={year}>
                  <p
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleYearChange(year)}
                  >
                    {year}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div id="sales_charts" />
        <Chart
          options={chartOptions}
          series={chartOptions.series}
          type="bar"
          height={320}
        />
      </div>
    </div>
  );
};

export default SalesChart;
