import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/catalog.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetailsRow from "../../../components/ui-kit/unlimit-field-details-row";
import usePagination from "../../../custom-hooks/usePagination";
import notificationService from "../../../services/toaster.service";
import UnlimitImageGallery from "../../../components/ui-kit/unlimit-image-gallery";
import ThreedFileWindow from "../../sample/3d-window";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function CatalogImageDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const [catalog, setCatalog] = useState(null);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} ${t('pagination.of')} ${total} ${t('pagination.items')}`,
    },
  });
  const navigate = useNavigate();
  const getCatalog = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getCatalog(id);
      if (response && response.status === 200) {
        setCatalog(response.data.data);
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCatalog();
    }
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Catalog", link: "/catalog-list" },
    { label: "Product Catalog Details", link:`/catalog/${id}/details` },
    { label: "Product Catalog Images", active: true },
  ];
  const updateImageStatus = async (imageId, status ) => {
    try {
      const formData = new FormData();
      formData.append("id", imageId);
      formData.append("is_active", status ? 1 : 0);
      formData.append("catalog_id", id);
      const response = await httpService.updateCatalogAttachment(formData)
      if (response && response.status === 200) {
        setCatalog(response.data.data);
      }
    } catch (error) {
      // Handle errors
    } finally {
      setPageLoading(false);
    }
  };
  const updateDefaultImage = async (imageId, status) => {
    try {
      setPageLoading(true);
      const formData = new FormData();
      formData.append("id", imageId);
      formData.append("is_default", status ? 1 : 0);
      formData.append("catalog_id", id);
      const response = await httpService.updateCatalogAttachment(formData)
      if (response && response.status === 200) {
        setCatalog(response.data.data);
      }
    } catch (error) {
      // Handle errors
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">Product Catalog Details</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            catalog && (
              <>
                <div className="row g-4 mb-4">
                  {catalog?.product_images.map((images, index) => (
                    <div key={index} className="col-md-3">
                      <div className="card" style={{height:"100%"}}>
                        <UnlimitImageGallery
                          urls={images}
                          isDefaultValue={catalog?.main_product_image}
                          updateImageStatus={updateImageStatus}
                          updateDefaultImage={updateDefaultImage}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
}
