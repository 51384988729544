import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Button, Badge, Spin } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import usePagination from "../custom-hooks/usePagination";
import httpService from "../services/http/settings/notification.service";
import { useTranslation } from "react-i18next";

const NotificationDropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { t } = useTranslation();
  const dropdownContentRef = useRef(null); // Ref for the dropdown content
  const navigate = useNavigate();

  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const fetchNotifications = async (
    pagination = paginationParams.pagination,
    append = false
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
    };
    await httpService
      .myNotificationList(data)
      .then((response) => {
        if (response.status === 200) {
          const fetchedNotifications = response.data.data.data;
          setNotifications((prev) =>
            append ? [...prev, ...fetchedNotifications] : fetchedNotifications
          );
          const unread = response.data.data.total;
          setUnreadCount(unread);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScroll = () => {
    const dropdownElement = dropdownContentRef.current;
    if (
      dropdownElement.scrollTop + dropdownElement.clientHeight >=
      dropdownElement.scrollHeight
    ) {
      if (!isFetchingMore) {
        loadMoreNotifications();
      }
    }
  };

  const loadMoreNotifications = async () => {
    const nextPage = paginationParams.pagination.current + 1;
    updatePaginationParams({
      pagination: {
        ...paginationParams.pagination,
        current: nextPage,
      },
    });
    setIsFetchingMore(true);
    await fetchNotifications(
      { ...paginationParams.pagination, current: nextPage },
      true
    );
    setIsFetchingMore(false);
  };

  useEffect(() => {
    const dropdownElement = dropdownContentRef.current;
    if (dropdownElement) {
      dropdownElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [dropdownContentRef.current, isFetchingMore]);
  useEffect(() => {
    setLoading(true);
    if (dropdownVisible) {
      fetchNotifications();
    } else {
      updatePaginationParams({
        pagination: {
          current: 1,
          pageSize: 10,
          showSizeChanger: true,
        },
      });
    }
  }, [dropdownVisible]);
  useEffect(() => {
    fetchNotifications();
  }, []);
  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  const handleLinkClick = (path) => {
    setDropdownVisible(false);
    navigate(path);
  };

  const markAsRead = async (id) => {
    try {
      await httpService.markOneAsReadNotification(id);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === id ? { ...notif, is_read: true } : notif
        )
      );
      setUnreadCount((prevCount) => prevCount - 1);
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const markAllAsRead = async () => {
    try {
      await httpService.markAllAsReadNotification();
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) => ({ ...notif, is_read: true }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error("Error marking all notifications as read", error);
    }
  };

  const notificationMenu = (
    <div
      ref={dropdownContentRef} // Attach the ref here
      style={{ maxHeight: "300px", overflowY: "auto" }} // Make the div scrollable
    >
      <Menu>
        {unreadCount > 0 ? (
          <>
          <div className="d-flex px-2 align-items-center justify-content-between">
            <p className="me-4 mb-0">Notifications</p>
            <Button type="link" onClick={markAllAsRead}>
              Mark all as read
            </Button>
          </div>
            <Menu.Divider />
          </>
        ) : (
          <p className="p-2 px-5">
            No New Notifications
          </p>
        )}

        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <Spin />
          </div>
        ) : (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className="py-1"
              style={{
                backgroundColor: notification.is_read ? "white" : "#f0f0f0",
                whiteSpace: "normal",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                borderBottom:"1px solid black"
              }}
            >
              <span
                onClick={() => handleLinkClick(notification.action_type)}
                style={{ flex: 1, paddingLeft: "10px" }}
              >
                <span className="d-flex flex-column">
                  <p className="mb-0 fw-bold">{notification.title}</p>
                  <p className="notification-body mt-1">
                    {truncateText(notification.body, 30)}
                  </p>
                </span>
              </span>
              {!notification.is_read && 
              <Button
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  markAsRead(notification.id);
                }}
              >
                <i className="fa-regular fa-circle-check"></i>
              </Button>
              }
            </div>
          ))
        )}
        {isFetchingMore && (
          <div className="d-flex justify-content-center my-4">
            <Spin size="large" />
          </div>
        )}
      </Menu>
    </div>
  );

  return (
    <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
      <Dropdown
        overlay={notificationMenu}
        trigger={["click"]}
        open={dropdownVisible}
        onOpenChange={(visible) => {
          setDropdownVisible(visible);
        }}
        className="notification-dropdown"
      >
        <Badge count={unreadCount}>
          <BellOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
        </Badge>
      </Dropdown>
    </li>
  );
};

export default NotificationDropdown;
