import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Plus,
} from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import httpService from "../../../services/http/products/tag-type.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import moment from "moment/moment";
import TagTypeDetail from "../../../components/tag-type-detail";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { store } from "../../../core/redux/store";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
const TagTypeList = () => {
  const { hasPermission } = useRoleAndPerm();
  const [tagType, setTagType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tagTypes, setTagTypes] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const { t } = useTranslation();
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const navigate = useNavigate();
  const getTagTypesList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .tagTypesList(data)
      .then((response) => {
        if (response.status == 200) {
          setTagTypes(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if(!pagination){
      pagination = initialPagination
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getTagTypesList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getTagTypesList();
  }, []);


  const onConfirmDelete = async (id) => {
    await httpService
      .deleteTagType(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("tagType.tagTypeDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getTagTypesList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const onHideModal = async () => {
    setTagType(null);
    await getTagTypesList();
  };
  const editTagType = (row) => {
    setTagType(row);
    const addButton = document.querySelector(".btn-added");
    if (addButton) {
      addButton.click();
    }
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tagType.tagType"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["tag-type-edit", "tag-type-delete"]),
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["tag-type-edit"]) && (
              <a className="me-2 p-2" onClick={() => editTagType(record)}>
                <i className="feather-edit-2"></i>
              </a>
            )}
            {hasPermission(["tag-type-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </div>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("tagType.tagType"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4> {t("tagType.tagType")} </h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            <div
              className="page-btn"
              style={{
                display: hasPermission(["tag-type-add"]) ? "block" : "none",
              }}
            >
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add-department"
                className="btn btn-added"
              >
                <Plus className="me-2 iconsize" />
                {t("general.button.addNew")}
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top row">
                  <div className="col-lg-5 col-sm-5 col-12">
                    <input
                      type="text"
                      placeholder={t("inputField.placeholder.enterTagType")}
                      className="form-control form-control-sm formsearch-custom ps-2"
                      value={nameFilter}
                      onChange={(e) => setNameFilter(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleTableChange();
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks  mb-0">
                      <button
                        className="btn btn-filters col-auto ms-auto px-2"
                        style={{ width: "fit-content" }}
                        onClick={handleTableChange}
                      >
                        <i
                          data-feather="search"
                          className="feather-search m-0"
                        />{" "}
                      </button>
                    </div>
                  </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={tagTypes}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <TagTypeDetail onHide={onHideModal} tagType={tagType} />
    </div>
  );
};

export default TagTypeList;
