// Shelf.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SensorDetail from "./sensor-detail";

const Shelf = ({ number, removeShelf }) => {
  const [sensors, setSensors] = useState([1]); // Initial sensor as a number
  const [showModal, setShowModal] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState(null);
 
  const addSensor = () => {
    const newSensorId = sensors.length > 0 ? Math.max(...sensors) + 1 : 1;
    setSensors([...sensors, newSensorId]);
  };

  const removeSensor = (sensorId) => {
    setSensors(sensors.filter((sensor) => sensor !== sensorId));
  };

  const handleSensorClick = (sensorId) => {
    setSelectedSensor(sensorId);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedSensor(null);
  };

  return (
    <div className="d-flex flex-column align-items-center mb-3">
      <div className="d-flex align-items-center w-100">
        <div className="py-2 px-3 d-flex justify-content-between align-items-center shelf-container w-100">
          <div className="d-flex align-items-center w-100">
            <h6 className="me-3 mb-0">{number}:</h6>
            {sensors.map((sensorId) => (
              <span
                key={sensorId}
                className="badge badge-linewarningshelf shelf me-2 flex-grow w-100"
                style={{ cursor: "pointer" }}
              >
                <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#add-sensorDetail"
                >
                Sensor: {sensorId}
                </Link>
                <i
                  className="feather-x ms-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSensor(sensorId);
                  }}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            ))}
          </div>
          <div onClick={addSensor} style={{ cursor: "pointer" }}>
            <i className="feather-plus h6"></i>
          </div>
        </div>
        <i
          className="feather-x h6 mx-3"
          onClick={removeShelf}
          style={{ cursor: "pointer" }}
        ></i>
      </div>

      {/* Sensor Modal */}
        <SensorDetail
        onHide={handleModalClose}
        systemLanguage={null}
        />
    </div>
  );
};

export default Shelf;
