import React, { useEffect, useState } from "react";
import logsService from "../../../services/http/settings/logs.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";

const LogsList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [purchaseIsLoading, setPurchaseIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [logsFiles, setLogsFiles] = useState([]);
  const [selectedLogsFiles, setSelectedLogsFiles] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);

  const getLogsList = async () => {
    setLoading(true);
    await logsService
      .getLogs(selectedLogsFiles?.value)
      .then((response) => {
        if (response.status === 200) {
          setLogs(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  useEffect(() => {
    if (selectedLogsFiles?.value) {
      getLogsList();
    }
  }, [selectedLogsFiles]);

  async function fetchLogsFiles() {
    try {
      const logsFiles = await logsService.logsList();
      setLogsFiles(logsFiles?.data?.data);
      if (logsFiles?.data?.data && logsFiles?.data?.data?.length > 0) {
        setSelectedLogsFiles({
          value: logsFiles?.data?.data[0],
          label: logsFiles?.data?.data[0],
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPurchaseIsLoading(false);
    }
  }

  useEffect(() => {
    setPurchaseIsLoading(true);
    fetchLogsFiles();
  }, []);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpand = (recordId) => {
    setExpandedRowKeys((prevExpandedRowKeys) =>
      prevExpandedRowKeys.includes(recordId)
        ? prevExpandedRowKeys.filter((key) => key !== recordId)
        : [...prevExpandedRowKeys, recordId]
    );
  };

  const filteredLogs = logs.filter((log) =>
    log.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
      width: 50,
    },
    {
      title: t("logs.type"),
      dataIndex: "type",
      render: (text) => (
        <div
          className={`${
            text === "Info"
              ? "badge badge-linesuccess"
              : "badge badge-linedanger"
          }  w-auto`}
          style={{
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          {text}
        </div>
      ),
      width: 100,
    },
    {
      title: t("logs.date"),
      dataIndex: "date",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
      width: 100,
    },
    {
      title: t("logs.content"),
      dataIndex: "content",
      width: 500,
      ellipsis: true,
      render: (text, record, index) => {
        const isExpanded = expandedRowKeys.includes(index);
        return (
          <div className="d-flex justify-content-between ms-2">
            {!isExpanded ? (
              <div
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {record.short_content}
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  maxWidth: "96.5%",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {text}
              </div>
            )}
            {text.length > 80 && (
              <div>
                <i
                  className={`fa fa-chevron-${
                    isExpanded ? "up" : "down"
                  } bg-primary text-white p-2 rounded`}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={() => handleExpand(index)}
                />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("logs.logList"), active: true },
  ];

  const logFilesOptions = logsFiles?.map((logs) => ({
    value: logs,
    label: logs,
  }));

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("logs.logList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {/* /Filter */}
              <div
                className={`card visible mt-md-4 mb-md-3`}
                id="filter_inputs"
                style={{ display: "block" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-4 col-12">
                      <div className="unlimit-uikit-select">
                        <label className="mb-2">
                          {t("logs.chooseLogFile")}
                        </label>
                        <Select
                          options={logFilesOptions}
                          className="select custom-select"
                          placeholder={t("logs.chooseLogFile")}
                          value={selectedLogsFiles}
                          onChange={(selectedOption) =>
                            setSelectedLogsFiles(selectedOption)
                          }
                          isLoading={purchaseIsLoading}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 col-12">
                      <div className="form-group">
                        <label className="mb-2">
                          {t("logs.searchContent")}
                        </label>
                        <div className="search-input">
                          <input
                            type="text"
                            className="form-control form-control-sm formsearch-custom ps-2"
                            placeholder={t("logs.enterSearchText")}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={filteredLogs}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default LogsList;
