import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/stores/outlet.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import locationAPI from "../../../services/http/location.service";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { useDispatch, useSelector } from "react-redux";
import GoogleMaps from "../../../components/google-maps";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useTranslation } from "react-i18next";
import { fetchVendors } from "../../../core/redux/reducers/vendors.slice";
import useRoleAndPerm from "../../../utils/permissionFunction";

const OutletsForm = () => {
  const { hasPermission, isVendorRole, isOutletRole, isVendorOrOutlet } =
    useRoleAndPerm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [center, setCenter] = useState({ lat: 55.04434, lng: 9.41741 });
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const { t } = useTranslation();
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [selectedVendorImg, setSelectedVendorImg] = useState("");
  const [vendor, setVendor] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState([]);
  const dispatch = useDispatch();
  const [citiesLoading, setCitiesLoading] = useState(false);
  const { locations } = store.getState();
  const [imageUrlAttachments, setImageUrlAttachments] = useState("");
  const [existingImageUrlAttachments, setExistingImageUrlAttachments] =
    useState("");

  const watchFile = watch("attachment");
  useEffect(() => {
    if (city) {
      fetchCoordinates(city);
    }
  }, [city]);
  const fetchCoordinates = async (city) => {
    try {
      if (city.latitude && city.longitude) {
        const lat = parseFloat(city.latitude);
        const lng = parseFloat(city.longitude);
        setCenter({ lat, lng });
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };
  const removeImage = () => {
    setImageUrlAttachments("");
    setExistingImageUrlAttachments("");
    setValue("image", "");
  };
  useEffect(() => {
    if (watchFile?.length > 0) {
      setImageUrlAttachments(URL.createObjectURL(watchFile[0]));
    }
  }, [watchFile]);

  const vendors = useSelector((state) => state.vendors.vendors);
  const systemLanguage = useSelector(
    (state) => state.systemLanguages.SystemLangauge
  );
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getOutlets();
    } else {
      if (isVendorRole) {
        setValue("vendor_id", {
          ...auth?.user?.vendor,
          label: auth?.user?.vendor?.business_name,
          value: auth?.user?.vendor?.id,
          logo: auth?.user?.vendor?.logo,
        });
      } else if (isOutletRole) {
        setValue("vendor_id", {
          ...auth?.user?.outlet?.vendor,
          value: auth?.user?.outlet?.vendor?.id,
          label: auth?.user?.outlet?.vendor?.business_name,
          logo: auth?.user?.outlet?.vendor?.logo,
        });
      }
    }
  };

  useEffect(() => {
    setCountries(locations.countries);
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
  }, [id]);

  // Fetch outlets data by ID
  const getOutlets = async () => {
    try {
      setPageLoading(true);
      await httpService
        .getOutlet(id)
        .then(async (response) => {
          if (response && response.status === 200) {
            const data = response.data.data;
            hydratePredefinedValues(data);
            await getCities(data.country.id);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo,
    });
    if (data.vendor.logo) setSelectedVendorImg(data.vendor.logo);
    setValue("outlet_id", data.outlet_id);
    setValue("designation_id", data.designation_id);
    setValue("address", data.address);
    setValue("zip_code", data.zip_code);
    setValue("is_active", data.is_active == 1 ? true : false);
    setValue("city_id", data.city.id);
    setValue("country_id", data.country?.id);
    setValue("language_id", {
      ...data.language,
      label: data.language.name,
      value: data.language.id,
    });
    const allTimeZones = locations.countries
      ?.filter((country) => country.flag)
      ?.find((country) => country?.id == data?.country?.id)?.timezones;
    const parsedTimeZone = JSON.parse(allTimeZones);
    if (allTimeZones) setTimezones(parsedTimeZone);
    const selectedTimezone = parsedTimeZone?.find(
      (timezone) => timezone?.gmtOffsetName == data?.timezone
    );
    if (selectedTimezone)
      setValue("time_zone", {
        ...selectedTimezone,
        label: selectedTimezone?.zoneName,
        value: selectedTimezone?.gmtOffsetName,
      });
    setValue("owner_name", data.owner_name);
    setValue("contact_name", data.contact_name);
    setValue("longitude", data.longitude);
    setValue("latitude", data.latitude);
    setCenter({
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    });
    setValue("installation_id", data.installation_id);
    setValue("authorization_token", data.authorization_token);
    setValue("nvr_url", data.nvr_url);
    setValue("nvr_username", data.nvr_username);
    setValue("nvr_password", data.nvr_password);
    setValue("base_url", data.base_url);
    setValue("n_site_id", data.n_site_id);
    setValue("n_controller_id", data.n_controller_id);
    setValue("n_control_type", data.n_control_type);
    setValue("n_control_index", data.n_control_index);
    setExistingImageUrl(data.image);
    setExistingImageUrlAttachments(data.attachments[0]?.file);
    // Set selected country
    if (data.country.id) {
      setCountries((prevCountries) => {
        let country = prevCountries.find((item) => item.id === data.country.id);
        if (country) {
          country = {
            ...country,
            label: country.name,
            value: country.id,
          };
          setValue("country_id", country);
        }
        return prevCountries;
      });
    }
  };
  const { auth } = store.getState();
  // Handle form submission
  const onSubmitForm = async (data) => {
    const user = auth.user;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("created_by", user.id);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("address", data.address);
      formData.append("owner_name", data.owner_name);
      formData.append("contact_name", data.contact_name);
      if (data.vendor_id?.id) formData.append("vendor_id", data.vendor_id?.id);
      formData.append("language_id", data.language_id?.id);
      formData.append("time_zone", data.time_zone.value);
      formData.append("zip_code", data.zip_code);
      formData.append("is_active", data.is_active ? 1 : 0);
      formData.append("longitude", center.lng);
      formData.append("latitude", center.lat);
      formData.append("installation_id", data.installation_id);
      formData.append("authorization_token", data.authorization_token);
      formData.append("nvr_url", data.nvr_url);
      formData.append("nvr_username", data.nvr_username);
      formData.append("nvr_password", data.nvr_password);
      formData.append("base_url", data.base_url);
      formData.append("n_site_id", data.n_site_id);
      formData.append("n_controller_id", data.n_controller_id);
      formData.append("n_control_type", data.n_control_type);
      formData.append("n_control_index", data.n_control_index);
      if (data.image[0]) {
        formData.append("image", data.image[0]);
      }
      if (data.country_id) {
        formData.append("country_id", data.country_id?.id);
      }
      if (data.city) {
        formData.append("city_id", data.city.id);
      }
      if (id) {
        formData.append("id", id);
        await httpService
          .updateOutlet(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t("outlet.outletUpdateSuccess")
              );
              setTimeout(() => {
                navigate(all_routes.outletsList);
              }, 1000);
              if (hasPermission(["vendor-view"])) dispatch(fetchVendors(true));
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createOutlets(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new outlets
  const createOutlets = async (formData) => {
    await httpService
      .createOutlet(formData)
      .then((response) => {
        if (response && response.status === 200) {
          notificationService.successMessage(t("outlet.outletCreateSuccess"));
          navigate(all_routes.outletsList);
          if (hasPermission(["vendor-view"])) dispatch(fetchVendors(true));
        }
      })
      .catch((error) => {
        if (error.validation_error.errors) {
          hydrateErrors(error.validation_error.errors, setError);
        }
      });
  };
  const getCities = async (country_id) => {
    try {
      setCitiesLoading(true);
      setCities([]);
      await locationAPI
        .getCitiesByCountry(country_id)
        .then((response) => {
          if (response && response.status === 200) {
            setCities(response.data.data?.data);
            if (id) {
              setCities((prevCities) => {
                let city = prevCities.find(
                  (item) => item.id === getValues("city_id")
                );
                if (city) {
                  city = {
                    ...city,
                    label: city.name,
                    value: city.id,
                  };
                  setValue("city", city);
                }
                return prevCities;
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } finally {
      setCitiesLoading(false);
    }
  };

  // Handle country change event
  const onChangeCountry = (value) => {
    if (value) {
      getCities(value.id);
      setCountry(value);
      setTimezones(JSON.parse(value.timezones));
    }
  };
  const onChangeCity = (value) => {
    if (value) {
      setCity(value);
    }
  };
  const onChangeVendor = (value) => {
    if (value) setSelectedVendorImg(value.logo);
  };
  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("outlet.outlets"), link: "/outlets-list" },
    {
      label: id ? t("outlet.editOutlet") : t("outlet.addOutlet"),
      active: true,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("outlet.outlet")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Outlets Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          {!isVendorOrOutlet && (
            <div className="card">
              <div className="card-body pb-0 mb-3 row align-items-center justify-content-between">
                <div className="col-md-4">
                  <UnlimitSelect
                    label={t("vendor.vendor")}
                    id="vendor_id"
                    errors={errors}
                    control={control}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    placeholder={t("inputField.placeholder.chooseVendor")}
                    options={vendor}
                    showLogo={true}
                    optionLabel="business_name"
                    onChange={onChangeVendor}
                    optionValue="id"
                  />
                </div>
                <div className="col-md-4 text-end">
                  {selectedVendorImg && (
                    <img
                      src={selectedVendorImg}
                      className="img-fluid"
                      style={{ maxWidth: "70px" }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="card">
            <div className="card-body pb-0">
              <h4>{t("outlet.outletDetails")}</h4>
            </div>
            <hr></hr>
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("inputField.label.outletName")}
                        id="name"
                        type="text"
                        placeholder={t("inputField.placeholder.enterName")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-6 d-flex align-items-start justify-content-between">
                      <UnlimitFileUpload
                        label={t("inputField.label.image")}
                        id="image"
                        register={register}
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        accept=".jpeg, .png, .jpg"
                        error={errors.image}
                        errorMessage={errors.image?.message}
                        onChange={handleImageChange}
                      />
                      {(id || imageUrl) && (
                        <img
                          src={
                            id
                              ? id && imageUrl
                                ? imageUrl
                                : existingImageUrl
                              : imageUrl
                          }
                          alt={t("inputField.label.image")}
                          className="img-fluid"
                          style={{ maxWidth: "70px" }}
                        />
                      )}{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.ownerName")}
                        id="owner_name"
                        type="text"
                        placeholder={t("inputField.placeholder.enterName")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Za-z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.contactNamePattern"
                            ),
                          },
                        }}
                        error={errors.owner_name}
                        errorMessage={errors.owner_name?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.contactName")}
                        id="contact_name"
                        type="text"
                        placeholder={t("inputField.placeholder.enterName")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Za-z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.contactNamePattern"
                            ),
                          },
                        }}
                        error={errors.contact_name}
                        errorMessage={errors.contact_name?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.phone")}
                        id="phone"
                        type="text"
                        placeholder={t("inputField.placeholder.enterPhone")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\+?\d+$/,
                            message: t("inputField.validation.phonePattern"),
                          },
                          maxLength: {
                            value: 20,
                            message: t("inputField.validation.maxLength", {
                              value: 20,
                            }),
                          },
                        }}
                        error={errors.phone}
                        errorMessage={errors.phone?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.email")}
                        id="email"
                        type="email"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("inputField.validation.emailPattern"),
                          },
                        }}
                        error={errors.email}
                        errorMessage={errors.email?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.address")}
                        id="address"
                        type="text"
                        placeholder={t("inputField.placeholder.enterAddress")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.address}
                        errorMessage={errors.address?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.zipCode")}
                        id="zip_code"
                        type="text"
                        placeholder={t("inputField.placeholder.enterZipCode")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[1-9][0-9]*$/,
                            message: t("inputField.validation.zipCodePattern"),
                          },
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                        }}
                        error={errors.zip_code}
                        errorMessage={errors.zip_code?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.country")}
                        id="country_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectCountry")}
                        options={countries?.filter((country) => country.flag)}
                        optionLabel="name"
                        optionValue="id"
                        onChange={onChangeCountry}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.city")}
                        id="city"
                        errors={errors}
                        control={control}
                        register={register}
                        options={cities}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectCity")}
                        optionLabel="name"
                        optionValue="id"
                        isLoading={citiesLoading}
                        onChange={onChangeCity}
                      />
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="mb-2">
                      {t("inputField.label.mapLocation")}
                    </label>
                    <GoogleMaps
                      country={country}
                      city={city}
                      center={center}
                      setCenter={setCenter}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("inputField.label.longitude")}
                        id="longitude"
                        type="text"
                        placeholder={t("inputField.placeholder.enterLongitude")}
                        register={register}
                        value={center.lng}
                        onChange={(event) =>
                          setCenter({
                            ...center,
                            lng: parseFloat(event.target.value),
                          })
                        }
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        error={errors.longitude}
                        errorMessage={errors.longitude?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("inputField.label.latitude")}
                        id="latitude"
                        type="text"
                        placeholder={t("inputField.placeholder.enterLatitude")}
                        register={register}
                        value={center.lat}
                        onChange={(event) =>
                          setCenter({
                            ...center,
                            lat: parseFloat(event.target.value),
                          })
                        }
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        error={errors.latitude}
                        errorMessage={errors.latitude?.message}
                      />
                    </div>
                   <div className="row mb-2 align-items-end">
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.systemLanguage")}
                        id="language_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t(
                          "inputField.placeholder.chooseSystemLanguage"
                        )}
                        options={systemLanguage}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.timeZone")}
                        id="time_zone"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.chooseTimeZone")}
                        options={timezones}
                        optionLabel="zoneName"
                        optionValue="gmtOffsetName"
                      />
                    </div>
                    <div className="col-lg-3 ">
                      <UnlimitToggle
                        isChecked={id ? false : true}
                        label={t("inputField.label.status")}
                        id="is_active"
                        register={register}
                      />
                    </div>
                  </div>
                  <hr className="mt-3"/>
                    <p className="text-muted">
                      {t("tableColumnName.CCTV")}
                    </p>
                    <div className="row">
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.NVRURL")}
                          id="nvr_url"
                          type="text"
                          placeholder={t("inputField.placeholder.enterNVRURL")}
                          register={register}
                          rules={{
                            pattern: {
                              value:
                                /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                              message: t("inputField.validation.invalidURL"),
                            },
                          }}
                          error={errors.nvr_url}
                          errorMessage={errors.nvr_url?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.userName")}
                          id="nvr_username"
                          type="string"
                          placeholder={t(
                            "inputField.placeholder.enterNVRUsername"
                          )}
                          register={register}
                          error={errors.nvr_username}
                          errorMessage={errors.nvr_username?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.password")}
                          id="nvr_password"
                          type="text"
                          placeholder={t(
                            "inputField.placeholder.enterNVRPassword"
                          )}
                          register={register}
                          error={errors.nvr_password}
                          errorMessage={errors.nvr_password?.message}
                        />
                      </div>
                    </div>
                    <hr className="mt-3"/>
                    <p className="text-muted">
                      {t("tableColumnName.delfiDetails")}
                    </p>
                    <div className="row">
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.installationID")}
                          id="installation_id"
                          type="number"
                          placeholder={t(
                            "inputField.placeholder.enterInstallationID"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            minLength: {
                              value: 3,
                              message: t("inputField.validation.minLength", {
                                value: 3,
                              }),
                            },
                            maxLength: {
                              value: 6,
                              message: t("inputField.validation.maxLength", {
                                value: 6,
                              }),
                            },
                          }}
                          error={errors.installation_id}
                          errorMessage={errors.installation_id?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.baseUrl")}
                          id="base_url"
                          type="text"
                          placeholder={t("inputField.placeholder.enterURL")}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            pattern: {
                              value:
                                /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                              message: t("inputField.validation.invalidURL"),
                            },
                          }}
                          error={errors.base_url}
                          errorMessage={errors.base_url?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.authorizationToken")}
                          id="authorization_token"
                          type="text"
                          placeholder={t(
                            "inputField.placeholder.enterAuthorizationToken"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          error={errors.authorization_token}
                          errorMessage={errors.authorization_token?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.nSiteID")}
                          id="n_site_id"
                          type="number"
                          placeholder={t("inputField.placeholder.enterSiteID")}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          error={errors.n_site_id}
                          errorMessage={errors.n_site_id?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.nControllerID")}
                          id="n_controller_id"
                          type="number"
                          placeholder={t(
                            "inputField.placeholder.enterControllerID"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          error={errors.n_controller_id}
                          errorMessage={errors.n_controller_id?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.nControlType")}
                          id="n_control_type"
                          type="text"
                          placeholder={t(
                            "inputField.placeholder.enterControlType"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          error={errors.n_control_type}
                          errorMessage={errors.n_control_type?.message}
                        />
                      </div>
                      <div className="col-md-3">
                        <UnlimitInput
                          label={t("inputField.label.nControlIndex")}
                          id="n_control_index"
                          type="number"
                          placeholder={t(
                            "inputField.placeholder.enterControlIndex"
                          )}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          error={errors.n_control_index}
                          errorMessage={errors.n_control_index?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.outletsList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Outlets Form End */}
      </div>
    </div>
  );
};

export default OutletsForm;
