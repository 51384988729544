import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/settings/notification.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import moment from "moment";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
const NotificationList = () => {
  const { hasPermission } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const { t } = useTranslation();
  const initialPagination = createInitialPagination(t)
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);
  const getNotificationList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .notificationList(data)
      .then((response) => {
        if (response.status == 200) {
          setNotificationList(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      name: nameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if(!pagination){
      pagination = initialPagination
    }
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getNotificationList(pagination, nonNullFilters, sorter);
  };
  useEffect(() => {
    getNotificationList();
  }, []);

  const { notificationCreate } = all_routes;
  const notificationTypeLabels = {
    PUSH_NOTIFICATION: t("notification.push"),
    SMS: t("tableColumnName.sms"),
    EMAIL: t("tableColumnName.email"), // Add other types as needed
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.notificationSubject"),
      dataIndex: "title",
    },
    {
      title: t("inputField.label.notificationDescription"),
      dataIndex: "body",
    },
    {
      title: t("customer.customer"),
      dataIndex: "code",
      render: (text, record) => {
        return record.customers.length;
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (outlet, record) =>
        record.created_by ? record.created_by.name : null,
    },
    {
      title: t("inputField.label.notificationType"),
      dataIndex: "notification_types",
      render: (notificationTypes) => {
        return notificationTypes.map((type) => (
          // <Badge key={type} style={{ backgroundColor: '#1890ff', color: '#fff', marginRight: 4 }}>
          <span key={type} className="me-2 badge badge-linesuccess">
            {notificationTypeLabels[type]}
          </span>
        ));
      },
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("notification.notificationList"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("notification.notificationList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["notification-add"]) && (
              <div className="page-btn">
                <Link to={notificationCreate} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.button.addNew")}
                </Link>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="col-lg-5 col-sm-5 col-12">
                  <input
                    type="text"
                    placeholder={t(
                      "inputField.placeholder.enterNotificationSubject"
                    )}
                    className="form-control form-control-sm formsearch-custom ps-2"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={notificationList}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
