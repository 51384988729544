// DashboardCards.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { User, UserCheck } from 'react-feather';
import ImageWithBasePath from '../../../core/img/imagewithbasebath';
import formatNumber from '../../../utils/numberFunction';

const DashboardCards = ({ dashboardData, selectedCountry, t, hasPermission,  all_routes }) => {
  return (
    <div className="row">
      <div className="custom-col col-4 col-md d-flex">
        <Link className="w-100" to={hasPermission(["customer-view"]) ? all_routes.customersList : '#'}>
          <div className="dash-count">
            <div className="dash-counts">
              <h4>{formatNumber(dashboardData?.widgetData?.customers?.count, selectedCountry)}</h4>
              <h5>{t("customer.customers")}</h5>
            </div>
            <div className="dash-imgs">
              <User />
            </div>
          </div>
        </Link>
      </div>

      <div className="custom-col col-4 col-md d-flex">
        <Link className="w-100" to={hasPermission(["sale-view"]) ? all_routes.salesList : '#'}>
          <div className="dash-count das1">
            <div className="dash-counts">
              <h4>{formatNumber(dashboardData?.widgetData?.sales?.count, selectedCountry) || 0}</h4>
              <h5>{t("Sales.Sales")}</h5>
            </div>
            <div className="dash-imgs">
              <UserCheck />
            </div>
          </div>
        </Link>
      </div>

      <div className="custom-col col-4 col-md d-flex">
        <Link className="w-100" to={hasPermission(["purchase-view"]) ? all_routes.purchaseList : '#'}>
          <div className="dash-count das2">
            <div className="dash-counts">
              <h4>{formatNumber(dashboardData?.widgetData?.purchases?.count, selectedCountry) || 0}</h4>
              <h5>{t("purchase.purchase")}</h5>
            </div>
            <div className="dash-imgs">
              <ImageWithBasePath src="assets/img/icons/file-text-icon-01.svg" className="img-fluid" alt="icon" />
            </div>
          </div>
        </Link>
      </div>

      <div className="custom-col col-4 col-md d-flex">
        <div className="dash-count das1">
          <div className="dash-counts">
            <h4>{formatNumber(dashboardData?.widgetData?.earned_points?.count, selectedCountry) || 0}</h4>
            <h5>{t("tableColumnName.earnedPoints")}</h5>
          </div>
          <div className="dash-imgs">
            <ImageWithBasePath src="assets/img/icons/Earn_A_points.svg" alt="icon" />
          </div>
        </div>
      </div>

      <div className="custom-col col-4 col-md d-flex">
        <div className="dash-count das3">
          <div className="dash-counts">
            <h4>{formatNumber(dashboardData?.widgetData?.redeem_points?.count, selectedCountry) || 0}</h4>
            <h5>{t("tableColumnName.redeemPoints")}</h5>
          </div>
          <div className="dash-imgs">
            <ImageWithBasePath src="assets/img/icons/redeem.svg" alt="icon" />
          </div>
        </div>
      </div>

      <div className="col-md-4 col-4 d-flex">
        <div className="dash-widget dash1 w-100">
          <div className="dash-widgetimg">
            <ImageWithBasePath src="assets/img/icons/dash2.svg" alt="icon" />
          </div>
          <div className="dash-widgetcontent">
            <h5 className="d-flex">
              <span className="me-1">{selectedCountry?.currency_symbol}</span>
              <p>{formatNumber(dashboardData?.widgetData?.sales?.amount, selectedCountry, 2)}</p>
            </h5>
            <h6>{t("dashboard.salesAmount")}</h6>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-4 d-flex">
        <div className="dash-widget w-100">
          <div className="dash-widgetimg">
            <ImageWithBasePath src="assets/img/icons/dash1.svg" alt="icon" />
          </div>
          <div className="dash-widgetcontent">
            <h5 className="d-flex">
              <span className="me-1">{selectedCountry?.currency_symbol}</span>
              <p>{formatNumber(dashboardData?.widgetData?.purchases?.amount, selectedCountry, 2)}</p>
            </h5>
            <h6>{t("tableColumnName.costAmount")}</h6>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-4 d-flex">
        <div className="dash-widget dash2 w-100">
          <div className="dash-widgetimg">
            <ImageWithBasePath src="assets/img/icons/revenue-rule-1.svg" alt="icon" />
          </div>
          <div className="dash-widgetcontent">
            <h5 className="d-flex">
              <span className="me-1">{selectedCountry?.currency_symbol}</span>
              <p>{formatNumber(dashboardData?.widgetData?.revenue?.amount, selectedCountry, 2) || 0}</p>
            </h5>
            <h6>{t("dashboard.totalRevenue")}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
