import React, { useEffect, useState } from "react";
import UOMHttpService from "../../../services/http/products/uom.service";
import categoryHttpService from "../../../services/http/products/category.service";
import pledgeHttpService from "../../../services/http/products/pledge-price.service";
import brandHttpService from "../../../services/http/products/brand.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { store } from "../../../core/redux/store";
import { useTranslation } from "react-i18next";

const CatalogStep1 = ({
  handleNext,
  formData,
  setFormData,
  existingData,
  isLoading,
}) => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const [brand, setBrand] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [UOMCode, setUOMCode] = useState([]);
  const [category, setCategory] = useState([]);
  const [pledge, setPledge] = useState([]);
  const [brandLoading, setBrandLoading] = useState(false);
  const [UOMLoading, setUOMLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [pledgeTypeLoading, setPledgeTypeLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedPledgeId, setSelectedPledgeId] = useState(null);
  const { locations } = store.getState();
  async function fetchCategory() {
    try {
      const category = await categoryHttpService.categoriesList({is_active:1});
      setCategory(category?.data?.data?.data);
    } catch (err) {
      console.log("error fetching Data", err);
    } finally {
      setCategoryLoading(false);
    }
  }
  async function fetchBrand() {
    try {
      const brand = await brandHttpService.brandsList({is_active:1});
      setBrand(brand?.data?.data?.data);
    } catch (err) {
      console.log("error fetching Data", err);
    } finally {
      setBrandLoading(false);
    }
  }
  async function fetchUOMCode() {
    try {
      const brand = await UOMHttpService.uomList({is_active:1});
      setUOMCode(brand?.data?.data?.data);
    } catch (err) {
      console.log("error fetching Data", err);
    } finally {
      setUOMLoading(false);
    }
  }
  async function fetchPledgeType() {
    try {
      const pledge = await pledgeHttpService.pledgePriceList({is_active:1});
      setPledge(pledge?.data?.data?.data);
    } catch (err) {
      console.log("error fetching Data", err);
    } finally {
      setPledgeTypeLoading(false);
    }
  }

  useEffect(() => {
    fetchBrand();
    fetchUOMCode();
    fetchCategory();
    setBrandLoading(true);
    setUOMLoading(true);
    setCategoryLoading(true);
    setPledgeTypeLoading(true);
    fetchPledgeType();
  }, [id]);
  useEffect(() => {
    if (existingData) hydratePredefinedValues(existingData);
  }, [existingData]);

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("ean", data.ean);
    if (data.category)
      setValue("category_id", {
        ...data.category,
        label: data.category?.name,
        value: data.category?.id,
        logo: data.category?.image,
      });
    if (data.uom_code_1)
      setValue("uom_code_1_id", {
        ...data.uom_code_1,
        label: data.uom_code_1?.name,
        value: data.uom_code_1?.id,
      });
    setValue("uom_code_1_value", data.uom_code_1_value);
    if (data.uom_code_2)
      setValue("uom_code_2_id", {
        ...data.uom_code_2,
        label: data.uom_code_2?.name,
        value: data.uom_code_2?.id,
      });
    setValue("uom_code_2_value", data.uom_code_2_value);
    if (data.brand)
      setValue("brand_id", {
        ...data.brand,
        label: data.brand?.name,
        value: data.brand?.id,
      });
    if (data.pledge_type)
      setValue("pledge_type", {
        ...data.pledge_type,
        label: data.pledge_type?.material,
        value: data.pledge_type?.id,
      });
    setSelectedPledgeId(data?.pledge_type?.id);
    if (data.vat) setValue("vat", data.vat);
    setValue(
      "extra_security_check",
      data.extra_security_check == 1 ? true : false
    );
    setValue("short_description", data.short_description);
    setValue("product_information", data.product_information);
    setValue("receipt_label_text", data.receipt_label_text);
    setValue(
      "is_security_product",
      data.is_security_product == 1 ? true : false
    );
    setValue("is_gs1_product", data.is_gs1_product == 1 ? true : false);
    setValue("is_vat_product", data.is_vat_product == 1 ? true : false);
    setValue(
      "is_compound_product",
      data.is_compound_product == 1 ? true : false
    );
    setValue("is_active", data.is_active == 1 ? true : false);
    setValue("text_line_1", data.text_line_1);
    setValue("text_line_2", data.text_line_2);
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    const newFormData = new FormData();
    newFormData.append("ean", data.ean);
    newFormData.append("name", data.name);
    newFormData.append("receipt_label_text", data.receipt_label_text);
    newFormData.append("brand_id", data.brand_id?.id);
    newFormData.append("category_id", data.category_id?.id);
    newFormData.append("uom_code_1_id", data.uom_code_1_id?.id);
    newFormData.append("uom_code_1_value", data.uom_code_1_value);
    newFormData.append("short_description", data.short_description);
    newFormData.append("product_information", data.product_information);
    newFormData.append("text_line_1", data.text_line_1);
    newFormData.append("text_line_2", data.text_line_2);
    newFormData.append(
      "extra_security_check",
      data.extra_security_check ? 1 : 0
    );
    newFormData.append("is_gs1_product", data.is_gs1_product ? 1 : 0);
    newFormData.append("is_security_product", data.is_security_product ? 1 : 0);
    newFormData.append("pledge_type_id", data.pledge_type?.id);
    newFormData.append("vat", data.vat);
    newFormData.append("is_vat_product", data.is_vat_product ? 1 : 0);
    newFormData.append("is_compound_product", data.is_active ? 1 : 0);
    newFormData.append("uom_code_2_id", data.uom_code_2_id?.id);
    newFormData.append("uom_code_2_value", data.uom_code_2_value);
    newFormData.append("is_active", data.is_active ? 1 : 0);
    setFormData(newFormData);
    handleNext();
  };
  const handlePledgeTypeChange = (selectedId) => {
    setSelectedPledgeId(selectedId.id);
  };
  const productName = watch("name");
  const uomCode2Selected = watch("uom_code_2_id");
  const isVATProduct = watch("is_vat_product");
  const isProductLevelVAT = watch("is_vat_product_level");
  const isSecurityProduct = watch("is_security_product");
  useEffect(() => {
    setValue("receipt_label_text", productName);
  }, [productName]);
  useEffect(() => {
    if (!isProductLevelVAT) {
      setValue("vat", null);
    }
  }, [isProductLevelVAT]);
  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div className="card">
        {isLoading ? (
          <div className="row d-flex justify-content-center">
            <UnlimitLoading width={50} height={50} />
          </div>
        ) : (
          <div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <UnlimitInput
                    label={t("inputField.placeholder.enterEAN")}
                    id="ean"
                    type="text"
                    placeholder={t("inputField.placeholder.enterEAN")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      pattern: {
                        value: /^\d{4,14}$/,
                        message: t("inputField.validation.eanPattern"),
                      },
                    }}
                    error={errors.ean}
                    errorMessage={errors.ean?.message}
                  />
                </div>
                <div className="col-md-3">
                  <UnlimitInput
                    label={t("inputField.label.enterProductName")}
                    id="name"
                    type="text"
                    placeholder={t("inputField.placeholder.enterProductName")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      maxLength: {
                        value: 50,
                        message: t("inputField.validation.maxLength", {
                          value: 50,
                        }),
                      },
                    }}
                    error={errors.name}
                    errorMessage={errors.name?.message}
                  />
                </div>
                <div className="col-md-3">
                  <UnlimitInput
                    label={t("inputField.label.receiptLabelText")}
                    id="receipt_label_text"
                    type="text"
                    placeholder={t(
                      "inputField.placeholder.enterReceiptLabelText"
                    )}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      maxLength: {
                        value: 50,
                        message: t("inputField.validation.maxLength", {
                          value: 50,
                        }),
                      },
                    }}
                    error={errors.receipt_label_text}
                    errorMessage={errors.receipt_label_text?.message}
                  />
                </div>
                <div className="col-lg-1 d-flex flex-column">
                  <UnlimitToggle
                    label={t("inputField.label.isActive")}
                    id="is_active"
                    register={register}
                    column={true}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <UnlimitTextarea
                      label={t("inputField.label.productInformation")}
                      id="product_information"
                      register={register}
                      rules={{
                        required: t("inputField.validation.required"),
                        maxLength: {
                          value: 225,
                          message: t("inputField.validation.maxLength", {
                            value: 225,
                          }),
                        },
                      }}
                      numOfRows={3}
                      error={errors.product_information}
                      errorMessage={errors.product_information?.message}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <UnlimitTextarea
                      label={t("inputField.label.shortDescription")}
                      id="short_description"
                      register={register}
                      rules={{
                        required: t("inputField.validation.required"),
                        maxLength: {
                          value: 225,
                          message: t("inputField.validation.maxLength", {
                            value: 225,
                          }),
                        },
                      }}
                      numOfRows={3}
                      error={errors.short_description}
                      errorMessage={errors.short_description?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <UnlimitSelect
                    label={t("inputField.label.brand")}
                    id="brand_id"
                    errors={errors}
                    control={control}
                    isLoading={brandLoading}
                    register={register}
                    options={brand}
                    placeholder={t("inputField.placeholder.select")}
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <div className="col-md-3">
                  <UnlimitSelect
                    label={t("inputField.label.productCategory")}
                    id="category_id"
                    errors={errors}
                    control={control}
                    isLoading={categoryLoading}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    placeholder={t("inputField.placeholder.select")}
                    options={category}
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3 mt-2">
                  <UnlimitSelect
                    label={t("inputField.label.uomCode1")}
                    id="uom_code_1_id"
                    errors={errors}
                    control={control}
                    isLoading={UOMLoading}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    placeholder={t("inputField.placeholder.select")}
                    options={UOMCode}
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <UnlimitInput
                    label={t("inputField.label.uomCode1Value")}
                    id="uom_code_1_value"
                    type="number"
                    placeholder={t("inputField.placeholder.enterValue")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      min: {
                        value: 0,
                        message: t("inputField.validation.minValue", {
                          min: 0,
                        }),
                      },
                      maxLength: {
                        value: 50,
                        message: t("inputField.validation.maxLength", {
                          value: 50,
                        }),
                      },
                    }}
                    step="0.01"
                    error={errors.uom_code_1_value}
                    errorMessage={errors.uom_code_1_value?.message}
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <UnlimitSelect
                    label={t("inputField.label.uomCode2")}
                    id="uom_code_2_id"
                    errors={errors}
                    control={control}
                    isLoading={UOMLoading}
                    register={register}
                    rules={{
                      required: uomCode2Selected
                        ? t("inputField.validation.required")
                        : false,
                    }}
                    placeholder={t("inputField.placeholder.select")}
                    options={UOMCode}
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <UnlimitInput
                    label={t("inputField.label.uomCode2Value")}
                    id="uom_code_2_value"
                    type="number"
                    placeholder={t("inputField.placeholder.enterValue")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      min: {
                        value: 0,
                        message: t("inputField.validation.minValue", {
                          min: 0,
                        }),
                      },
                    }}
                    step="0.01"
                    error={errors.uom_code_2_value}
                    errorMessage={errors.uom_code_2_value?.message}
                  />
                </div>
                <div className="col-md-3 d-flex">
                  <div className="col-md-6">
                    <UnlimitToggle
                      label={t("inputField.label.isVATableProduct")}
                      id="is_vat_product"
                      register={register}
                      column={true}
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <UnlimitToggle
                      label={t("inputField.label.isProductLevelVAT")}
                      id="is_vat_product_level"
                      register={register}
                      column={true}
                      disabled={!isVATProduct}
                    />
                  </div> */}
                </div>
                <div className="col-lg-3">
                  {/* <UnlimitInput
                    label={t("inputField.label.vatPercentage")}
                    id="vat"
                    type="number"
                    step="0.01"
                    register={register}
                    rules={{
                      required: isProductLevelVAT
                        ? t("inputField.validation.required")
                        : false,
                      min: {
                        value: 0,
                        message: t("inputField.validation.minValue", {
                          min: 0,
                        }),
                      },
                      max: {
                        value: 100,
                        message: t("inputField.validation.maxValue", {
                          max: 100,
                        }),
                      },
                      validate: (value) => {
                        const isValid =
                          !isNaN(value) && value >= 0 && value <= 100;
                        return (
                          isValid || t("inputField.validation.validNumber")
                        );
                      },
                    }}
                    placeholder={t("inputField.placeholder.enterVATPercentage")}
                    error={errors.vat}
                    errorMessage={errors.vat?.message}
                    disabled={!isProductLevelVAT}
                  /> */}
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <UnlimitToggle
                    label={t("inputField.label.isSecurityableProduct")}
                    id="is_security_product"
                    register={register}
                    column={true}
                  />
                </div>
                <div className="col-md-3">
                  <UnlimitSelect
                    label={t("inputField.label.pledgeType")}
                    id="pledge_type"
                    errors={errors}
                    control={control}
                    register={register}
                    isLoading={pledgeTypeLoading}
                    rules={{
                      required: isSecurityProduct
                        ? t("inputField.validation.required")
                        : false,
                    }}
                    placeholder={t("inputField.placeholder.select")}
                    options={pledge}
                    optionLabel="material"
                    optionValue="id"
                    onChange={handlePledgeTypeChange}
                  />
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <p className="d-flex flex-column">
                    <span className="fw-bold">
                      {t("inputField.label.pledgePrice")}:
                    </span>
                    {pledge.find((item) => item.id === selectedPledgeId)
                      ?.amount || 0}{" "}
                    DKK
                  </p>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 mt-2">
                  <UnlimitToggle
                    label={t("inputField.label.extraSecurityCheck")}
                    id="extra_security_check"
                    register={register}
                    column={true}
                  />
                </div>
                <div className="col-md-3 mt-2">
                  <UnlimitToggle
                    label={t("inputField.label.isCompoundProduct")}
                    id="is_compound_product"
                    register={register}
                    column={true}
                  />
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <UnlimitInput
                    label={t("inputField.label.textLine1")}
                    id="text_line_1"
                    type="text"
                    placeholder={t("inputField.placeholder.enterContent")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      maxLength: {
                        value: 50,
                        message: t("inputField.validation.maxLength", {
                          value: 50,
                        }),
                      },
                    }}
                    error={errors.text_line_1}
                    errorMessage={errors.text_line_1?.message}
                  />
                </div>
                <div className="col-md-3">
                  <UnlimitInput
                    label={t("inputField.label.textLine2")}
                    id="text_line_2"
                    type="text"
                    placeholder={t("inputField.placeholder.enterContent")}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      maxLength: {
                        value: 50,
                        message: t("inputField.validation.maxLength", {
                          value: 50,
                        }),
                      },
                    }}
                    error={errors.text_line_2}
                    errorMessage={errors.text_line_2?.message}
                  />
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-lg-12 mb-4 mt-3">
                  <UnlimitSubmitButton
                    label={t("general.button.next")}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default CatalogStep1;
