import React, { useEffect, useRef, useState } from "react";
import UnlimitInput from "./ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import UnlimitSubmitButton from "./ui-kit/unlimit-submit-button";
import UnlimitSelect from "./ui-kit/unlimit-select";
import UnlimitFileUpload from "./ui-kit/unlimit-fileupload";
import httpService from "../services/http/peoples/employees.service";
import notificationService from "../services/toaster.service";
import { store } from "../core/redux/store";
import { hydrateErrors } from "../utils/error.utils";
import { useTranslation } from "react-i18next";

const AddDocuments = ({ onHide, recordId, recordName, createDocument }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    control,
    setError,
    reset,
    setValue,
  } = useForm();
  const { errors } = formState;
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const modalRef = useRef();
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      if (selectedFile) {
        formData.append("attachment", selectedFile);
      }
      formData.append(`id`, recordId);
      try {
        await createDocument(formData);
      } catch (error) {
        if (error.validation_error?.errors) {
          hydrateErrors(error.validation_error.errors, setError);
        }
      }
      reset();
      onHide();
      setSelectedFile(null);
      setImageUrl("");
      modalHide();
    } finally {
      setIsLoading(false);
    }
  };
  const modalHide = () => {
    const cancelButton = document.querySelector(".btn-cancel");
    if (cancelButton) {
      cancelButton.click();
    }
  };

  useEffect(() => {
    const modalElement = modalRef.current;
    const handleShow = () => {
      console.log("Handle show");
    };
    const handleHide = () => {
      reset();
      onHide();
    };
    modalElement.addEventListener("show.bs.modal", handleShow);
    modalElement.addEventListener("hide.bs.modal", handleHide);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShow);
      modalElement.removeEventListener("hide.bs.modal", handleHide);
    };
  }, [onHide]);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setImageUrl(URL.createObjectURL(file));
  };
  return (
    <div>
      <div className="modal fade" id="add-department" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content px-2 pt-3">
                <button
                  type="button"
                  className="close w-100 justify-content-end text-black"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <UnlimitInput
                            label={t("inputField.label.documentName")}
                            id="name"
                            type="text"
                            placeholder={t("inputField.placeholder.enterDocumentName")}
                            register={register}
                            rules={{
                              required: {
                                value: true,
                                message: t("inputField.validation.required"),
                              },
                            }}
                            error={errors.name}
                            errorMessage={errors.name?.message}
                          />
                        </div>
                      </div>
                      <div className="input-blocks m-0">
                        <UnlimitFileUpload
                          label={t("inputField.label.chooseFile")}
                          id="image"
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            validate: {
                              fileSize: (file) => {
                                return file[0]?.size <= 20000000 || t("inputField.validation.fileSize");
                              },
                            },
                          }}
                          error={errors.image}
                          errorMessage={errors.image?.message}
                          onChange={handleImageChange}
                          accept=".jpg,.jpeg,.png,.pdf"
                        />
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        {t("general.button.cancel")}
                      </button>
                      <UnlimitSubmitButton isLoading={isLoading} label={t("general.button.submit")} />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocuments;
