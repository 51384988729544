import axios from "axios";
import { logout } from "../../core/redux/reducers/auth.slice";
import { store } from "../../core/redux/store";
import notificationService from "../toaster.service";

const clearLocalStorage = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
const token = "13|SgSPduKZMO6BRejSU7GLkuMWu6VcA4wxUPbjVS3o1ae684d9";
const performLogout = () => {
  store.dispatch(logout());
  clearLocalStorage();
};

const redirectToLogin = () => {
  performLogout();
};

const consoleErrorPerformRedirection = (error) => {
  console.error(error?.response?.data?.message || error);
  
  // Check if error response contains detailed errors
  const errors = error?.response?.data?.errors;
  if (errors) {
    // Loop through each field in the errors object
    Object.keys(errors).forEach((key) => {
      // Display each error message for the specific key
      errors[key].forEach((errorMessage) => {
        notificationService.errorMessage(errorMessage); // Show individual error messages
      });
    });
  } else {
    // Fallback: display the main error message if no detailed errors are found
    notificationService.errorMessage(error?.response?.data?.message);
  }

  // Redirect to login if unauthorized
  if (error?.response?.status === 401) {
    redirectToLogin();
  }
};


//Get Method
const getMethod = async (
  endpoint,
  authentication = true,
  showError = false,
  data,
) => {
  let params = {};
  let bearer_token;
  if (authentication) {
    const { auth } = store.getState();
    bearer_token = auth.accessToken || localStorage.getItem("accessToken");

    params = {
      headers: {
        Authorization: `Bearer ${bearer_token}`,
        Accept: "application/json",
      },
    };
  }
  if (data) {
    params.params = data;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (showError) {
          consoleErrorPerformRedirection(error);
        }
        reject(error);
      });
  });
};

// Post Method
const postMethod = async (
  endpoint,
  authentication = true,
  data = null,
  multipart = false,
  showError = true,
) => {
  let headers = {};

  if (authentication) {
    const { auth } = store.getState();
    var bearer_token = auth.accessToken || localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${bearer_token}`;
    headers["Accept"] = "application/json";
  }
  if (multipart) {
    headers["content-type"] = "multipart/form-data";
  } else {
    headers["content-type"] = "application/json";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, data, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (showError) {
          consoleErrorPerformRedirection(error);
        }
        reject({
          ...error,
          validation_error: {
            message: error?.response?.data?.message ?? "Unknown Error",
            errors: error?.response?.data?.errors ?? {},
          },
        });
      });
  });
};
// Post Method
const postMethodCustomHeader = async (
  endpoint,
  authentication = true,
  data = null,
  multipart = false,
  showError = true,
) => {
  let headers = {};

  if (authentication) {
    headers["Authorization"] = `Bearer ${authentication}`;
    headers["Accept"] = "application/json";
  }
  if (multipart) {
    headers["content-type"] = "multipart/form-data";
  }
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, data, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (showError) {
          consoleErrorPerformRedirection(error);
        }
        reject({
          ...error,
          validation_error: {
            message: error?.response?.data?.message ?? "Unknown Error",
            errors: error?.response?.data?.errors ?? {},
          },
        });
      });
  });
};

// Delete Method
const deleteMethod = async (endpoint, authentication = true, data = null) => {
  header = {};
  if (authentication) {
    const { auth } = store.getState();
    var bearer_token = auth.accessToken || localStorage.getItem("accessToken");
    var header = {
      headers: {
        Authorization: `Bearer ${bearer_token}`,
      },
    };
  }
  return new Promise((resolve, reject) => {
    axios
      .delete(endpoint, header)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        consoleErrorPerformRedirection(error);
        reject(error);
      });
  });
};

//Patch Method
const patchMethod = async (
  endpoint,
  authentication = true,
  data = null,
  multipart = false,
  contentType= "multipart/form-dat"
) => {
  let headers = {};

  if (authentication) {
    const { auth } = store.getState();
    var bearer_token = auth.accessToken || localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${bearer_token}`;
  }
  if (multipart) {
    headers["content-type"] = "multipart/form-data";
  }
  headers["content-type"] = contentType;
  return new Promise((resolve, reject) => {
    axios
      .patch(endpoint, data, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        consoleErrorPerformRedirection(error);
        reject(error);
      });
  });
};

//Patch Method
const putMethod = async (
  endpoint,
  authentication = true,
  data = null,
) => {
  let headers = {};

  if (authentication) {
    const { auth } = store.getState();
    var bearer_token = auth.accessToken || localStorage.getItem("accessToken");
    headers["Authorization"] = `Bearer ${bearer_token}`;
  }

    headers["content-type"] = "application/x-www-form-urlencoded";
  return new Promise((resolve, reject) => {
    axios
      .put(endpoint, data, { headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        consoleErrorPerformRedirection(error);
        reject(error);
      });
  });
};

export default {
  getMethod,
  postMethod,
  deleteMethod,
  patchMethod,
  putMethod,
  postMethodCustomHeader
};
