import { useTranslation } from "react-i18next";
import { useState } from "react";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import RackList from "../../../components/RackList";

export const OutletLayout = () => {
  const { t } = useTranslation();
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("outlet.outlet"), link: "/outlets-list" },
    { label: t("outlet.outletLayout"), active: true },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h4>{t("outlet.outletLayout")}</h4>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <div className="card table-list-card">
          <div className="card-body p-3">
            <RackList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutletLayout;
