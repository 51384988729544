import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/variant.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import designaionHttpService from "../../../services/http/peoples/designation.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";

const VarientForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  const [vendor, setVendor] = useState([]);
  const [variantType, setVariantType] = useState([]);
  const [outlets, setOutlets] = useState([]);

  const vendors = useSelector((state) => state.vendors.vendors);

  async function fetchOutlet() {
    try {
      const outlet = await outletHttpService.outletList();
      setOutlets(outlet?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching outlet data:", error);
    }
  }
  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getVariant();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
    if (id) {
      getVariant();
    }
    fetchOutlet();
    fetchVariantType();
  }, [id]);

  const getVariant = async () => {
    try {
      setPageLoading(true);
      await httpService.getVariant(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
        }
      });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("address", data.address);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("description", data.description);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor.business_name,
      value: data.vendor.id,
      logo: data.vendor.logo,
    });
    setValue("outlet_id", {
      ...data.outlet,
      label: data.outlet.name,
      value: data.outlet.id,
    });
    setValue("variant_type", {
      ...data.variant,
      label: data.variant.name,
      value: data.name.id,
    });
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("vendor_id", data.vendor_id.id);
      formData.append("outlet_id", data.outlet_id.id);
      formData.append("variant_type", data.variant_type.id);
      if (id) {
        formData.append("id", id);
        await httpService
          .updateVariant(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                "Variant updated successfully!"
              );
              setTimeout(() => {
                navigate(all_routes.variantList);
              }, 1000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        await createVariant(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new variant
  const createVariant = async (formData) => {
    try {
      await httpService
        .createVariant(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage("Variant created successfully!");
            navigate(all_routes.variantList);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Variant", link: "/variant-list" },
    { label: id ? "Edit Variant" : "Add Variant", active: true },
  ];
  async function fetchVariantType() {
    const variantType = await designaionHttpService.designationsList();
    setVariantType(variantType?.data?.data?.data);
  }
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">Variant</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Variant Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Product"
                        id="vendor_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Choose Vendor"
                        options={vendor}
                        showLogo={true}
                        optionLabel="business_name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Outlet"
                        id="outlet_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Select Outlet"
                        options={outlets}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitSelect
                        label="Variant Type"
                        id="variant_type"
                        errors={errors}
                        control={control}
                        register={register}
                        options={variantType}
                        rules={{
                          required: "This Field is required.",
                        }}
                        placeholder="Select Variant Type"
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label="Variant Name"
                        id="name"
                        type="text"
                        placeholder="Enter Variant Name"
                        register={register}
                        rules={{
                          required: "This Field is required.",
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label="Phone"
                        id="phone"
                        type="text"
                        placeholder="Enter Phone No."
                        register={register}
                        rules={{
                          required: "This Field is required.",
                          pattern: {
                            value:
                              /^\+?\d+$/,
                            message: "Enter a valid phone number.",
                          },
                          maxLength: { value: 20, message: "This field cannot accept more than 20 characters." }
                        }}
                        error={errors.phone}
                        errorMessage={errors.phone?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="Enter Email"
                        register={register}
                        rules={{
                          required: "This Field is required.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Enter a valid email address.",
                          },
                        }}
                        error={errors.email}
                        errorMessage={errors.email?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <UnlimitTextarea
                          label="Description"
                          id="description"
                          register={register}
                          rules={{
                            required: "This Field is required.",
                          }}
                          numOfRows={3}
                          error={errors.description}
                          errorMessage={errors.description?.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.variantList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        Cancel
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={id ? "Update" : "Submit"}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Variant Form End */}
      </div>
    </div>
  );
};

export default VarientForm;
