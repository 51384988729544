import { useState, useEffect } from 'react';
import dashboardService from '../../../services/http/dashboard/dashboard.service';
import useRoleAndPerm from '../../../utils/permissionFunction';
import { useSelector } from 'react-redux';

const useDashboardData = (start_date, end_date, vendor_id, outlet_id,selectedYear) => {
  const { isVendorOrOutlet, isOutletRole,isVendorRole } = useRoleAndPerm();
  const auth = useSelector((state) => state.auth);
  const [dashboardData, setDashboardData] = useState({
    widgetData: null,
    salePurchaseGraphData: null,
    topOutletsData: null,
    topProductsData: null,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const requestData = {
        start_date,
        end_date,
        vendor_id,
        outlet_id,
      };
      if(!vendor_id && isVendorRole){
        requestData.vendor_id =auth?.user?.vendor?.id
      }else if(isOutletRole){
        requestData.vendor_id =auth?.user?.outlet?.vendor?.id
        requestData.outlet_id = auth?.user?.outlet?.id
      }
      
      if(isVendorOrOutlet && !requestData.vendor_id){
        return;
      }
      try {
        // Call the functions from your service file
        const [widgetData, salePurchaseGraphData, topOutletsData, topProductsData] = await Promise.all([
          dashboardService.GetWidgetData(requestData),
          dashboardService.salePurchaseGraph(requestData,selectedYear),
          dashboardService.topOutlets(requestData),
          dashboardService.topProducts(requestData),
        ]);
        // Update state with the fetched data
        setDashboardData({
          widgetData:widgetData?.data?.data,
          salePurchaseGraphData:salePurchaseGraphData?.data?.data,
          topOutletsData:topOutletsData?.data?.data,
          topProductsData:topProductsData?.data?.data,
        });

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [start_date, end_date, vendor_id, outlet_id,selectedYear]);

  return { dashboardData, loading, error };
};

export default useDashboardData;
