import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createProduct = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.product.create}`,
    true,
    data,
    true,
  );
};
const productFromCatalog = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.product.fromCatalog}`,
    true,
    data,
    true,
  );
};
const updateProduct = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.product.update}`,
    true,
    data,
    true,
  );
};
const productList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.product.list}`,
    true,
    true,
    data,
  );
};
const deleteProduct = async (id) => {
  const url = `${ENDPOINT.products.product.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getProduct = async (id) => {
  const url = `${ENDPOINT.products.product.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const exportProducts = async (data) => {
  return await API.postMethod(ENDPOINT.products.product.exportAs, true, data);
};
const updateImageAttachment = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.product.updateAttachment}`,
    true,
    data,
    true
  );
};
export default {
  getProduct,
  createProduct,
  updateProduct,
  productList,
  deleteProduct,
  productFromCatalog,
  exportProducts,
  updateImageAttachment
};
