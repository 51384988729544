import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createNotification = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.notification.create}`,
    true,
    data,
    json?false:true,
  );
};
const updateNotification = async (data,json) => {
  return await API.postMethod(
    `${ENDPOINT.settings.notification.update}`,
    true,
    data,
    json?false:true,
  );
};
const notificationList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.notification.list}`, true, true, data);
};
const myNotificationList = async (data) => {
  return await API.getMethod(`${ENDPOINT.settings.notification.myList}`, true, true, data);
};
const deleteNotification = async (id) => {
  const url = `${ENDPOINT.settings.notification.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const markOneAsReadNotification = async (id) => {
  const url = `${ENDPOINT.settings.notification.markOneAsRead}`.replace(":id", id);
  return await API.getMethod(url, true);
};
const markAllAsReadNotification = async (id) => {
  const url = `${ENDPOINT.settings.notification.markAllAsRead}`;
  return await API.getMethod(url, true);
};
const getNotification = async (id) => {
  const url = `${ENDPOINT.settings.notification.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};
export default {
  getNotification,
  createNotification,
  updateNotification,
  notificationList,
  deleteNotification,
  myNotificationList,
  markAllAsReadNotification,
  markOneAsReadNotification
};
