import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/product-group-list.service";
import usePagination from "../../../custom-hooks/usePagination";
import { Link, useNavigate } from "react-router-dom";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import groupHttpService from "../../../services/http/products/product-group.service";
import { useFieldArray, useForm } from "react-hook-form";
import ProductFromCatalogSearch from "./add-product-group-list-search";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { Checkbox } from "antd";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { all_routes } from "../../../Router/all_routes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import notificationService from "../../../services/toaster.service";
import { hydrateErrors } from "../../../utils/error.utils";
import { useTranslation } from "react-i18next";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import useRoleAndPerm from "../../../utils/permissionFunction";

const AddProductGroupList = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const { isOutletRole,isVendorRole } = useRoleAndPerm();
  const [errMessage, setErrMessage] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [catalogList, setCatalogList] = useState([]);
  const [isListVisible, setIsListVisible] = useState(true);
  const [selectedCatalogItems, setSelectedCatalogItems] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [vendorId, setVendorId] = useState();
  const vendors = useSelector((state) => state.vendors.vendors);
  const auth = useSelector((state) => state.auth);
  const { control, handleSubmit, register, formState, setError } = useForm();
  const { errors } = formState;
  const { fields, append } = useFieldArray({
    control,
    name: "catalog_variants",
  });
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();

  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };
  async function fetchGroupData(vendorID) {
    const data = {
      vendor_id: vendorID,
    };
    try {
      const group = await groupHttpService.productGroupList(data);
      setGroupData(group?.data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    setVendor(vendors?.data?.data);
    if (isOutletRole) {
      fetchGroupData();
    }
  }, []);
  const handleCheckboxChange = (record, checked) => {
    if (checked) {
      setSelectedCatalogItems([...selectedCatalogItems, record]);
    } else {
      setSelectedCatalogItems(
        selectedCatalogItems.filter((item) => item.id !== record.id)
      );
    }
  };
  const handleSelectAll = (checked) => {
    const selectedItems = checked ? [...catalogList] : [];
    setSelectedCatalogItems(selectedItems);
  };
  const columns = [
    {
      title: <Checkbox onChange={(e) => handleSelectAll(e.target.checked)} />,
      dataIndex: "index",
      align: "center",
      render: (value, item, index) => (
        <Checkbox
          style={{ color: "red" }}
          checked={selectedCatalogItems.some(
            (selectedItem) => selectedItem.id === item.id
          )}
          onChange={(e) => handleCheckboxChange(item, e.target.checked)}
        />
      ),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => record?.catalog?.ean,
    },
    {
      title: t("tableColumnName.productName"),
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.product_images[0]?.file}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.catalog?.name}
        </div>
      ),
    },
    {
      title: t("category.category"),
      dataIndex: "catalog.category.name",
      render: (text, record) => record?.catalog?.category?.name,
    },
    {
      title: t("tableColumnName.sku"),
      dataIndex: "sku",
    },
    {
      title: t("tableColumnName.uomCode1"),
      dataIndex: "catalog.uom_code_1.name",
      render: (text, record) => record.catalog?.uom_code_1.name,
    },
    {
      title: t("brand.brand"),
      dataIndex: "catalog.brand.name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.catalog?.brand?.image}
            alt="Brand"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {text}
        </div>
      ),
    },
    {
      title: t("tableColumnName.minOrderQty"),
      dataIndex: "min_order_qty",
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="p-2"
              onClick={() => navigate(`/product/${record.id}/details`)}
            >
              <i className="feather-eye"></i>
            </a>
          </div>
        </div>
      ),
    },
  ];

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    {
      label: t("productGroup.productGroupList"),
      link: "/product-group-list-index",
    },
    { label: t("productGroup.addProductGroup"), active: true },
  ];
  const MySwal = withReactContent(Swal);
  const onSubmit = (data) => {
    if (selectedCatalogItems.length < 1) {
      return MySwal.fire({
        title: t("general.prompts.deleted"),
        text: t("inputField.validation.AtleastOneItem"),
        className: "btn btn-success",
        confirmButtonText: t("general.button.ok"),
        customClass: {
          confirmButton: "btn btn-warning",
        },
      });
    }
    const requestData = {
      group_id: selectedGroup.id,
      is_active: data.is_active || 1,
      product_id: selectedCatalogItems.map((item, index) => item.id),
    };
    productFromCatalog(requestData);
  };
  const productFromCatalog = async (formData) => {
    try {
      await httpService
        .createProductGroupList(formData, true)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              t("productGroup.productGroupListCreateSuccess")
            );
            navigate(all_routes.productGroupListIndex);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
  };
  useEffect(()=>{
    if (isVendorRole) {
      handleVendorChange({id:auth?.user?.vendor?.id})
    }
  },[auth])
  const handleVendorChange = (selectedOption) => {
    fetchGroupData(selectedOption.id);
    setVendorId(selectedOption.id)
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4> {t("productGroup.productGroupList")} </h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="m-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    {!isVendorRole && (
                      <div className="col-md-6">
                        <UnlimitSelect
                          label={t("vendor.vendor")}
                          id="vendor_id"
                          errors={errors}
                          control={control}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                          }}
                          placeholder={t("inputField.placeholder.chooseVendor")}
                          options={vendor}
                          showLogo={true}
                          onChange={handleVendorChange}
                          optionLabel="business_name"
                          optionValue="id"
                        />
                      </div>
                    )}
                    <div className="col-md-6">
                      <UnlimitSelect
                        label={t("tableColumnName.groupName")}
                        id={`group_id`}
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.ChooseGroup")}
                        options={groupData}
                        onChange={handleGroupChange}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ProductFromCatalogSearch
            setIsListVisible={setIsListVisible}
            setCatalogList={setCatalogList}
            setErrMessage={setErrMessage}
            vendorId={vendorId}
          />
          {/* /Search */}
          {catalogList.length > 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card table-list-card">
                <div className="card-body">
                  {/* /Filter */}
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={catalogList}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <Link to={all_routes.productGroupListIndex} className="me-2">
                <button type="button" className="btn btn-cancel">
                  {t("general.button.cancel")}
                </button>
              </Link>
              <UnlimitSubmitButton
                label={t("general.button.submit")}
                isLoading={isLoading}
              />
            </form>
          ) : (
            <p className="fw-bold fs-4 ms-2">{errMessage}</p>
          )}
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default AddProductGroupList;
