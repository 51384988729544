import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/attribute.service";
import uomService from "../../../services/http/products/uom.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitRadio from "../../../components/ui-kit/unlimit-radio";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";

const AttributeForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uomList, setUomList] = useState([]);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError,
    control,
    watch,
  } = useForm({
    defaultValues: {
      options: [""],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "options",
      rules: {
        required: "Please add at-least on input field.",
      },
    },
  );
  const { errors } = formState;
  const { id } = useParams();
  const selectedType = watch("type");
  const getAttribute = async () => {
    await httpService
      .getAttribute(id)
      .then((response) => {
        if (response && response.status == 200) {
          const data = response.data.data;
          setValue("name", data.name);
          setValue("type", data.type ?? "text");
          if (data.type == "text") {
            setValue("text_field", data.attribute_option);
          }
          if (data.type == "number") {
            setValue("number_field", data.attribute_option);
          }
          setValue("is_active", data.is_active == 1 ? true : false);
          setUomList((prevUomList) => {
            let uom = prevUomList.find((item) => item.id == data.uom?.id);
            if (uom) {
              uom = {
                ...uom,
                label: uom.name,
                value: uom.id,
              };
              setValue("uom", uom);
            }
            return prevUomList;
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getUomList = async () => {
    try {
      await uomService
        .uomList()
        .then((response) => {
          if (response.status == 200) {
            setUomList(response.data.data?.data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.log("Error ", error);
    }
  };
  const fetchPredefinedValues = async () => {
    await getUomList();
    if (id) {
      await getAttribute();
    }
  };
  useEffect(() => {
    fetchPredefinedValues();
  }, [id]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.uom) {
        formData.append("uom_id", data.uom.id);
      }
      formData.append("type", data.type);
      if (data.type == "dropdown") {
        formData.append("options", JSON.stringify(data.options));
      } else if (data.type == "text") {
        formData.append("options", data.text_field);
      } else if (data.type == "number") {
        formData.append("options", data.number_field);
      }
      formData.append("is_active", data.is_active ? 1 : 0);
      if (id) {
        formData.append("id", id);
        await updateAttribute(formData);
      } else {
        await createAttribute(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const updateAttribute = async (formData) => {
    await httpService
      .updateAttribute(formData)
      .then((response) => {
        if (response.status == 200) {
          notificationService.successMessage("Attribute updated successfully!");
          setTimeout(() => {
            navigate(all_routes.attributeList);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const createAttribute = async (formData) => {
    try {
      const response = await httpService.createAttribute(formData);
      if (response && response.status == 200) {
        notificationService.successMessage("Attribute created successfully!");
        setTimeout(() => {
          navigate(all_routes.attributeList);
        }, 500);
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: "Attributes", link: "/attribute-list" },
    { label: id ? "Edit Attribute" : "Add Attribute", active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">Add New Attribute</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitInput
                      label="Attribute Name"
                      id="name"
                      type="text"
                      placeholder="Enter Attribute Name"
                      register={register}
                      rules={{
                        required: {
                          value: true,
                          message: "This Field is required.",
                        },
                      }}
                      error={errors.name}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitSelect
                      label="UOM"
                      id="uom"
                      errors={errors}
                      control={control}
                      register={register}
                      rules={{
                        required: "This Field is required.",
                      }}
                      placeholder="Select UOM"
                      options={uomList}
                      optionLabel="name"
                      optionValue="id"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="input-blocks mb-3">
                    <UnlimitToggle
                      isChecked={id ? false : true}
                      label="Status"
                      id="is_active"
                      register={register}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className="d-flex">
                    {/* <UnlimitRadio
                      label="Dropdown"
                      name="type"
                      id="dropdown"
                      value="dropdown"
                      register={register}
                      rules={{
                        required: "This Field is required.",
                      }}
                    />*/}
                    <UnlimitRadio
                      label="Text"
                      name="type"
                      id="text"
                      value="text"
                      register={register}
                      rules={{
                        required: "This Field is required.",
                      }}
                    />
                    <UnlimitRadio
                      label="Number"
                      name="type"
                      id="number"
                      value="number"
                      register={register}
                      rules={{
                        required: "This Field is required.",
                      }}
                    />
                  </div>
                  <div className="text-danger">
                    {errors.type && (
                      <span className="error">{errors.type?.message}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  {selectedType == "dropdown" && (
                    <div>
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => {
                            append("");
                          }}
                          type="button"
                          className="btn btn-success me-2"
                        >
                          Add
                        </button>
                      </div>
                      <div className="row mt-2">
                        {fields.map((field, index) => (
                          <>
                            <div
                              className="col-11 unlimit-uikit-input"
                              key={field.id}
                            >
                              <input
                                className="form-control"
                                type="text"
                                {...register(`options.${index}`, {
                                  required: "This field is required.",
                                })}
                              />
                              <span className="error">
                                {errors?.options?.length &&
                                  errors?.options[index]?.message}
                              </span>
                            </div>
                            <div className="col-1">
                              <button
                                onClick={() => {
                                  remove(index);
                                }}
                                type="button"
                                className="btn btn-danger me-1 ms-1"
                              >
                                Remove
                              </button>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {selectedType == "text" && (
                    <UnlimitInput
                      label="Attribute Option"
                      id="text_field"
                      type="text"
                      placeholder="Attribute Option"
                      register={register}
                      rules={{
                        required: "This Field is required.",
                      }}
                      error={errors.text_field}
                      errorMessage={errors.text_field?.message}
                    />
                  )}
                  {selectedType == "number" && (
                    <UnlimitInput
                      label="Attribute Option"
                      id="number_field"
                      type="number"
                      placeholder="Attribute Option"
                      register={register}
                      rules={{
                        required: "This Field is required.",
                      }}
                      error={errors.number_field}
                      errorMessage={errors.number_field?.message}
                    />
                  )}
                </div>
                {/*  <span className="error">{errors.options?.root?.message}</span>*/}
              </div>
              <div className="col-lg-12 mb-4 mt-4">
                <Link to={all_routes.attributeList}>
                  <button type="button" className="btn btn-cancel me-2">
                    Cancel
                  </button>
                </Link>
                <UnlimitSubmitButton
                  label={id ? "Update" : "Submit"}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AttributeForm;
