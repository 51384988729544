import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { Filter, Plus } from "feather-icons-react/build/IconComponents";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import httpService from "../../../services/http/purchases/purchase.service";
import usePagination, { createInitialPagination } from "../../../custom-hooks/usePagination";
import { useNavigate } from "react-router-dom";
import ConfirmationAlert from "../../../components/confirm-alert";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
const PurchaseList = () => {
  const { t } = useTranslation();
  const { hasPermission, isVendorOrOutlet } = useRoleAndPerm();
  const [loading, setLoading] = useState(false);
  const initialPagination = createInitialPagination(t)
  const [designations, setDesignations] = useState([]);
  const [supplierNameFilter, setSupplierNameFilter] = useState(null);
  const [vendor, setVendor] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { paginationParams, updatePaginationParams } = usePagination(initialPagination);
  const navigate = useNavigate();
  const getPurchaseList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      vendor_id: selectedVendor.value,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .purchaseList(data)
      .then((response) => {
        if (response.status == 200) {
          setDesignations(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {
      supplier_name: supplierNameFilter,
    };
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    if(!pagination){
      pagination = initialPagination
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getPurchaseList(pagination, nonNullFilters, sorter);
  };

  useEffect(() => {
    if (selectedVendor?.value) {
      handleTableChange();
    }
  }, [selectedVendor]);
  useEffect(() => {
    if (isVendorOrOutlet) {
      getPurchaseList();
    } else {
      setVendor(vendors?.data?.data);
      let selectedVendor = vendors?.data?.data[0];

      if (vendors?.data?.data?.length) {
        const vendor = vendors.data.data.find((vendor) =>
          vendor.business_name.toLowerCase().includes("near")
        );
        if (vendor) {
          selectedVendor = vendor;
        }
      }

      setSelectedVendor({
        ...selectedVendor,
        value: selectedVendor?.id,
        label: selectedVendor?.business_name,
      });
    }
  }, []);
  const onConfirmDelete = async (id) => {
    await httpService
      .deletePurchase(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("purchase.purchaseDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          getPurchaseList();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addNewFile = () => {
    navigate(all_routes.purchaseUpload);
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "order_no",
      render: (text, record) => {
        return record?.order_no ?? "";
      },
    },
    {
      title: t("tableColumnName.purchaseDate"),
      dataIndex: "purchase_date",
      render: (text) => {
        return moment(text).format("DD MMM YYYY");
      },
      sorter: true,
    },
    {
      title: t("supplier.supplier"),
      dataIndex: "supplier",
      render: (text, record) => (
        <Link
          to={`/supplier/${record.supplier_id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.supplier?.image}
              alt="Contact"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record?.supplier?.name}
          </div>
        </Link>
      ),
    },
    {
      title: "No. of Products",
      dataIndex: "total_items",
      align: "center",
      render: (text, record) => {
        return record?.total_items ?? "";
      },
    },
    // {
    //   title: t("tableColumnName.createdBy"),
    //   dataIndex: "created_by",
    //   render: (text, record) => {
    //     return record.created_by ?? "";
    //   },
    // },
    // {
    //   title: t("tableColumnName.createdAt"),
    //   dataIndex: "created_at",
    //   render: (text) => {
    //     return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
    //   },
    // },

    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["purchase-view", "purchase-delete"]),
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["purchase-view"]) && (
              <Link
                to={`/purchase/${record.id}/details`}
                className="me-2 edit-icon p-2"
              >
                <i className="feather-eye"></i>
              </Link>
            )}
            {hasPermission(["purchase-delete"]) && (
              <ConfirmationAlert onConfirm={() => onConfirmDelete(record.id)} />
            )}
          </div>
        </div>
      ),
    },
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("purchase.purchases"), active: true },
  ];
  const vendorOptions = vendor?.map((vendor) => ({
    ...vendor,
    value: vendor.id,
    label: vendor.business_name,
  }));
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("purchase.purchaseList")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
            {hasPermission(["purchase-add"]) && (
              <div className="page-btn">
                <button onClick={addNewFile} className="btn btn-added">
                  <Plus className="me-2 iconsize" />
                  {t("general.actions.importFromFile")}
                </button>
              </div>
            )}
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              {!isVendorOrOutlet && (
                <div
                  className="row align-items-end"
                  style={{ padding: "24px 24px 0" }}
                >
                  <div className="mb-0 col-lg-5 col-sm-5 col-12">
                    <div className="unlimit-uikit-select">
                      <label className="mb-2">{t("vendor.vendor")}</label>
                      <Select
                        options={vendorOptions}
                        className="select custom-select"
                        placeholder={t("inputField.placeholder.chooseVendor")}
                        value={selectedVendor}
                        formatOptionLabel={({ label, logo_url }) => (
                          <div className="d-flex">
                            <img
                              src={logo_url}
                              alt=""
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                objectFit: "contain",
                              }}
                            />
                            <span style={{ marginLeft: 10 }}>{label}</span>
                          </div>
                        )}
                        onChange={(selectedOption) =>
                          setSelectedVendor(selectedOption)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="table-top row">
                <div className="col-lg-5 col-sm-4 col-12">
                  <input
                    type="text"
                    placeholder={t("inputField.placeholder.enterSupplierName")}
                    value={supplierNameFilter}
                    onChange={(e) => setSupplierNameFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleTableChange();
                      }
                    }}
                    className="form-control form-control-sm formsearch-custom ps-2"
                  />
                </div>
                <div className="col-lg-3 col-sm-4 col-12 ms-auto">
                  <div className="input-blocks mb-0">
                    <button
                      className="btn btn-filters col-auto ms-auto px-2"
                      style={{ width: "fit-content" }}
                      onClick={handleTableChange}
                    >
                      <i data-feather="search" className="feather-search m-0" />{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <UnlimitTable
                  pagination={paginationParams.pagination}
                  columns={columns}
                  dataSource={designations}
                  rowKey={(record) => record.id}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </div>
  );
};

export default PurchaseList;
