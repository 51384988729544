import { useState } from "react";

const usePagination = (initialParams) => {
  const [paginationParams, setPaginationParams] = useState(initialParams);

  const updatePaginationParams = (newParams) => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      ...newParams,
    }));
  };

  return {
    paginationParams,
    updatePaginationParams,
  };
};
export const createInitialPagination = (t) => {
  return {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  };
};
export default usePagination;
