import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/settings/country.service";
import { useGetCountries } from "../../../services/http/side-Effects/location";
import TimezoneHttpService from "../../../services/http/settings/timezone.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import locationService from "../../../services/http/location.service";
import { setCountries } from "../../../core/redux/reducers/locations.slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const CountryForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const fetchCountries = useGetCountries();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  const [Timezone, setTimezone] = useState([]);
  const [prevTimeZone, setPrevTimeZone] = useState([]);

  async function fetchTimezone() {
    const data = {
      per_page: 250,
    };
    const Timezones = await TimezoneHttpService.timezoneList(data);
    setTimezone(Timezones?.data?.data?.data);
  }

  useEffect(() => {
    fetchTimezone();
    if (id) {
      getCountry();
    }
  }, [id]);
  useEffect(() => {
    if (Timezone && prevTimeZone) {
      const timezoneOptions = prevTimeZone
        .map((timezone) => {
          const matchingTimezone = Timezone?.find(
            (tz) => tz.zone_name === timezone.zoneName
          );
          if (matchingTimezone?.zone_name) {
            return {
              ...matchingTimezone,
              label: matchingTimezone?.zone_name,
              value: matchingTimezone?.id,
            };
          }
        })
        .filter(Boolean);
      setValue("timezones", timezoneOptions);
    }
  }, [Timezone]);
  useEffect(() => {
    fetchTimezone();
    if (id) {
      getCountry();
    }
  }, [id]);

  const getCountry = async () => {
    try {
      setPageLoading(true);
      await httpService.getCountry(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
        }
      });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setPrevTimeZone(JSON.parse(data.timezones));
    setValue("name", data.name);
    setValue("iso3", data.iso3);
    setValue("numeric_code", data.numeric_code);
    setValue("iso2", data.iso2);
    setValue("phonecode", data.phonecode);
    setValue("capital", data.capital);
    setValue("currency", data.currency);
    setValue("currency_name", data.currency_name);
    setValue("currency_symbol", data.currency_symbol);
    setValue("currency_format", numberType.find((n)=>n.value == data.currency_format));
    setValue("tld", data.tld);
    setValue("native", data.native);
    setValue("region", data.region);
    setValue("subregion", data.subregion);
    setValue("translations", data.translations);
    setValue("latitude", data.latitude);
    setValue("longitude", data.longitude);
    setValue("emoji", data.emoji);
    setValue("emojiU", data.emojiU);
    setValue("flag", data.flag == 1 ? true : false);
    setValue("wikiDataId", data.wikiDataId);
    setValue("vat_percentage", data.vat_percentage);
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const requestData = {
        name: data.name,
        iso3: data.iso3,
        numeric_code: data.numeric_code,
        iso2: data.iso2,
        phonecode: data.phonecode,
        capital: data.capital,
        currency: data.currency,
        currency_name: data.currency_name,
        currency_symbol: data.currency_symbol,
        currency_format: data.currency_format.value,
        tld: data.tld,
        native: data.native,
        region: data.region,
        subregion: data.subregion,
        translations: data.translations,
        latitude: data.latitude,
        longitude: data.longitude,
        emoji: data.emoji,
        emojiU: data.emojiU,
        flag: data.flag ? 1 : 0,
        wikiDataId: data.wikiDataId,
        vat_percentage: data.vat_percentage,
        timezones: data.timezones.map((timezone) => ({
          zoneName: timezone.zone_name,
          gmtOffset: timezone.gmt_offset,
          gmtOffsetName: timezone.gmt_offset_name,
          abbreviation: timezone.abbreviation,
          tzName: timezone.tz_name,
        }))
      };
      if (id) {
        requestData.id = id;
        await httpService
          .updateCountry(requestData,true)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                "Country updated successfully!"
              );
              setTimeout(() => {
                navigate(all_routes.CountryList);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createCountry(requestData);
      }
    } finally {
      setIsLoading(false);
      fetchCountries();
    }
  };

  // Create new country
  const createCountry = async (formData) => {
    await httpService
      .createCountry(formData,true)
      .then((response) => {
        if (response && response.status === 200) {
          notificationService.successMessage("Country created successfully!");
          navigate(all_routes.CountryList);
        }
      })
      .catch((error) => {
        if (error.validation_error.errors) {
          hydrateErrors(error.validation_error.errors, setError);
        }
      });
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("country.country"), link: "/country-list" },
    {
      label: id ? t("country.editCountry") : t("country.addNewCountry"),
      active: true,
    },
  ];
  const numberType = [
    {value:"US_STANDARD",label:"US STANDARD"},
    {value:"EUROPEAN",label:"EUROPEAN"},
    {value:"SWISS",label:"SWISS"},
    {value:"NORDIC",label:"NORDIC"}
  ]
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">
                {id ? t("country.editCountry") : t("country.addNewCountry")}
              </h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Country Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("country.countryName")}
                        id="name"
                        type="text"
                        placeholder={t("country.enterCountryName")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          // pattern: {
                          //   value: /^[0-9a-zA-Z,-\s]*$/,
                          //   message:
                          //     t("inputField.validation.onlyNumaricValues"),
                          // },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("country.iSO3")}
                        id="iso3"
                        type="text"
                        placeholder={t("inputField.placeholder.enterISO3")}
                        register={register}
                        rules={{
                          // required:  t('inputField.validation.required'),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.iso3}
                        errorMessage={errors.iso3?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.numericCode")}
                        id="numeric_code"
                        type="number"
                        placeholder={t(
                          "inputField.placeholder.enterNumericCode"
                        )}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          maxLength: {
                            value: 12,
                            message: t("inputField.validation.maxLength", {
                              value: 12,
                            }),
                          },
                        }}
                        error={errors.numeric_code}
                        errorMessage={errors.numeric_code?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("country.iSO2")}
                        id="iso2"
                        type="text"
                        placeholder={t("inputField.placeholder.enterISO2")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.iso2}
                        errorMessage={errors.iso2?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.phoneCode")}
                        id="phonecode"
                        type="text"
                        placeholder={t("inputField.placeholder.enterPhoneCode")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\+?[0-9]+$/,
                            message: t("inputField.validation.phoneCode"),
                          },
                          minLength: {
                            value: 1,
                            message: t("inputField.validation.minLength", {
                              value: 1,
                            }),
                          },
                          maxLength: {
                            value: 7,
                            message: t("inputField.validation.maxLength", {
                              value: 7,
                            }),
                          },
                        }}
                        error={errors.phonecode}
                        errorMessage={errors.phonecode?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.capital")}
                        id="capital"
                        type="text"
                        placeholder={t("inputField.placeholder.enterCapital")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.capital}
                        errorMessage={errors.capital?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.currencyShortCode")}
                        id="currency"
                        type="text"
                        placeholder={t("inputField.placeholder.enterCurrencyShortCode")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 5,
                            message: t("inputField.validation.maxLength", {
                              value: 5,
                            }),
                          },
                        }}
                        error={errors.currency}
                        errorMessage={errors.currency?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.currencyName")}
                        id="currency_name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterCurrencyName"
                        )}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.currency_name}
                        errorMessage={errors.currency_name?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.currencySymbol")}
                        id="currency_symbol"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterCurrencySymbol"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.currency_symbol}
                        errorMessage={errors.currency_symbol?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.currencyformat")}
                        id="currency_format"
                        errors={errors}
                        control={control}
                        register={register}
                        options={numberType}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t(
                          "inputField.placeholder.chooseCurrencyFormat"
                        )}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label="TID"
                        id="tld"
                        type="text"
                        placeholder={t("inputField.placeholder.enterTID")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                        }}
                        error={errors.tld}
                        errorMessage={errors.tld?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.native")}
                        id="native"
                        type="text"
                        placeholder={t("inputField.placeholder.enterNative")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.native}
                        errorMessage={errors.native?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.region")}
                        id="region"
                        type="text"
                        placeholder={t("inputField.placeholder.enterRegion")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.region}
                        errorMessage={errors.region?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.subRegion")}
                        id="subregion"
                        type="text"
                        placeholder={t("inputField.placeholder.enterSubRegion")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.subregion}
                        errorMessage={errors.subregion?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.longitude")}
                        id="longitude"
                        type="text"
                        placeholder={t("inputField.placeholder.enterLongitude")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value:
                              /^-?(90(\.0+)?|[0-9]|[1-8][0-9])(\.[0-9]+)?$/,
                            message: t(
                              "inputField.validation.invalidLongitude"
                            ),
                          },
                        }}
                        error={errors.longitude}
                        errorMessage={errors.longitude?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.latitude")}
                        id="latitude"
                        type="text"
                        placeholder={t("inputField.placeholder.enterLatitude")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value:
                              /^-?(90(\.0+)?|[0-9]|[1-8][0-9])(\.[0-9]+)?$/,
                            message: t("inputField.validation.invalidLatitude"),
                          },
                        }}
                        error={errors.latitude}
                        errorMessage={errors.latitude?.message}
                      />
                    </div>
                    <div className="col-md-12">
                      <UnlimitSelect
                        label={t("inputField.label.timeZone")}
                        id="timezones"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.select")}
                        options={Timezone}
                        isMulti={true}
                        optionLabel="zone_name"
                        optionValue="id"
                        error={errors.timezones}
                        errorMessage={errors.timezones?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.emoji")}
                        id="emoji"
                        type="text"
                        placeholder={t("inputField.placeholder.EnterEmoji")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.emoji}
                        errorMessage={errors.emoji?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.placeholder.emojiU")}
                        id="emojiU"
                        type="text"
                        placeholder={t("inputField.placeholder.EnteremojiU")}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.emojiU}
                        errorMessage={errors.emojiU?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.wikiDataId")}
                        id="wikiDataId"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterWikiDataId"
                        )}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.wikiDataId}
                        errorMessage={errors.wikiDataId?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.vatPercentage")}
                        id="vat_percentage"
                        type="number"
                        step="0.01"
                        placeholder={t(
                          "inputField.placeholder.enterVATPercentage"
                        )}
                        register={register}
                        rules={{
                          // required: t('inputField.validation.required'),
                          min: {
                            value: 0,
                            message: t("inputField.validation.minValue", {
                              min: 0,
                            }),
                          },
                          max: {
                            value: 100,
                            message: t("inputField.validation.maxValue", {
                              max: 100,
                            }),
                          },
                        }}
                        error={errors.vat_percentage}
                        errorMessage={errors.vat_percentage?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitToggle
                        label={t("tableColumnName.status")}
                        id="flag"
                        register={register}
                        column={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.CountryList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Country Form End */}
      </div>
    </div>
  );
};

export default CountryForm;
