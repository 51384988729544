import { useState } from "react";
import Rack from "./Rack";

const RackList = () => {
  const [racks, setRacks] = useState([{ id: 1 }]);

  const addRack = () => {
    setRacks([...racks, { id: racks.length + 1 }]);
  };

  const removeRack = (id) => {
    setRacks(racks.filter((rack) => rack.id !== id));
  };

  return (
    <div>
      {racks.map((rack, index) => (
        <Rack
          key={rack.id}
          id={rack.id}
          number={index + 1}
          removeRack={() => removeRack(rack.id)}
        />
      ))}
      <button onClick={addRack} className="btn btn-primary mt-3">
        Add Rack
      </button>
    </div>
  );
};

export default RackList;
