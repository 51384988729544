import { Link, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/price-line.service";
import { useEffect, useState } from "react";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import AddDocuments from "../../../components/add-documents";
import { useTranslation } from "react-i18next";
import moment from "moment";
import formatNumber from "../../../utils/numberFunction";
import { useSelectedCountry } from "../../../feature-module/dashboard/hooks/useSelectedCountry";

export default function PriceLineDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const selectedCountry = useSelectedCountry();
  const [priceLine, setPriceLine] = useState([]);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getPriceLine = async (pagination = paginationParams.pagination) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
    };
    try {
      setPageLoading(true);
      const response = await httpService.getPriceLineItems(data,id);
      if (response && response.status === 200) {
        setPriceLine(response.data.data.data);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPriceLine();
    }
  }, [id]);

  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getPriceLine(pagination)
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => (
        <Link
          to={record.catalog?.id ? `/catalog/${record.catalog?.id}/details`: '#'}
          className=" d-flex align-items-center edit-icon"
        >
          {record?.catalog?.ean}
        </Link>
      ),
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
          <Link
            to={record.catalog?.product?.id ? `/product/${record.catalog?.product?.id}/details`: '#'}
            className=" d-flex align-items-center edit-icon p-2"
          >
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record.catalog?.image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.catalog?.name}
        </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.unitSalePrice"),
      dataIndex: "sale_price",
      align: "right",
      render: (text, record) => formatNumber(text,selectedCountry,2),
    },
    {
      title: t("tableColumnName.lastCostPrice"),
      dataIndex: "unit_cost",
      align: "right",
      render: (text, record) => formatNumber(record?.purchase_item?.unit_cost,selectedCountry,2)
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "purchase_reference.id",
      align: "center",
      render: (outlet, record) =>
        record.purchase ? record?.purchase?.order_no : null,
      // sorter: true,
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.status"),
      dataIndex: "is_active",
      render: (text, record) => {
        if (record.is_active == 1) {
          return (
            <span className="badge badge-linesuccess">
              <a>{t("general.actions.activate")}</a>
            </span>
          );
        } else {
          return (
            <span className="badge badge-linedanger">
              <a>{t("general.actions.deactive")}</a>
            </span>
          );
        }
      },
    }
  ];


  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('priceLine.priceLine'), link: "/price-line-list" },
    { label: t('priceLine.priceLineListItems'), active: true },
  ];

  const onHideModal = async () => {
    console.log("hide modal");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t('priceLine.priceLineListItems')}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            priceLine && (
              <>
                <div className="">
                  {/* <div className="p-3 card">
                    <div className="row mb-md-3">
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.priceLineRefernce")}
                          details={priceLine.order_number}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("supplier.supplier")}
                          details={
                            <div className="d-flex align-items-center">
                              <ImageWithBasePath
                                type="link"
                                src={priceLine.supplier?.image_url}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              {priceLine?.supplier?.name}
                            </div>
                          }
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.vendor")}
                          details={
                            <div className="d-flex align-items-center">
                              <ImageWithBasePath
                                type="link"
                                src={priceLine.vendor?.logo_url}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              {priceLine?.vendor?.business_name}
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-md-3">
                      <div className="col-sm col-md-4">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.priceLineDate")}
                          details={moment(priceLine.priceLine_date).format(
                            "DD MMM YYYY"
                          )}
                        />
                      </div>
                      <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdAt")}
                                details={moment(priceLine.created_at).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdBy")}
                                details={priceLine.created_by?.name}
                              />
                            </div>
                    </div>
                  </div> */}
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={priceLine}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <AddDocuments onHide={onHideModal} />
      </div>
    </>
  );
}
