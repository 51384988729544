import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/purchases/purchase.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../components/add-documents";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import notificationService from "../../../services/toaster.service";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelectedCountry } from "../../../feature-module/dashboard/hooks/useSelectedCountry";
import formatNumber from "../../../utils/numberFunction";

export default function PurchaseDetails() {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCountry = useSelectedCountry();
  const { id } = useParams();
  const [purchase, setPurchase] = useState([]);
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });

  const getPurchase = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getPurchaseItems(id);
      if (response && response.status === 200) {
        setPurchase(response.data.data);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            total: response.data.data.length,
          },
        });
      }
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchase();
    }
  }, [id]);

  const handleTableChange = async (pagination, filters, sorter) => {
    updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.catalog?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.purchaseQuantity"),
      dataIndex: "initial_quantity",
      align: 'center',
      render:(text)=> formatNumber(text,selectedCountry,2)
    },
    {
      title: t('tableColumnName.unitCost'),
      dataIndex: "unit_cost",
      align: 'right',
      render:(text)=> formatNumber(text,selectedCountry,2)
    },
    // {
    //   title: t("tableColumnName.discount"),
    //   dataIndex: "discount",
    //   align: 'right',
    //   render:(text)=> formatNumber(text,selectedCountry,2)
    // },
    // {
    //   title: t("inputField.label.vat"),
    //   dataIndex: "vat",
    //   align: 'center',
    //   render:(text)=> formatNumber(text,selectedCountry,2)
    // },
    // {
    //   title: t("tableColumnName.unitSalePrice"),
    //   dataIndex: "salePrice",
    //   align: 'right',
    //   render:(text,record)=> formatNumber(record.sale_price,selectedCountry,2)
    // },
  ];

  // Paginate data client-side
  const paginate = (data, current, pageSize) => {
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data?.slice(startIndex, endIndex);
  };

  const paginatedData = paginate(
    purchase.items,
    paginationParams.pagination.current,
    paginationParams.pagination.pageSize
  );

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("purchase.purchases"), link: "/purchase-list" },
    { label: t("purchase.purchaseListItems"), active: true },
  ];

  const onHideModal = async () => {
    console.log("hide modal");
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("purchase.purchaseListItems")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            purchase && (
              <>
                <div className="">
                  <div className="p-3 card">
                    <div className="row mb-md-3">
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.purchaseRefernce")}
                          details={purchase.order_number}
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("supplier.supplier")}
                          details={
                            <div className="d-flex align-items-center">
                              <ImageWithBasePath
                                type="link"
                                src={purchase.supplier?.image}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              {purchase?.supplier?.name}
                            </div>
                          }
                        />
                      </div>
                      <div className="col-sm">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.vendor")}
                          details={
                            <div className="d-flex align-items-center">
                              <ImageWithBasePath
                                type="link"
                                src={purchase.vendor?.logo}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              {purchase?.vendor?.business_name}
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <div className="row mb-md-3">
                      <div className="col-sm col-md-4">
                        <UnlimitFieldDetails
                          heading={t("tableColumnName.purchaseDate")}
                          details={moment(purchase.purchase_date).format(
                            "DD MMM YYYY"
                          )}
                        />
                      </div>
                      <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdAt")}
                                details={moment(purchase.created_at).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )}
                              />
                            </div>
                            <div className="col-sm">
                              <UnlimitFieldDetails
                                heading={t("tableColumnName.createdBy")}
                                details={purchase.created_by}
                              />
                            </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <UnlimitTable
                      pagination={paginationParams.pagination}
                      columns={columns}
                      dataSource={paginatedData}
                      rowKey={(record) => record.id}
                      loading={loading}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <AddDocuments onHide={onHideModal} />
      </div>
    </>
  );
}
