import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/stores/outlet.service";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitFieldDetails from "../../../components/ui-kit/unlimit-field-details";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ConfirmationAlert from "../../../components/confirm-alert";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Upload } from "feather-icons-react/build/IconComponents";
import AddDocuments from "../../../components/add-documents";
import UnlimitHyperlink from "../../../components/ui-kit/unlimit-hyperlink";
import notificationService from "../../../services/toaster.service";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
import DocumentModal from "../../../components/view-document";
import ConfirmationAlertDeleteAttachments from "../../../components/confirm-alert-attachment-delete";
import ConfirmationAlertDeactivate from "../../../components/confirm-deactivate";
import { store } from "../../../core/redux/store";
import { useTranslation } from "react-i18next";

export default function OutletsDetails() {
  const { hasPermission, isVendorOrOutlet } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const [outlets, setOutlets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { auth } = store.getState();
  const roles = auth.roles ?? [];
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const navigate = useNavigate();
  const getOutlets = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getOutlet(id);
      if (response && response.status === 200) {
        setOutlets(response.data.data);
        setIsActive(response.data.data.is_active == 0 ? true : false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getOutlets();
    }
  }, [id]);

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("outlet.outlets"), link: "/outlets-list" },
    { label: t("outlet.outletDetails"), active: true },
  ];
  const handleTableChange = async (pagination, filters, sorter) => {
    await updatePaginationParams({
      pagination,
      filters,
      sorter,
    });
  };
  const onConfirmDelete = async (id) => {
    await httpService
      .deleteOutlet(id)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: t("general.prompts.deleted"),
            text: t("outlet.outletDeleteSuccess"),
            className: "btn btn-success",
            confirmButtonText: t("general.button.ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          navigate(`/outlets-list`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateOutlet = async () => {
    const requestData = {
      is_active: isActive ? 1 : 0,
      id: id,
    };
    await httpService
      .updateOutlet(requestData, true)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(t("outlet.outletUpdateSuccess"));
          setIsActive(!isActive);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const documentColumns = [
    {
      title: "Sr. No",
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.documentName"),
      dataIndex: "name",
    },
    {
      title: t("tableColumnName.fileType"),
      dataIndex: "type",
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return moment(text).format("DD MMM YYYY - HH:mm:ss");
      },
    },
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a className="p-2" onClick={() => handleViewDocument(record)}>
              <i className="feather-eye"></i>
            </a>
            <ConfirmationAlertDeleteAttachments
              id={record.id}
              get={getOutlets}
            />
          </div>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (paginationParams?.pagination?.current - 1) *
          paginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("inputField.label.EAN"),
      dataIndex: "purchase_reference.id",
      render: (outlet, record) =>
        record.catalog ? record?.catalog?.ean : null,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt={t("tableColumnName.productName")}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record?.catalog?.name}
        </div>
      ),
    },
    {
      title: t("tableColumnName.quantity"),
      dataIndex: "quantity",
      align: "center",
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "purchase_reference.id",
      render: (outlet, record) =>
        record.purchase ? record?.purchase?.order_no : null,
    },
    {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    },
  ];

  const handleViewDocument = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
  };
  const onHideModal = async () => {
    getOutlets();
  };
  const createDocument = async (formData) => {
    const response = await httpService.createOutletDocument(formData);
    if (response && response.status === 200) {
      notificationService.successMessage(t("document.documentUploadSuccess"));
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("outlet.outlet")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            outlets && (
              <>
                <div className="row">
                  <div className="col-md-4">
                    <div className="border card p-3 mb-3">
                      <div className="d-flex justify-content-between flex-column align-items-center mb-2">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <ImageWithBasePath
                            src={outlets?.vendor?.logo}
                            height={60}
                            width={60}
                            type="link"
                          ></ImageWithBasePath>
                          <span
                            className={`badge ${
                              !isActive ? "badge-linesuccess" : "badge-danger"
                            }`}
                          >
                            <a>
                              {!isActive
                                ? t("general.actions.active")
                                : t("general.actions.deactive")}
                            </a>
                          </span>
                        </div>
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center h-75 mt-4">
                          <ImageWithBasePath
                            src={outlets?.image}
                            height={120}
                            width={120}
                            type="link"
                          ></ImageWithBasePath>
                          <div className="mt-4 text-center">
                            <div className="text-black text-center fs-6">
                              <strong>{outlets?.name}</strong>
                            </div>
                            <div className="text-center">
                              Owner: {outlets.owner_name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex w-100 mt-4 gap-3">
                        {roles[0].permissions.some(
                          (permission) => permission.name === "outlet-edit"
                        ) && (
                          <UnlimitHyperlink
                            to={`/outlets/${id}/edit`}
                            className="btn btn-primary"
                            type="router-link"
                            style={{ flex: 1 }}
                          >
                            {t("general.actions.edit")}
                          </UnlimitHyperlink>
                        )}
                        {roles[0].permissions.some(
                          (permission) => permission.name === "outlet-edit"
                        ) && (
                          <ConfirmationAlertDeactivate
                            onConfirm={() => updateOutlet()}
                            isActive={isActive}
                          />
                        )}
                      </div>
                    </div>
                        {(hasPermission(["outlet-view"]) && outlets.nvr_url) && (
                    <div className="border card p-2">
                      <div className="d-flex justify-content-center">
                          <a href={outlets.nvr_url} target="blank">
                            <ImageWithBasePath
                              src="assets/img/icons/cctv-camera-icon.png"
                              alt="img"
                              height={"30px"}
                            />
                          </a>
                        <div className="ms-3">
                          <p className="mb-0 w-auto">
                            <strong>UserName:</strong> {outlets.nvr_username}
                          </p>
                          <p className="mb-0 w-auto">
                            <strong>Password:</strong> {outlets.nvr_password}
                          </p>
                        </div>
                      </div>
                    </div>
                        )}
                  </div>
                  <div className="col-md-8">
                    <div className="p-3 card">
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.contactName")}
                            details={outlets.contact_name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.phone")}
                            details={outlets.phone}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.email")}
                            details={
                              <span
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}
                              >
                                {outlets.email}
                              </span>
                            }
                          />
                        </div>
                      </div>

                      <div className="row mb-md-3">
                        <div className="col-sm-8">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.address")}
                            details={outlets.address}
                          />
                        </div>
                        <div className="col-sm-4">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.location")}
                            details={
                              <a
                                href={`https://www.google.com/maps?q=${outlets.latitude},${outlets.longitude}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Open Maps"
                              >
                                <i className="feather-map-pin text-primary"></i>
                              </a>
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.zipCode")}
                            details={outlets.zip_code}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.city")}
                            details={outlets.city?.name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.country")}
                            details={outlets.country?.name}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.currency")}
                            details={outlets.country?.currency}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.systemLanguage")}
                            details={outlets.language?.name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.timeZone")}
                            details={outlets?.timezone}
                          />
                        </div>
                      </div>

                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.installationID")}
                            details={outlets?.installation_id}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.baseUrl")}
                            details={outlets?.base_url}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.authorizationToken")}
                            details={
                              <span
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                }}
                              >
                                {outlets?.authorization_token}
                              </span>
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.nSiteID")}
                            details={outlets?.n_site_id}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.nControllerID")}
                            details={outlets.n_controller_id}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.nControlType")}
                            details={outlets?.n_control_type}
                          />
                        </div>
                      </div>
                      <div className="row mb-md-3">
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("inputField.label.nControlIndex")}
                            details={outlets?.n_control_index}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.createdBy")}
                            details={outlets.created_by?.name}
                          />
                        </div>
                        <div className="col-sm">
                          <UnlimitFieldDetails
                            heading={t("tableColumnName.createdAt")}
                            details={moment(outlets.created_at).format(
                              "DD MMM YYYY - HH:mm:ss"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="fw-bold">{t("outlet.outletStock")}</h3>
          </div>
          <div className="card">
            <div className="table-responsive">
              <UnlimitTable
                pagination={paginationParams.pagination}
                columns={columns}
                dataSource={outlets?.distributions}
                rowKey={(record) => record.id}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="fw-bold">{t("document.documents")}</h4>
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add-department"
              className="btn btn-added"
            >
              <Upload className="me-2 iconsize" />
              {t("general.actions.upload")}
            </Link>
          </div>
          <div className="card">
            <div className="table-responsive">
              <UnlimitTable
                pagination={false}
                columns={documentColumns}
                dataSource={outlets?.attachments}
                rowKey={(record) => record.id}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
          <AddDocuments
            onHide={onHideModal}
            recordId={outlets?.id}
            createDocument={createDocument}
          />
          <DocumentModal
            show={showModal}
            onHide={() => setShowModal(false)}
            document={selectedDocument}
          />
          <AddDocuments onHide={onHideModal} />
        </div>
      </div>
      {/* Documents  */}
    </>
  );
}
