import { useEffect } from 'react';
import { messaging } from './firebaseConfig.js';
import { getToken, onMessage } from 'firebase/messaging';
import httpService from "../services/http/user-managment/user.service.js";
import { useDispatch, useSelector } from 'react-redux';
import { updateFCMToken } from '../core/redux/reducers/auth.slice.js';
function NotificationRequest(handleNotificationMessage) {
  const storedToken = useSelector((state) => state?.auth?.user?.fcm_id); 
  const dispatch = useDispatch();
  useEffect(() => {
    // Request permission to send notifications
    const requestPermission = async () => {
      try {
        const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_ID });
        if (token) {
          console.log('Notification permission granted:');
          if (token !== storedToken) {
            console.log('FCM token has changed, updating backend and Redux...');

            // Update the token in the backend
            httpService.updateToken({ fcm_id: token })
              .then((response) => {
                if (response && response.status === 200) {
                  console.log("Successfully updated token for user");

                  // Update the token in Redux
                  dispatch(updateFCMToken(token));
                }
              })
              .catch((error) => {
                console.error("Failed to update token for user", error);
              });
          } else {
            console.log("FCM token is the same, no update required.");
          }
          onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            handleNotificationMessage(payload.notification ? payload.notification : payload.data)
          });
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      } catch (error) {
        console.log('Error getting permission or token:', error);
      }
    };

    requestPermission();
  }, []);

  return <div>Firebase Notification Setup</div>;
}

export default NotificationRequest;
