import { useState } from "react";
import Shelf from "./Shelf";

const Rack = ({ id, number, removeRack }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shelves, setShelves] = useState([{ id: 1 }]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const addShelf = () => {
    setShelves([...shelves, { id: shelves.length + 1 }]);
  };

  const removeShelf = (shelfId) => {
    setShelves(shelves.filter((shelf) => shelf.id !== shelfId));
  };
  return (
    <div className="d-flex  flex-column align-items-center mb-3">
    <div className="d-flex align-items-center w-100">
      <div className="py-2 px-3 d-flex justify-content-between align-items-center rack-container w-100">
        <div className="d-flex align-items-center justify-content-center w-100">
          <h6 className="me-3 mb-0">Rack {number}:</h6>
          <span className="badge badge-linedangershelf shelf me-2">Shelf: {shelves.length}</span>
          <span className="badge badge-linedangershelf sensor me-2">Sensor: 15</span>
          <span className="badge badge-linedangershelf product me-2">Product: 20</span>
        </div>
        <div onClick={toggleDropdown} style={{ cursor: "pointer" }}>
          <i className={`feather-chevron-${isOpen ? "up" : "down"} h3`}></i>
        </div>
      </div>
      <i className="feather-x h3 mx-3" onClick={removeRack} style={{ cursor: "pointer" }}></i>
      </div>

      {isOpen && (
       <div className="rack-details w-100 p-3">
       <h5 className="mb-2">Shelves</h5>
       {shelves.map((shelf, index) => (
         <Shelf key={shelf.id} number={index + 1} removeShelf={() => removeShelf(shelf.id)} />
       ))}
       <button onClick={addShelf} className="btn btn-primary mt-2">
         Add Shelf
       </button>
     </div>
      )}
    </div>
  );
};

export default Rack;
