import React, { useEffect, useState } from "react";
import Select from "react-select";
import outletService from "../../services/http/stores/outlet.service";
import { all_routes } from "../../Router/all_routes";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../utils/permissionFunction";
import { useSelector } from "react-redux";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import useDashboardData from "./hooks/userDashboardData";
import SalesChart from "./features/dashboardChart";
import ProductsList from "./features/productsList";
import OutletList from "./features/outletList";
import UnlimitBreadcrumb from "../../components/ui-kit/unlimit-breadcrumb";
import formatNumber from "../../utils/numberFunction";
import { useSelectedCountry } from "./hooks/useSelectedCountry";
import DashboardCards from "./features/DashboardCard";
const Dashboard = () => {
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("year"),
    end: moment(),
  });
  const { isVendorOrOutlet, isOutletRole, isVendorRole, hasPermission } =
    useRoleAndPerm();
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const selectedCountry = useSelectedCountry();
  const [outletLoading, setOutletLoading] = useState(false);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const [selectedYear, setSelectedYear] = useState(2024);
  const vendors = useSelector((state) => state.vendors.vendors);
  const auth = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const { dashboardData, loading, error } = useDashboardData(
    dateRange.start.format("YYYY-MM-DD"),
    dateRange.end.format("YYYY-MM-DD"),
    selectedVendor?.value,
    selectedOutletId?.value,
    selectedYear
  );

  useEffect(() => {
    setVendor(vendors?.data?.data);
  }, []);
  useEffect(() => {
    if (!isOutletRole) {
      setSelectedOutletId(null);
      if (selectedVendor?.value) {
        setOutletLoading(true);
        fetchOutlet(selectedVendor?.value);
      } else if (auth?.user?.vendor && auth?.user?.vendor?.id) {
        setOutletLoading(true);
        fetchOutlet(auth?.user?.vendor?.id);
      }
    }
  }, [selectedVendor, auth]);
  const handleDateRangeChange = (event, picker) => {
    setDateRange({
      start: picker.startDate,
      end: picker.endDate,
    });
  };
  async function fetchOutlet(vendorID) {
    try {
      const outlet = await outletService.outletList({
        vendor_id: vendorID,
      });
      setOutlet(outlet?.data?.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setOutletLoading(false);
    }
  }
  const outletOptions = [
    { value: null, label: t("outlet.allOutlet") },
    ...(outlet
      ? outlet.map((outlet) => ({
          ...outlet,
          value: outlet.id,
          label: outlet.name,
        }))
      : []),
  ];

  const vendorOptions = [
    { value: null, label: t("vendor.allVendor") },
    ...(vendor
      ? vendor.map((vendor) => ({
          ...vendor,
          value: vendor.id,
          label: vendor.business_name,
        }))
      : []),
  ];
  const handleYearChange = (year) => {
    setSelectedYear(year);
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("tableColumnName.dashboard"), active: true },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header mb-0">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h4>{t("tableColumnName.dashboard")}</h4>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row mb-3 align-items-end">
            <div className="d-flex flex-wrap align-items-end w-100">
              {!isVendorOrOutlet && (
                <div className="col-lg-5 col-sm-4 pe-2">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("vendor.vendor")}</label>
                    <Select
                      options={vendorOptions}
                      className="select custom-select"
                      placeholder={t("vendor.allVendor")}
                      value={selectedVendor}
                      formatOptionLabel={({ label, logo_url }) => (
                        <div className="d-flex">
                          {logo_url && (
                            <img
                              src={logo_url}
                              alt=""
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                objectFit: "contain",
                              }}
                            />
                          )}
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      onChange={(selectedOption) =>
                        setSelectedVendor(selectedOption)
                      }
                    />
                  </div>
                </div>
              )}
              {!isOutletRole && (
                <div className="col-lg-4 col-sm-4">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("outlet.outlet")}</label>
                    <Select
                      options={outletOptions}
                      className="select custom-select"
                      placeholder={t("outlet.allOutlet")}
                      value={selectedOutletId}
                      onChange={(selectedOption) =>
                        setSelectedOutletId(selectedOption)
                      }
                      formatOptionLabel={({ label, image }) => (
                        <div className="d-flex">
                          {image && (
                            <img
                              src={image}
                              alt=""
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                objectFit: "contain",
                              }}
                            />
                          )}
                          <span style={{ marginLeft: 10 }}>{label}</span>
                        </div>
                      )}
                      isLoading={outletLoading}
                    />
                  </div>
                </div>
              )}
              <div className="col-lg-3 col-sm-4 ms-auto d-flex justify-content-end mb-0">
                <DateRangePicker
                  initialSettings={{
                    startDate: dateRange.start,
                    endDate: dateRange.end,
                  }}
                  onApply={handleDateRangeChange}
                >
                  <span className="card d-flex flex-row mb-0">
                    <span className="fw-bold mx-2 p-2">
                      {new Date(dateRange.start).toLocaleDateString() +
                        " - " +
                        new Date(dateRange.end).toLocaleDateString()}
                    </span>
                    <button className="btn btn-primary">
                      <i className="feather-filter"></i>
                    </button>
                  </span>
                </DateRangePicker>
              </div>
            </div>
          </div>

          <DashboardCards
            dashboardData={dashboardData}
            selectedCountry={selectedCountry}
            t={t}
            hasPermission={hasPermission}
            all_routes={all_routes}
          />
          {/* Button trigger modal */}

          <div className="row">
            <div className="col-xl-12 col-sm-12 col-12 d-flex">
              <SalesChart
                selectedYear={selectedYear}
                handleYearChange={handleYearChange}
                dashboardData={dashboardData}
                t={t}
              />
            </div>
            <ProductsList
              t={t}
              selectedCountry={selectedCountry}
              ProductsList={dashboardData.topProductsData}
            />
            {/* <ExpiredProductList t={t} /> */}
            <OutletList
              t={t}
              OutletsList={dashboardData.topOutletsData}
              selectedCountry={selectedCountry}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
