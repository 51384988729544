import React, { useEffect, useRef, useState } from "react";
import UnlimitInput from "./ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import UnlimitToggle from "./ui-kit/unlimit-toggle";
import UnlimitSubmitButton from "./ui-kit/unlimit-submit-button";
import httpService from "../services/http/loyalty/loyalty-sttings.service";
import notificationService from "../services/toaster.service";
import { store } from "../core/redux/store";
import { hydrateErrors } from "../utils/error.utils";
import { useTranslation } from "react-i18next";
import UnlimitSelect from "./ui-kit/unlimit-select";
import { useSelector } from "react-redux";
import useRoleAndPerm from "../utils/permissionFunction";

const LoyaltySettingsDetail = ({ onHide, loyaltySetting }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError,
    control,
    reset,
    watch
  } = useForm();
  const { errors } = formState;
  const vendors = useSelector((state) => state.vendors.vendors);
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState([]);
  const { isOutletRole,isVendorRole,isVendorOrOutlet } = useRoleAndPerm();
  const auth = useSelector((state) => state.auth);
  const modalRef = useRef();
  const onSubmitForm = async (data) => {
    const user = auth.user;
    try {
      setIsLoading(true);
      const requestData = {
        amount: data.amount,
        points: data.points,
        created_by: user.id,
        type: data.type.value,
      };
      if (data.vendor_id?.id) {
        requestData.vendor_id = data.vendor_id?.id;
    }
      if (loyaltySetting) {
        requestData.id = loyaltySetting.id;
        await updateLoyaltySetting(requestData);
      } else {
        await createLoyaltySetting(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const createLoyaltySetting = async (formData) => {
    try {
      const response = await httpService.createLoyaltySettings(formData, true);
      if (response && response.status === 200) {
        notificationService.successMessage(t("loyalty.loyaltyCreateSuccess"));
        onHide();
        reset();
        modalHide();
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const updateLoyaltySetting = async (formData) => {
    try {
      const response = await httpService.updateLoyaltySettings(formData, true);
      if (response && response.status === 200) {
        notificationService.successMessage(t("loyalty.loyaltyUpdateSuccess"));
        onHide();
        reset();
        modalHide();
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const modalHide = () => {
    const cancelButton = document.querySelector(".btn-cancel");
    if (cancelButton) {
      cancelButton.click();
    }
    reset()
  };
  useEffect(() => {
    setVendor(vendors?.data?.data);
    if (loyaltySetting) {
      setValue("amount", loyaltySetting.amount);
      setValue("points", loyaltySetting.points);
      setValue("type",typeOption.find((type)=>type.value == loyaltySetting.type));
      if (loyaltySetting.vendor)
        setValue("vendor_id", {
          ...loyaltySetting.vendor,
          label: loyaltySetting.vendor.business_name,
          value: loyaltySetting.vendor.id,
          logo: loyaltySetting.vendor.logo_url,
        });
    }
  }, [loyaltySetting]);
  useEffect(()=>{
    if (isVendorRole) {
      setValue('vendor_id',{id:auth?.user?.vendor?.id})
    }
  },[auth])
  useEffect(() => {
    const modalElement = modalRef.current;
    const handleShow = () => {
      console.log("Handle show");
    };
    const handleHide = () => {
      reset();
      onHide();
    };
    modalElement.addEventListener("show.bs.modal", handleShow);
    modalElement.addEventListener("hide.bs.modal", handleHide);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShow);
      modalElement.removeEventListener("hide.bs.modal", handleHide);
    };
  }, [onHide]);
  const typeOption = [
    {label:t('tableColumnName.earnPoints'),value:"EARN" },
    {label:t('tableColumnName.redeemPoints'),value:"REDEEM" }
  ]
  return (
    <div>
      <div className="modal fade" id="add-department" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {t("tableColumnName.loyaltyPointsSettings")}
                    </h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmitForm)}
                  >
                    <div className="row">
                      <div className="col-lg-12 mb-2">
                        <UnlimitSelect
                          label={t("logs.type")}
                          id="type"
                          errors={errors}
                          control={control}
                          register={register}
                          placeholder={t("loyalty.chooseLoyaltyType")}
                          options={typeOption}
                        />
                      </div>
                     {!isVendorRole &&  <div className="col-lg-12 mb-2">
                        <UnlimitSelect
                          label={t("vendor.vendor")}
                          id="vendor_id"
                          errors={errors}
                          control={control}
                          register={register}
                          placeholder={t("inputField.placeholder.chooseVendor")}
                          options={vendor}
                          showLogo={true}
                          optionLabel="business_name"
                          optionValue="id"
                        />
                      </div>}
                      <div className="col-md-12">
                        <UnlimitInput
                          label={t("tableColumnName.Amount")}
                          id="amount"
                          type="number"
                          placeholder={t("inputField.placeholder.enterAmount")}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            maxLength: {
                              value: 8,
                              message: t("inputField.validation.maxLength", {
                                value: 8,
                              }),
                            },
                            min: {
                              value: 0,
                              message: t("inputField.validation.minValue", {
                                min: 0,
                              }),
                            },
                          }}
                          error={errors.amount}
                          step="0.01"
                          errorMessage={errors.amount?.message}
                        />
                      </div>
                      <div className="col-lg-12">
                        <UnlimitInput
                          label={t("loyalty.points")}
                          id="points"
                          type="number"
                          placeholder={t("inputField.placeholder.enterPoints")}
                          register={register}
                          rules={{
                            required: t("inputField.validation.required"),
                            maxLength: {
                              value: 8,
                              message: t("inputField.validation.maxLength", {
                                value: 8,
                              }),
                            },
                            min: {
                              value: 0,
                              message: t("inputField.validation.minValue", {
                                min: 0,
                              }),
                            },
                          }}
                          error={errors.points}
                          errorMessage={errors.points?.message}
                        />
                      </div>
                      {/* <div className="input-blocks m-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <UnlimitToggle
                            label={t("inputField.label.status")}
                            id="is_active"
                            register={register}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        {t("general.button.cancel")}
                      </button>
                      <UnlimitSubmitButton
                        label={
                          loyaltySetting
                            ? t("general.button.update")
                            : t("general.button.submit")
                        }
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltySettingsDetail;
