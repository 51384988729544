// authSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  accessToken: null,
  authenticated: false,
  profile: null,
  roles: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthProfile: (state, action) => {
      state.profile = action.payload.profile;
    },
    authSuccess: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.authenticated = true;
      state.roles = action.payload.user.roles;
    },
    updateFCMToken: (state, action) => {
      if (state.user) {
        state.user.fcm_id = action.payload;
      }
    },
    logout: (state) => {
      state.accessToken = initialState.accessToken;
      state.user = initialState.user;
      state.authenticated = initialState.authenticated;
    },
  },
});

export const { authSuccess, logout,updateFCMToken, setAuthProfile } = authSlice.actions;

export default authSlice.reducer;
