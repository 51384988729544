import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/campaign.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { store } from "../../../core/redux/store";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { useSelector } from "react-redux";
import productGroupHttpService from "../../../services/http/products/product-group.service";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useTranslation } from "react-i18next";
import {
  convertToISOString,
  UTCToLocalTimeDate,
} from "../../../utils/dateFunctions";
import useRoleAndPerm from "../../../utils/permissionFunction";

const CampaignForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { isOutletRole,isVendorRole,isVendorOrOutlet } = useRoleAndPerm();
  const auth = useSelector((state) => state.auth);
  const [productGroup, setProductGroup] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedVendorImg, setSelectedVendorImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [productGroupLoading, setProductGroupLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);

  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    if (id) {
      await getCampaign();
    }
  };
  useEffect(()=>{
    if (isVendorRole) {
      setValue('vendor_id',{id:auth?.user?.vendor?.id})
      fetchProductGroup(auth?.user?.vendor?.id)
    }
  },[auth])
  useEffect(() => {
    fetchPredefinedValues();
    setVendor(vendors?.data?.data);
  
  }, [id]);
  async function fetchProductGroup(vendorId) {
    setProductGroupLoading(true);
    try {
      const productGroup = await productGroupHttpService.productGroupList({vendor_id:vendorId,is_active:1});
      setProductGroup(productGroup?.data?.data?.data);
    } catch (error) {
      console.error("Error Product Group data:", error);
    } finally {
      setProductGroupLoading(false);
    }
  }
  // Fetch campaign data by ID
  const getCampaign = async () => {
    try {
      setPageLoading(true);
      await httpService
        .getCampaign(id)
        .then(async (response) => {
          if (response && response.status === 200) {
            const data = response.data.data;
            hydratePredefinedValues(data);
          }
        })
        .catch(() => {
          console.log("error fetching campaign");
        });
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    // setValue("image", data.image);
    setValue("is_active", data.is_active == 1 ? true : false);
    setValue("product_group_id", {
      ...data.product_group,
      label: data.product_group?.name,
      value: data.product_group?.id,
    });
    const startDateLocal = UTCToLocalTimeDate(data.start_date);
    const endDateLocal = UTCToLocalTimeDate(data.end_date);
    setValue("start_date", startDateLocal);
    setValue("end_date", endDateLocal);
    setValue("discount_amount_type", {
      id: data.discount_amount_type,
      value: data.discount_amount_type,
      label: discountAmountTypeOptions.find(
        (option) => option.id === data.discount_amount_type
      ).name,
    });
    setValue("discount_amount_type_value", data.discount_amount_type_value);
    setValue("vendor_id", {
      ...data.vendor,
      label: data.vendor?.business_name,
      value: data.vendor?.id,
      logo: data.vendor?.logo,
    });
    if(data.vendor?.id) fetchProductGroup(data.vendor?.id)
    setExistingImageUrl(data.image);
  };
  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      if (data.image[0]) {
        formData.append("image", data.image[0]);
      }
      formData.append("vendor_id", data.vendor_id?.id);
      formData.append("product_group_id", data.product_group_id?.id);
      const startDateUTC = new Date(data.start_date).toISOString();
      const endDateUTC = new Date(data.end_date).toISOString();
      formData.append("start_date", startDateUTC);
      formData.append("end_date", endDateUTC);
      formData.append("discount_amount_type", data.discount_amount_type?.id);
      formData.append("is_active", data.is_active ? 1 : 0);
      formData.append(
        "discount_amount_type_value",
        data.discount_amount_type_value
      );
      if (id) {
        formData.append("id", id);
        await httpService
          .updateCampaign(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t("campaign.campaignUpdateSuccess")
              );
              setTimeout(() => {
                navigate(all_routes.campaignList);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createCampaign(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new campaign
  const createCampaign = async (formData) => {
    try {
      await httpService
        .createCampaign(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(
              t("campaign.campaignCreateSuccess")
            );
            navigate(all_routes.campaignList);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  const onChangeVendor = (value) => {
    fetchProductGroup(value.id)
    if (value) setSelectedVendorImg(value.logo);
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("campaign.campaign"), link: "/campaign-list" },
    {
      label: id ? t("campaign.editCampaign") : t("campaign.addCampaign"),
      active: true,
    },
  ];
  const discountAmountTypeOptions = [
    { id: "percentage", name: "Percentage (%)" },
    { id: "amount", name: "Amount" },
  ];
  const start_date = watch("start_date");
  const today = new Date().toISOString().split("T")[0];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">
                {id ? t("campaign.editCampaign") : t("campaign.addCampaign")}
              </h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* Campaign Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          {!isVendorRole && <div className="card">
            <div className="card-body pb-0 mb-3 row align-items-center justify-content-between">
              <div className="col-md-4">
                <UnlimitSelect
                  label={t("vendor.vendor")}
                  id="vendor_id"
                  errors={errors}
                  control={control}
                  register={register}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t("inputField.placeholder.chooseVendor")}
                  options={vendor}
                  showLogo={true}
                  optionLabel="business_name"
                  onChange={onChangeVendor}
                  optionValue="id"
                />
              </div>
              <div className="col-md-4 text-end">
                {selectedVendorImg && (
                  <img
                    src={selectedVendorImg}
                    className="img-fluid"
                    style={{ maxWidth: "70px" }}
                  />
                )}
              </div>
            </div>
          </div>}
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("tableColumnName.campaignName")}
                        id="name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterCampaignName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name}
                        errorMessage={errors.name?.message}
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-start">
                      <UnlimitFileUpload
                        label={t("inputField.label.image")}
                        id="image"
                        accept=".jpeg, .png, .jpg"
                        register={register}
                        error={errors.image}
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        errorMessage={errors.image?.message}
                        onChange={handleImageChange}
                      />
                      {(id || imageUrl) && (
                        <img
                          src={
                            id
                              ? id && imageUrl
                                ? imageUrl
                                : existingImageUrl
                              : imageUrl
                          }
                          alt="Campaign Image"
                          className="img-fluid ms-2"
                          style={{ maxWidth: "70px",maxHeight:"70px" }}
                        />
                      )}{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("productGroup.productGroup")}
                        id="product_group_id"
                        errors={errors}
                        control={control}
                        isLoading={productGroupLoading}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectProduct")}
                        options={productGroup}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("tableColumnName.startDate")}
                        id="start_date"
                        type="datetime-local"
                        placeholder={t(
                          "inputField.placeholder.selectStartDate"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        min={today}
                        error={errors.start_date}
                        errorMessage={errors.start_date?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("tableColumnName.endDate")}
                        id="end_date"
                        type="datetime-local"
                        placeholder={t("inputField.placeholder.selectEndDate")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        min={start_date || today}
                        error={errors.end_date}
                        errorMessage={errors.end_date?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitSelect
                        label={t("inputField.label.discountType")}
                        id="discount_amount_type"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.select")}
                        options={discountAmountTypeOptions}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.discountValue")}
                        id="discount_amount_type_value"
                        type="number"
                        placeholder={t("inputField.placeholder.enterValue")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                          min: {
                            value: 0,
                            message: t("inputField.validation.minValue", {
                              min: 0,
                            }),
                          },
                          max:
                            watch("discount_amount_type")?.value == "percentage"
                              ? {
                                  value: 100,
                                  message: t("inputField.validation.maxValue", {
                                    max: 100,
                                  }),
                                }
                              : undefined,
                        }}
                        step="0.01"
                        error={errors.discount_amount_type_value}
                        errorMessage={
                          errors.discount_amount_type_value?.message
                        }
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-center pt-4">
                      <UnlimitToggle
                        label={t("inputField.label.status")}
                        id="is_active"
                        register={register}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.campaignList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* Campaign Form End */}
      </div>
    </div>
  );
};

export default CampaignForm;
