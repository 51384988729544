import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import formatNumber from "../../../utils/numberFunction";

const OutletList = ({ t, OutletsList,selectedCountry }) => {
  const route = all_routes;
  return (
    <div className="col-xl-6 col-sm-12 col-12 d-flex">
      <div className="card flex-fill default-cover mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h4 className="card-title mb-0">{t("dashboard.topOutlets")}</h4>
          <div className="view-all-link">
            <Link to="#" className="view-all d-flex align-items-center">
              <i className="bx bx-dots-vertical-rounded fs-4" />
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive dataview">
            <table className="table dashboard-recent-products">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("outlet.outlets")}</th>
                  <th>{t("dashboard.salesAmount")}</th>
                </tr>
              </thead>
              <tbody>
                {OutletsList && OutletsList.map((outlet, index) => (
                  <tr key={outlet.outlet_id}>
                    <td>{index + 1}</td>
                    <td className="productimgname">
                      <Link to={`/outlets/${outlet.outlet_id}/details`} className="product-img">
                        <ImageWithBasePath
                          src={outlet.outlet_image}
                          alt={outlet.outlet_name}
                          type='link'
                        />
                      </Link>
                      <Link to={`/outlet/${outlet.outlet_id}/details`}>
                        {outlet.outlet_name}
                      </Link>
                    </td>
                    <td>{selectedCountry?.currency + " " + formatNumber(outlet.total_sales,selectedCountry,2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutletList;
