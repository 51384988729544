import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/user-managment/role.service";
import permissionService from "../../../services/http/user-managment/permission.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import { Checkbox } from "antd";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import { store } from "../../../core/redux/store";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";

const RoleForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [schema, setSchema] = useState([]);
  const { auth } = store.getState();
  const { id } = useParams();
  const roles = auth.roles ?? [];
  const [selectedSchema, setSelectedSchema] = useState();
  const [schemaOptions, setSchemaOptions] = useState([]);
  const [showFullForm, setShowFullForm] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const { register, handleSubmit, formState, setValue, setError } = useForm();
  const [permissions, setPermissions] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const { errors } = formState;
  const { t } = useTranslation();
  const getRole = async () => {
    await httpService
      .getRole(id)
      .then((response) => {
        if (response && response.status == 200) {
          const data = response.data.data;
          setValue("name", data.name);
          setValue("is_active", data.is_active == 1 ? true : false);
          setShowFullForm(true);
          setSelectedModules(data.permissions?.map((item) => item.id) ?? []);
          setSelectedSchema(data.role_type)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(()=>{
getPermissions()
  },[selectedSchema])
  const getPermissions = async (selectedOption) => {
    if(!selectedOption && !selectedSchema) return
    try {
      const data = { page: 1, per_page: 5000,scema:selectedOption || selectedSchema }
      const response = await permissionService.permissionList(data);
      if (response.status == 200) {
        hydratePermissions(response.data.data);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
  const hydratePermissions = (data) => {
    const permissions = Object.entries(data).map(
      ([moduleName, modulePermissions]) => {
        const moduleNameLower = moduleName.toLowerCase();
        const findPermissionId = (action) =>
          modulePermissions.find((item) => item.name.includes(action))?.id ??
          -1;
        const viewPermissionId = findPermissionId("view");
        const addPermissionId = findPermissionId("add");
        const deletePermissionId = findPermissionId("delete");
        const editPermissionId = findPermissionId("edit");

        return {
          module: moduleName,
          ids: [
            addPermissionId,
            editPermissionId,
            deletePermissionId,
            viewPermissionId,
          ],
        };
      },
    );
    setPermissions(permissions);
  };
  const getPageData = async () => {
    try {
      setPageLoading(true);
      if (id) {
         await getRole();
      }
      await getPermissions();
    } finally {
      setPageLoading(false);
    }
  };
  useEffect(() => {
    setInitialSchema()
    getPageData();
  }, [id]);
  const setInitialSchema = () => {
    const roleType = roles[0].role_type;
    const perms = ["SAAdmin", "UnlimitAdmin", "Vendor", "Outlet"];
  
    const roleIndex = perms.indexOf(roleType);
      const schema = perms.slice(roleIndex);
      setSchema(schema);
  };
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      selectedModules.forEach((mod, index) => {
        formData.append(`permissions[${index}]`, mod);
      });
      formData.append("guard_name", "web");
      formData.append("scema", selectedSchema);
      formData.append("is_active", data.is_active == true ? 1 : 0);
      if (id) {
        formData.append("id", id);
        await updateRole(formData);
      } else {
        await createRole(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateRole = async (formData) => {
    try {
      await httpService
        .updateRole(formData)
        .then((response) => {
          if (response.status == 200) {
            notificationService.successMessage(t("role.roleUpdateSuccess"));
            setTimeout(() => {
              navigate(all_routes.roleList);
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        // hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const createRole = async (formData) => {
    try {
      const response = await httpService.createRole(formData);
      if (response && response.status == 200) {
        notificationService.successMessage(t("role.roleCreateSuccess"));
        setTimeout(() => {
          navigate(all_routes.roleList);
        }, 500);
      }
    } catch (error) {
      if (error.validation_error.errors) {
        // hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("role.rolesAndPermissions"), link: "/role-list" },
    {
      label: id ? t("role.editRole") : t("role.addRole"),
      active: true,
    },
  ];

  const handleModuleSelection = (moduleId) => {
    setSelectedModules((prevModules) => {
      if (prevModules.includes(moduleId)) {
        return prevModules.filter((id) => id !== moduleId);
      } else {
        return [...prevModules, moduleId];
      }
    });
  };

  const handleAllowAll = (moduleIds) => {
    const allSelected = moduleIds.every((id) => selectedModules.includes(id));
    setSelectedModules((prevModules) =>
      allSelected
        ? prevModules.filter((id) => !moduleIds.includes(id))
        : [...prevModules, ...moduleIds],
    );
  };
  const permissionColumns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) => index + 1,
    },
    { title: t("tableColumnName.module"), dataIndex: "module" },
    ...[
      t("general.actions.create"),
      t("general.actions.edit"),
      t("general.actions.delete"),
      t("general.actions.view"),
    ].map((action, index) => ({
      title: action,
      render: (_, record) => (
        <div key={index}>
          {record.ids[index] !== -1 && (
            <Checkbox
              style={{ color: "red" }}
              checked={selectedModules.includes(record.ids[index])}
              onChange={() => handleModuleSelection(record.ids[index])}
            ></Checkbox>
          )}
        </div>
      ),
    })),
    {
      title: t("general.actions.allowAll"),
      render: (_, record) => (
        <div>
        {record.ids.every(id => id !== -1) && (
        <Checkbox
          style={{ color: "red" }}
          checked={record.ids.every((id) => selectedModules.includes(id))}
          onChange={() => handleAllowAll(record.ids)}
        ></Checkbox>
      )}
      </div>
      ),
    },
  ];
  const handleTableChange = () => {};

  useEffect(() => {
    if (schema && schema.length > 0) {
      const options = schema.map((s) => ({
        value: s,
        label: s,
      }));
      setSchemaOptions(options);
    }
  }, [schema]);
  const handleSchemaChange = (selectedOption) =>{
    setSelectedModules([])
setSelectedSchema(selectedOption.value)
}
const handleAssignButtonClick = (e)=>{
  e.preventDefault()
  setShowFullForm(true)
}
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("role.rolesAndPermissions")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            {pageLoading ? (
              <div className="row d-flex justify-content-center mt-2">
                <UnlimitLoading width={50} height={50} />
              </div>
            ) : (
              <div className="card-body add-product pb-0">
              <div className="row align-items-start">
              <div className="col-lg-3 col-sm-4 col-12">
                  <div className="unlimit-uikit-select">
                    <label className="mb-2">{t("role.permSchema")}</label>
                    <Select
                      options={schemaOptions}
                      className="select custom-select"
                      placeholder={t("role.selectSchema")}
                      value={schemaOptions.find(option => option.value === selectedSchema)}
                      onChange={(selectedOption) => handleSchemaChange(selectedOption)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitInput
                      label={t("inputField.label.roleName")}
                      id="name"
                      type="text"
                      placeholder={t("inputField.placeholder.enterRoleName")}
                      register={register}
                      rules={{
                        required: t("inputField.validation.required"),
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: t("inputField.validation.pattern"),
                        },
                      }}
                      error={errors.name}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                </div>
                  <div className="col-md-3 ps-2 mt-4 pt-2">
                    <span className="mb-4"></span>
                    <UnlimitToggle
                      isChecked={id ? false : true}
                      label={t("tableColumnName.status")}
                      id="is_active"
                      register={register}
                      />
                  </div>
              </div>
              {showFullForm && (
                <>
                  <h4 className="text-black f-bold bold mb-3">
                    {t("role.permissions")}
                  </h4>
                  <div className="table-responsive">
                    <UnlimitTable
                      columns={permissionColumns}
                      dataSource={permissions}
                      rowKey={(record) => record.module}
                      onChange={handleTableChange}
                      pagination={false}
                    />
                  </div>
                </>
              )}
              {!showFullForm && (
                <button
                  className="btn btn-submit mb-3"
                  onClick={(e) => handleAssignButtonClick(e)}
                >
                  {t("role.assignPermissions")}
                </button>
              )}
              {showFullForm && (
                <div className="col-lg-12 my-4">
                  <Link to={all_routes.roleList}>
                    <button type="button" className="btn btn-cancel me-2">
                      {t("general.button.cancel")}
                    </button>
                  </Link>
                  <UnlimitSubmitButton
                    label={
                      id
                        ? t("general.button.update")
                        : t("general.button.submit")
                    }
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleForm;
