import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Custom hook to get the selected country
export function useSelectedCountry() {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = useSelector((state) => state.locations.countries);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (user && countries.length > 0) {
            const country = countries.find((country) => country.id === user.country_id) || null;
            setSelectedCountry(country);
        } else {
            setSelectedCountry(null);
        }
    }, [user, countries]);

    return selectedCountry;
}
