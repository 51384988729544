import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/brand.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import UnlimitAttachement from "../../../components/ui-kit/unlimit-attachments";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { useTranslation } from "react-i18next";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";

const BrandForm = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState, setValue, watch, setError } =
    useForm();
    const { t } = useTranslation();
  const { errors } = formState;
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const getBrand = async () => {
    await httpService
      .getBrand(id)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          setValue("name", data.name);
          setExistingImageUrl(data.image);
          setValue("description", data.description);
          setValue("is_active", data.is_active == 1 ? true : false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      }).finally(()=>{
        setPageLoading(false)
      });
  };
  useEffect(() => {
    if (id) {
      setPageLoading(true)
      getBrand();
    }
  }, [id]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("is_active", data.is_active ? 1 : 0);
      if (data.image && data.image[0]) {
        formData.append("image", data.image[0]);
      }
      if (id) {
        formData.append("id", id);
        await updateBrand(formData);
      } else {
        await createBrand(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const createBrand = async (formData) => {
    try {
      await httpService
        .createBrand(formData)
        .then((response) => {
          if (response.status == 200) {
            notificationService.successMessage(t('brand.brandCreateSuccess'));
            setTimeout(() => {
              navigate(all_routes.brandlist);
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const updateBrand = async (formData) => {
    try {
      await httpService
        .updateBrand(formData)
        .then((response) => {
          if (response.status == 200) {
            notificationService.successMessage(t('brand.brandUpdateSuccess'));
            setTimeout(() => {
              navigate(all_routes.brandlist);
            }, 1000);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t('brand.brands'), link: "/brand-list" },
    { label: id ? t('brand.editBrand') : t('brand.addBrand'), active: true },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">
                {id ? t('brand.editBrand') : t('brand.addBrand')}
              </h3>
              <h6></h6>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
            {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
              <div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-blocks summer-description-box transfer mb-3">
                    <UnlimitInput
                      label={t("inputField.label.brandName")}
                      id="name"
                      placeholder={t("inputField.placeholder.enterName")}
                      type="text"
                      register={register}
                      rules={{
                        required: {
                          value: true,
                          message: t("inputField.validation.required"),
                        },
                        maxLength: { value: 50, message: t("inputField.validation.maxLength", { value: 50 }) }
                      }}
                      error={errors.name}
                      errorMessage={errors.name?.message}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-start justify-content-between">
                  <UnlimitFileUpload
                    label={t("inputField.label.image")}
                    id="image"
                    register={register}
                    rules={{
                      required: !id && t("inputField.validation.required"),
                    }}
                    accept=".jpeg, .png, .jpg"
                    error={errors.image}
                    errorMessage={errors.image?.message}
                    onChange={handleImageChange}
                  />
                  {(id || imageUrl) && (
                    <img
                      src={
                        id
                          ? id && imageUrl
                            ? imageUrl
                            : existingImageUrl
                          : imageUrl
                      }
                      alt={t("inputField.label.image")}
                      className="img-fluid"
                      style={{ maxWidth: "70px" }}
                    />
                  )}{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <UnlimitTextarea
                      label={t("inputField.label.description")}
                      id="description"
                      register={register}
                      rules={{
                        required: t("inputField.validation.required"),
                        maxLength: {
                          value: 500,
                          message: t("inputField.validation.maxLength", { value: 500 }),
                        },
                      }}
                      error={errors.description}
                      errorMessage={errors.description?.message}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="input-blocks">
                  <UnlimitToggle
                    label={t("inputField.label.status")}
                    id="is_active"
                    register={register}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <Link to={all_routes.brandlist} className="me-2">
                  <button type="button" className="btn btn-cancel">
                    {t("general.button.cancel")}
                  </button>
                </Link>
                <UnlimitSubmitButton
                  label={id ? t("general.button.update") : t("general.button.submit")}
                  isLoading={isLoading}
                />
              </div>
            </div>)}
            </div>
          </div>
        </form>

        {/* /add */}
      </div>
    </div>
  );
};

export default BrandForm;
