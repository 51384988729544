import React, { useEffect, useState } from "react";
import locationAPI from "../../../services/http/location.service";
import httpService from "../../../services/http/peoples/vendor.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { store } from "../../../core/redux/store";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitFileUpload from "../../../components/ui-kit/unlimit-fileupload";
import { useTranslation } from "react-i18next";
import useRoleAndPerm from "../../../utils/permissionFunction";
import { useDispatch } from "react-redux";
import { fetchVendors } from "../../../core/redux/reducers/vendors.slice";

const VendorForm = () => {
  const { hasPermission, getRedirectLink } = useRoleAndPerm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const dispatch = useDispatch();
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const { locations } = store.getState();
  const vendorLink = getRedirectLink("vendor");
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
    watch,
  } = useForm();
  const { errors } = formState;
  const { id } = useParams();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);
  // const [imageUrlAttachments, setImageUrlAttachments] = useState("");
  const [existingImageUrlAttachments, setExistingImageUrlAttachments] =
    useState("");
  // const watchFile = watch("attachment");
  // const removeImage = () => {
  //   setImageUrlAttachments("");
  //   setExistingImageUrlAttachments("");
  //   setValue("attachment", "");
  // };
  // useEffect(() => {
  //   if (watchFile?.length > 0) {
  //     setImageUrlAttachments(URL.createObjectURL(watchFile[0]));
  //   }
  // }, [watchFile]);
  /* const getCountries = async () => {
      await locationAPI
        .getCountries({ page: 1, perPage: 1000 })
        .then((response) => {
          if (response && response.status == 200) {
            setCountries(response.data.data?.data);
          }
        }).catch(error => {
          console.error('Error:', error);
        });
    };*/
  const hydratePredefinedValues = (data) => {
    setValue("business_name", data.business_name);
    setValue("name_constant", data.name_constant);
    setValue("contact_person_name", data.contact_person_name);
    setValue("address", data.address);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("cvr_no", data.cvr_no);
    setValue("zip", data.zip);
    setValue("is_active", data.is_active == 1 ? true : false);
    setValue("description", data.description);
    setValue("city_id", data.city?.id);
    setValue("country_id", data.country?.id);
    setExistingImageUrl(data.logo);
    setExistingImageUrlAttachments(data.attachments);
    setValue("quickpay_api_key", data.quickpay_api_key);
    setValue(
      "quick_pay_currency",
      locations.countries?.map((country) => {
        if (
          country.currency?.toLowerCase() ==
          data?.quick_pay_currency?.toLowerCase()
        ) {
          return { ...country, label: country.currency, value: country.id };
        }
      })
    );
    setValue("quick_pay_merchant_id", data.quick_pay_merchant_id);
    setValue("quick_pay_agreement_id", data.quick_pay_agreement_id);
    setValue("economic_app_secret_token", data.economic_app_secret_token);
    setValue(
      "economic_agreement_access_token",
      data.economic_agreement_access_token
    );
    /** set selected country **/
    if (data.country?.id) {
      setCountries((prevCountries) => {
        let country = prevCountries.find(
          (item) => item.id == data?.country?.id
        );
        if (country) {
          country = {
            ...country,
            label: country.name,
            value: country.id,
          };
          setValue("country_id", country);
        }
        return prevCountries;
      });
    }
  };
  const getVendor = async () => {
    try {
      setPageLoading(true);
      await httpService
        .getVendor(id)
        .then(async (response) => {
          if (response && response.status == 200) {
            const data = response.data.data;
            hydratePredefinedValues(data);
            await getCities(data?.country?.id);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } finally {
      setPageLoading(false);
    }
  };
  const fetchPredefinedValues = async () => {
    //await getCountries();
    if (id) {
      await getVendor();
    }
  };
  useEffect(() => {
    setCountries(locations.countries);
    fetchPredefinedValues();
  }, [id]);
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name_constant", data.name_constant);
      formData.append("business_name", data.business_name);
      formData.append("description", data.description);
      formData.append("address", data.address);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("cvr_no", data.cvr_no);
      formData.append("zip", data.zip);
      formData.append("is_active", data.is_active ? 1 : 0);
      formData.append("contact_person_name", data.contact_person_name);
      formData.append("quickpay_api_key", data.quickpay_api_key);
      formData.append("quick_pay_merchant_id", data.quick_pay_merchant_id);
      formData.append("quick_pay_agreement_id", data.quick_pay_agreement_id);
      formData.append(
        "economic_app_secret_token",
        data.economic_app_secret_token
      );
      formData.append(
        "economic_agreement_access_token",
        data.economic_agreement_access_token
      );
      if (fileList) {
        for (let i = 0; i < fileList.length; i++) {
          formData.append(`attachments[${i}]`, fileList[i]);
        }
      }
      if (data.logo[0]) {
        formData.append("logo", data.logo[0]);
      }
      if (data.country_id) {
        formData.append("country_id", data.country_id.id);
      }
      if (data.quick_pay_currency?.currency) {
        formData.append(
          "quick_pay_currency",
          data.quick_pay_currency?.currency
        );
      }
      if (data.city) {
        formData.append("city_id", data.city.id);
      }
      if (id) {
        formData.append("id", id);
        await updateVendor(formData);
      } else {
        await createVendor(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const updateVendor = async (formData) => {
    await httpService
      .updateVendor(formData)
      .then((response) => {
        if (response.status === 200) {
          notificationService.successMessage(t("vendor.vendorUpdateSuccess"));
          if (hasPermission(["vendor-view"])) dispatch(fetchVendors(true));
          setTimeout(() => {
            navigate(all_routes.vendorList);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.validation_error.errors) {
          delete error.validation_error.errors["attachments.0"];
          hydrateErrors(error.validation_error.errors, setError);
        }
      });
  };
  const createVendor = async (formData) => {
    try {
      await httpService
        .createVendor(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(t("vendor.vendorCreateSuccess"));
            if (hasPermission(["vendor-view"])) dispatch(fetchVendors(true));
            navigate(all_routes.vendorList);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
          console.error("Error:", error);
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const getCities = async (countryid) => {
    try {
      setCitiesLoading(true);
      setCities([]);
      setValue("city", null);
      await locationAPI
        .getCitiesByCountry(countryid)
        .then((response) => {
          if (response && response.status == 200) {
            setCities(response.data.data?.data);
            if (id) {
              setCities((prevCities) => {
                let city = prevCities.find(
                  (item) => item.id == getValues("city_id")
                );
                if (city) {
                  city = {
                    ...city,
                    label: city.name,
                    value: city.id,
                  };
                  setValue("city", city);
                }
                return prevCities;
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } finally {
      setCitiesLoading(false);
    }
  };
  const onChangeCountry = (value) => {
    if (value) {
      getCities(value.id);
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("vendor.vendor"), link: "/vendor-list" },
    {
      label: id ? t("vendor.editVendor") : t("vendor.addVendor"),
      active: true,
    },
  ];
  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
            <h4>{id ? t("vendor.editVendor") : t("vendor.addVendor")}</h4>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* /add */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.businessName")}
                        id="business_name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterVendorName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.business_name}
                        errorMessage={errors.business_name?.message}
                      />
                    </div>
                    <div className="col-md-4">
                      <UnlimitInput
                        label={t("inputField.label.NameConstant")}
                        id="name_constant"
                        type="text"
                        disabled={id}
                        placeholder={t(
                          "inputField.placeholder.enterNameConstant"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.nameConstantPattern"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.name_constant}
                        errorMessage={errors.name_constant?.message}
                      />
                    </div>
                    <div className="col-md-4 d-flex align-items-start justify-content-between">
                      <UnlimitFileUpload
                        label={t("inputField.label.logo")}
                        id="logo"
                        register={register}
                        accept=".jpeg, .png, .jpg"
                        error={errors.logo}
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        errorMessage={errors.logo?.message}
                        onChange={handleImageChange}
                      />
                      {(id || imageUrl) && (
                        <img
                          src={
                            id
                              ? id && imageUrl
                                ? imageUrl
                                : existingImageUrl
                              : imageUrl
                          }
                          alt="Vendor Image"
                          className="img-fluid ms-2"
                          style={{ maxWidth: "70px", maxHeight: "70px" }}
                        />
                      )}{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.contactName")}
                        id="contact_person_name"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterContactName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Za-z\s\-',.*&@]+$/,
                            message: t(
                              "inputField.validation.contactNamePattern"
                            ),
                          },
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.contact_person_name}
                        errorMessage={errors.contact_person_name?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.phone")}
                        id="phone"
                        type="text"
                        placeholder={t("inputField.placeholder.enterPhone")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\+?\d+$/,
                            message: t("inputField.validation.phonePattern"),
                          },
                          maxLength: {
                            value: 20,
                            message: t("inputField.validation.maxLength", {
                              value: 20,
                            }),
                          },
                        }}
                        error={errors.phone}
                        errorMessage={errors.phone?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.email")}
                        id="email"
                        type="email"
                        placeholder={t("inputField.placeholder.enterEmail")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t("inputField.validation.emailPattern"),
                          },
                        }}
                        error={errors.email}
                        errorMessage={errors.email?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.cvrNo")}
                        id="cvr_no"
                        type="text"
                        placeholder={t("inputField.placeholder.enterCvrNo")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^\d{8,}$/,
                            message: t("inputField.validation.cvrNoPattern"),
                          },
                          maxLength: {
                            value: 8,
                            message: t("inputField.validation.maxLength", {
                              value: 8,
                            }),
                          },
                        }}
                        error={errors.cvr_no}
                        errorMessage={errors.cvr_no?.message}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.address")}
                        id="address"
                        type="text"
                        placeholder={t("inputField.placeholder.enterAddress")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                        }}
                        error={errors.address}
                        errorMessage={errors.address?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.zipCode")}
                        id="zip"
                        type="text"
                        placeholder={t("inputField.placeholder.enterZipCode")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[1-9][0-9]*$/,
                            message: t("inputField.validation.zipCodePattern"),
                          },
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                        }}
                        error={errors.zip}
                        errorMessage={errors.zip?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.country")}
                        id="country_id"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectCountry")}
                        options={countries?.filter((country) => country.flag)}
                        optionLabel="name"
                        optionValue="id"
                        onChange={onChangeCountry}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.city")}
                        id="city"
                        errors={errors}
                        control={control}
                        register={register}
                        options={cities}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.selectCity")}
                        optionLabel="name"
                        optionValue="id"
                        isLoading={citiesLoading}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-blocks mb-3">
                        <UnlimitToggle
                          isChecked={id ? false : true}
                          label={t("inputField.label.status")}
                          id="is_active"
                          register={register}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <UnlimitTextarea
                        label={t("inputField.label.description")}
                        id="description"
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 500,
                            message: t("inputField.validation.maxLength", {
                              value: 500,
                            }),
                          },
                        }}
                        error={errors.description}
                        errorMessage={errors.description?.message}
                      />
                    </div>
                  </div>
                  <p style={{ color: "#BEBEBE" }}>
                    {t("inputField.label.quickPaySettings")}
                  </p>
                  <div className="row">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.quickPayApiKey")}
                        id="quickpay_api_key"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterQuickPayApiKey"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 225,
                            message: t("inputField.validation.maxLength", {
                              value: 225,
                            }),
                          },
                        }}
                        error={errors.quickpay_api_key}
                        errorMessage={errors.quickpay_api_key?.message}
                      />
                    </div>

                    <div className="col-md-3">
                      <UnlimitSelect
                        label={t("inputField.label.quickPayCurrency")}
                        id="quick_pay_currency"
                        errors={errors}
                        control={control}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t(
                          "inputField.placeholder.selectQuickPayCurrency"
                        )}
                        options={countries.filter((country, index, self) =>index === self.findIndex((c) => c.currency === country.currency))}
                        optionLabel="currency"
                        optionValue="id"
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.quickPayMerchantId")}
                        id="quick_pay_merchant_id"
                        type="number"
                        placeholder={t(
                          "inputField.placeholder.enterQuickPayMerchantId"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                        }}
                        error={errors.quick_pay_merchant_id}
                        errorMessage={errors.quick_pay_merchant_id?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.quickPayAgreementId")}
                        id="quick_pay_agreement_id"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterQuickPayAgreementId"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 10,
                            message: t("inputField.validation.maxLength", {
                              value: 10,
                            }),
                          },
                        }}
                        error={errors.quick_pay_agreement_id}
                        errorMessage={errors.quick_pay_agreement_id?.message}
                      />
                    </div>
                  </div>
                  <p style={{ color: "#BEBEBE" }}>
                    {t("inputField.label.economicappSettings")}
                  </p>
                  <div className="row mb-md-3">
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t("inputField.label.economicAppSecretToken")}
                        id="economic_app_secret_token"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterEconomicAppSecretToken"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 225,
                            message: t("inputField.validation.maxLength", {
                              value: 225,
                            }),
                          },
                        }}
                        error={errors.economic_app_secret_token}
                        errorMessage={errors.economic_app_secret_token?.message}
                      />
                    </div>
                    <div className="col-md-3">
                      <UnlimitInput
                        label={t(
                          "inputField.label.economicAgreementAccessToken"
                        )}
                        id="economic_agreement_access_token"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterEconomicAgreementAccessToken"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 225,
                            message: t("inputField.validation.maxLength", {
                              value: 225,
                            }),
                          },
                        }}
                        error={errors.economic_agreement_access_token}
                        errorMessage={
                          errors.economic_agreement_access_token?.message
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <Link to={vendorLink} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* /add */}
      </div>
    </div>
  );
};

export default VendorForm;
