// Define the formatting cases
const CountryFormat = {
  US_STANDARD: "US_STANDARD",
  EUROPEAN: "EUROPEAN",
  SWISS: "SWISS",
  NORDIC: "NORDIC",
};

function formatNumber(number, selectedCountry, toFixed = 0) {
  const numberFromString = Number(number);
  // Return 0 if the numberFromString is NaN
  if (isNaN(numberFromString)) {
    return "0";
  }
  // Determine formatting based on selected country format
  let formattedNumber;
  switch (selectedCountry?.currency_format) {
    case CountryFormat.US_STANDARD:
      formattedNumber = numberFromString
        .toFixed(toFixed)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // US: 1,000.00
      break;

    case CountryFormat.EUROPEAN:
      formattedNumber = numberFromString
        .toFixed(toFixed)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."); // European: 1.000,00
      break;

    case CountryFormat.SWISS: {
      const [integerPart, decimalPart] = numberFromString
        .toFixed(toFixed)
        .split(".");
      formattedNumber =
        integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "'") +
        (decimalPart ? `.${decimalPart}` : "");
      break;
    }

    case CountryFormat.NORDIC:
      formattedNumber = numberFromString
        .toFixed(toFixed)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " "); // Nordic: 1 000,00
      break;

    default:
      // Default to standard format if no country match is found
      formattedNumber = numberFromString
        .toFixed(toFixed)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      break;
  }

  return formattedNumber;
}

export default formatNumber;
