import { useSelector } from 'react-redux';
import outletHttpService from "../services/http/stores/outlet.service";
import React, { useEffect, useState } from "react";
const useRoleAndPerm = () => {
    const auth = useSelector(state => state.auth);
    const roles = auth.roles ?? [];
    const roleType = roles[0]?.role_type?.toLowerCase();
    const userType = auth?.user?.user_type?.toLowerCase();
    const vendors = useSelector(state => state.vendors.vendors);
    const hasPermission = (requiredPermissions) => {
        return requiredPermissions.some(permissionName =>
            roles[0]?.permissions.some(permission => permission.name === permissionName)
        );
    };
    const isVendorRole = roleType === "vendor"
    const isOutletRole = roleType === "outlet"
    const isVendorOrOutlet = roleType === "vendor" || roleType === "outlet";
    const getRedirectLink = (linkType) => {
        switch (linkType) {
            case "vendor":
                if (isVendorRole && vendors?.data?.data[0]?.id) {
                    return `/vendor/${vendors?.data?.data[0]?.id}/view`;
                }
                return "/vendor-list";
            case "outlet":
                if (isOutletRole && auth?.user?.outlet?.id) {
                    return `/outlets/${auth?.user?.outlet?.id}/details`;
                }
                return "/outlets-list";
            default:
                return "/";
        }
    };
    return { hasPermission, isVendorOrOutlet,roleType,userType,getRedirectLink,isVendorRole,isOutletRole };
};

export default useRoleAndPerm;
