import { Canvas } from "@react-three/fiber";
import { OrbitControls, useTexture } from "@react-three/drei";
import { Suspense, useEffect } from "react";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { useLoader } from "@react-three/fiber";
import "./3dStyles.css";
import { useNavigate } from "react-router-dom";

export function Model({ files }) {
  // Load material file
  const materials = useLoader(MTLLoader, files?.material_file);
  // Load object file and set materials
  const obj = useLoader(OBJLoader, files?.object_file, (loader) => {
    if (materials) {
      materials.preload();
      loader.setMaterials(materials);
    }
  });
  
  // Load texture (bitmap or png based on what you need)
  const texture = useTexture(files?.bitmap_file || files?.png_file); 
  
  if (!obj || !texture) {
    console.error("Failed to load OBJ or texture file.");
    return null;
  }
  return (
    <mesh geometry={obj?.children[0]?.geometry} position={[0, -100, 0]}>
      <primitive object={obj} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}

export default function ThreedFileWindowIphone({ height, id, files }) {
  const navigate = useNavigate();

  return (
    <div
      style={{
        height,
        background:
          "linear-gradient(292deg, #131416 0%, rgb(79 79 79) 49.26%, #121315 99.52%)",
      }}
    >
      <img
        src="/assets/img/icons/maximize-1-svgrepo-com 1.svg"
        alt=""
        className="expand-button color-primary"
        onClick={() => navigate(`/3d-sample-catalog/${id}/view`, { state: { files } })}
      />
      <Canvas className="canvas" camera={{ position: [0, 0, 300] }}>
        <OrbitControls enableZoom={true} target={[0, 0, 0]} />
        <ambientLight intensity={1} />
        <directionalLight position={[-2, 5, 2]} intensity={1.5} />
        <Suspense fallback={null}>
          <Model files={files} />
        </Suspense>
      </Canvas>
    </div>
  );
}
