/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Plus, Search, XCircle } from "react-feather";
import { messaging } from '../../services/firebaseConfig';
import { all_routes } from "../../Router/all_routes";
import authService from "../../services/http/auth.service";
import httpService from "../../services/http/settings/country.service";
import { logout } from "../../core/redux/reducers/auth.slice";
import { authSuccess } from "../../core/redux/reducers/auth.slice";
import ReactCountryFlag from "react-country-flag";
import notificationService from "../../services/toaster.service";
import { useTranslation } from "react-i18next";
import { fetchVendors } from "../../core/redux/reducers/vendors.slice";
import LanguageSelector from "../../components/LanguageSelecter";
import useRoleAndPerm from "../../utils/permissionFunction";
import NotificationDropdown from "../../components/notification-dropdown";
import { onMessage } from "firebase/messaging";
import NotificationRequest from "../../services/firebase.service";

const Header = () => {
  const { t, i18n } = useTranslation();
  const { isVendorRole,isOutletRole, isVendorOrOutlet,getRedirectLink } = useRoleAndPerm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, SetToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const countries = useSelector((state) => state.locations.countries);


  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };

  const sidebarOverlay = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const handleLogout = async () => {
    navigate("/login");
    const res = await authService.logout();
    localStorage.removeItem("accessToken");
    if (res) dispatch(logout());
  };
  const handleCountryChange = async (record) => {
    setSelectedCountry(null);
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", user.id);
      formData.append("country_id", record.id);
      await httpService.updateCountrySetting(formData).then((response) => {
        if (response.status === 200) {
          const updatedUser = { ...user, country_id: record.id };
          dispatch(
            authSuccess({ accessToken: auth.accessToken, user: updatedUser })
          );
          dispatch(fetchVendors(true));
          notificationService.successMessage("Country updated successfully!");
          setTimeout(() => {
            navigate(all_routes.dashboard);
          }, 1000);
        }
      });
    } finally {
      setIsLoading(false); 
    }
  };
  useEffect(() => {
    if (user && countries.length > 0) {
      const userCountry = countries.find(
        (country) => country.id === user.country_id
      );
      setSelectedCountry(userCountry);
    }
  }, [user, countries]);
  const vendorLink = getRedirectLink('vendor');
  const outletLink = getRedirectLink('outlet');

  NotificationRequest(handleNotificationMessage)
  function handleNotificationMessage(notification) {
      notificationService.notificationInfoMessage(notification);
  }
  
  return (
    <>
      <div className="header">
        {/* Logo */}
        <div className={`header-left ${toggle ? "" : "active"}`}>
          <Link to="/" className="logo logo-normal">
            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
          </Link>
          <Link to="/" className="logo logo-white">
            <ImageWithBasePath src="assets/img/logo-white.png" alt="img" />
          </Link>
          <Link to="/" className="logo-small">
            <ImageWithBasePath src="assets/img/logo-small.png" alt="img" />
          </Link>
          <Link
            id="toggle_btn"
            to="#"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                ? "none"
                : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-searchinputs">
            <div className="top-nav-search">
              <Link to="#" className="responsive-search">
                <Search />
              </Link>
              <form action="#" className="dropdown">
                <div
                  className="searchinputs"
                  // id="dropdownMenuClickable"
                  // data-bs-toggle="dropdown"
                  data-bs-auto-close="false"
                >
                  <input type="text" placeholder={t("general.button.search" )}/>
                  <div className="search-addon">
                    <span>
                      <XCircle className="feather-14" />
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-menu search-dropdown"
                  aria-labelledby="dropdownMenuClickable"
                >
                  <div className="search-info">
                    <h6>
                      <span>
                        <i data-feather="search" className="feather-16" />
                      </span>
                      Recent Searches
                    </h6>
                    <ul className="search-tags">
                      <li>
                        <Link to="#">Products</Link>
                      </li>
                      <li>
                        <Link to="#">Sales</Link>
                      </li>
                      <li>
                        <Link to="#">Applications</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="search-info">
                    <h6>
                      <span>
                        <i data-feather="help-circle" className="feather-16" />
                      </span>
                      Help
                    </h6>
                    <p>
                      How to Change Product Volume from 0 to 200 on Inventory
                      management
                    </p>
                    <p>Change Product Name</p>
                  </div>
                  <div className="search-info">
                    <h6>
                      <span>
                        <i data-feather="user" className="feather-16" />
                      </span>
                      Customers
                    </h6>
                    <ul className="customers">
                      <li>
                        <Link to="#">
                          Aron Varu
                          <ImageWithBasePath
                            src="assets/img/profiles/avator1.jpg"
                            alt="Avatar"
                            className="img-fluid"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Jonita
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-01.jpg"
                            alt="Avatar"
                            className="img-fluid"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Aaron
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-10.jpg"
                            alt="Avatar"
                            className="img-fluid"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <NotificationDropdown/>
          <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
            <p
            style={{width:"auto",padding:"0px 10px"}}
              className="nav-link dropdown-toggle country-dropdown-header"
              data-bs-toggle="dropdown"
              role="button"
            >
              {selectedCountry && (
                <>
                  <ReactCountryFlag
                    style={{ width: "24px", height: "16px" }}
                    svg
                    className="emojiFlag"
                    countryCode={selectedCountry.iso2}
                  />
                  <span className="ms-2">
                    {selectedCountry &&
                      "(" + selectedCountry?.currency_symbol + ")"}
                  </span>
                </>
              )}
            </p>
            <div className="dropdown-menu dropdown-menu-right scrollable-menu">
              {[...countries.filter((country) => country.flag)]
                .sort((a, b) => {
                  if (a.name === "Denmark") return -1;
                  if (b.name === "Denmark") return 1;
                  return a.name.localeCompare(b.name);
                })
                .map((country) => (
                  <p
                    key={country.id}
                    onClick={() => handleCountryChange(country)}
                    className="dropdown-item"
                  >
                    <ReactCountryFlag
                      style={{ width: "24px", height: "16px" }}
                      svg
                      className="emojiFlag"
                      countryCode={country.iso2}
                    />
                    <span className="ms-2">{country.name}</span>
                    <span className="ms-2">
                      {country && "(" + country?.currency_symbol + ")"}
                    </span>
                  </p>
                ))}
            </div>
          </li>
          <LanguageSelector />

          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="me-2">
                  <ImageWithBasePath
                    type="link"
                    src={user?.image}
                    alt="img"
                    style={{ width: 40, height: 40,borderRadius:"50%" }}
                  />
                </span>
                <span className="user-detail">
                  <span className="user-name">
                    {isVendorOrOutlet ? isVendorRole ? user?.vendor?.business_name: user?.outlet?.name : user?.name ?? "Super Admin"}
                  </span>
                  <span className="user-role">{user?.email ?? ""}</span>
                </span>
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  {/* <span className="user-img">
                    <ImageWithBasePath
                      type="link"
                      src={user?.image}
                      alt="img"
                      className="h-100"
                    />
                    <span className="status online" />
                  </span> */}
                  <div className="profilesets">
                    <h6>{user?.name}</h6>
                    <h5>{user?.email}</h5>
                  </div>
                </div>
                <hr className="m-0" />
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#change-password"
                  className="dropdown-item"
                >
                  {t("general.updatePassword")}
                </Link>
                <Link to={`/user/${user?.id}/edit`} className="dropdown-item">
                  {t("general.myProfile")}
                </Link>
                {isVendorOrOutlet && (
                  <Link
                    to={isVendorRole ? vendorLink : outletLink}
                    className="dropdown-item"
                  >
                    {isVendorRole
                      ? t("general.vendorProfile")
                      : t("general.outletProfile")
                      }
                  </Link>
                )}

                {/* <Link className="dropdown-item" to={route.generalsettings}>
                  <i className="me-2" data-feather="settings" />
                  Settings
                </Link> */}
                <hr className="m-0" />
                <div
                  className="dropdown-item logout pb-0"
                  onClick={handleLogout}
                >
                  <ImageWithBasePath
                    src="assets/img/icons/log-out.svg"
                    alt="img"
                    className="me-2"
                  />
                  {t("general.actions.logout")}
                </div>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {/* <Link className="dropdown-item" to="profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="generalsettings">
              Settings
            </Link> */}
            <Link className="dropdown-item" to="signin">
              Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
