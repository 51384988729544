import React, { useEffect, useState } from "react";
import purchaseHttpService from "../../../services/http/purchases/purchase.service";
import { Link } from "react-router-dom";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitButton from "../../../components/ui-kit/unlimit-button";
import { useTranslation } from "react-i18next";
import { all_routes } from "../../../Router/all_routes";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useSelector } from "react-redux";
import useRoleAndPerm from "../../../utils/permissionFunction";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
const PriceLineFromPurchaseSearch = ({ setPurchaseItemsList, setLoading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isOutletRole,isVendorRole,isVendorOrOutlet } = useRoleAndPerm();
  const auth = useSelector((state) => state.auth);
  const [purchaseIsLoading, setPurchaseIsLoading] = useState(false);
  const [purchase, setPurchase] = useState([]);
  const [supplierName, setSupplierName] = useState("");
  const [supplierImage, setSupplierImage] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [vendor, setVendor] = useState([]);
  const vendors = useSelector((state) => state.vendors.vendors);
  const { register, handleSubmit, formState, control, setValue, watch } =
    useForm();

  const { errors } = formState;
  const { t } = useTranslation();

  const getPurchaseList = async (id) => {
    setLoading(true);
    await purchaseHttpService
      .getPurchaseItems(id)
      .then((response) => {
        if (response.status === 200) {
          setPurchaseItemsList(response.data.data.items);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function fetchPurchase(vendorID) {
    const data = {
      vendor_id: vendorID,
    };
    try {
      const purchase = await purchaseHttpService.purchaseList(data);
      setPurchase(purchase?.data?.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setPurchaseIsLoading(false);
    }
  }

  useEffect(() => {
    setVendor(vendors?.data?.data);
    if (isOutletRole) {
      setPurchaseIsLoading(true);
      fetchPurchase();
    }
  }, []);

  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      await getPurchaseList(data.purchase_id?.id);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedPurchaseId = watch("purchase_id");

  useEffect(() => {
    if (selectedPurchaseId) {
      const selectedPurchase = purchase.find(
        (p) => p.id === selectedPurchaseId.id
      );
      if (selectedPurchase) {
        setSupplierName(selectedPurchase?.supplier?.name);
        setSupplierImage(selectedPurchase?.supplier?.image);
        setPurchaseDate(
          moment(selectedPurchase?.purchase_date).format("DD MMM YYYY")
        );
      } else {
        setSupplierName("");
        setPurchaseDate("");
      }
    }
    setPurchaseItemsList([]);
  }, [selectedPurchaseId, purchase]);
  useEffect(()=>{
    if (isVendorRole) {
      fetchPurchase(auth?.user?.vendor?.id)
    } else if (isOutletRole) {
      fetchPurchase(auth?.user?.outlet?.vendor?.id)
    }
  },[auth])
  const handleVendorChange = (selectedOption) => {
    setPurchaseIsLoading(true);
    fetchPurchase(selectedOption.id);
  };
  return (
    <div>
      {/* /Search */}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="card">
          <div className="card-body">
            <div className="row mb-2">
              {!isVendorOrOutlet && (
                <div className="col-md-6">
                  <UnlimitSelect
                    label={t("vendor.vendor")}
                    id="vendor_id"
                    errors={errors}
                    control={control}
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                    }}
                    placeholder={t("inputField.placeholder.chooseVendor")}
                    options={vendor}
                    showLogo={true}
                    onChange={handleVendorChange}
                    optionLabel="business_name"
                    optionValue="id"
                  />
                </div>
              )}
              <div className={isVendorOrOutlet ?"col-md-12":"col-md-6" }>
                <UnlimitSelect
                  label={t("tableColumnName.purchaseRefernce")}
                  id="purchase_id"
                  errors={errors}
                  control={control}
                  isLoading={purchaseIsLoading}
                  register={register}
                  options={purchase}
                  rules={{
                    required: t("inputField.validation.required"),
                  }}
                  placeholder={t(
                    "inputField.placeholder.selectpurchaseRefernce"
                  )}
                  optionLabel="order_no"
                  optionValue="id"
                />
              </div>
              {supplierName && (
                <div className="col-md-6 my-3">
                  <label className="fw-bold">{t("supplier.supplier")}</label>
                  <div className="d-flex align-items-center">
                              <ImageWithBasePath
                                type="link"
                                src={supplierImage}
                                alt="Contact"
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                }}
                              />
                              {supplierName}
                            </div>
                </div>
              )}
              {purchaseDate && (
                <div className="col-md-6 my-3">
                  <label className="fw-bold">
                    {t("tableColumnName.purchaseDate")}
                  </label>
                  <div className="mt-2">{purchaseDate}</div>
                </div>
              )}
            </div>
            <Link to={all_routes.priceLinelist}>
              <button type="button" className="btn btn-cancel me-2">
                {t("general.button.cancel")}
              </button>
            </Link>
            <UnlimitButton
              text={t("general.button.search")}
              isLoading={isLoading}
            />
            {/* /Filter */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PriceLineFromPurchaseSearch;
