import { Link, useNavigate, useParams } from "react-router-dom";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import httpService from "../../../services/http/products/product.service";
import distributionHttpService from "../../../services/http/inventory/distribution.service";
import purchaseHttpService from "../../../services/http/purchases/purchase.service";
import { useEffect, useState } from "react";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitTable from "../../../components/ui-kit/unlimit-table";
import usePagination from "../../../custom-hooks/usePagination";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { useTranslation } from "react-i18next";
import ThreedFileWindowIphone from "../../sample/sample-iphone/3d-window";
import UnlimitImageGallery from "../../../components/ui-kit/unlimit-image-gallery";
import UnlimitFieldDetailsRow from "../../../components/ui-kit/unlimit-field-details-row";
import { useForm } from "react-hook-form";
import moment from "moment";
import useRoleAndPerm from "../../../utils/permissionFunction";
import ProductSalePriceDialog from "./product-sale-price-dialog";
import formatNumber from "../../../utils/numberFunction";
import { useSelectedCountry } from "../../../feature-module/dashboard/hooks/useSelectedCountry";

export default function ProductDetails() {
  const { hasPermission, isOutletRole,isVendorOrOutlet } = useRoleAndPerm();
  const [pageLoading, setPageLoading] = useState(false);
  const selectedCountry = useSelectedCountry();
  const [purchaseHistoryLoading, setPurchaseHistoryLoading] = useState(false);
  const [distributionLoading, setDistributionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [purchaseHistory, setPurchaseHistory] = useState(null);
  const [distribution, setDistribution] = useState(null);
  const { t } = useTranslation();
  const { paginationParams: purchasePaginationParams, updatePaginationParams: updatePurchasePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t("pagination.items")}`,
    },
  });
  
  const { paginationParams: distributionPaginationParams, updatePaginationParams: updateDistributionPaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t("pagination.items")}`,
    },
  });
  

  const navigate = useNavigate();
  const { register } = useForm();

  const getProduct = () => {
    setPageLoading(true);
    httpService
      .getProduct(id)
      .then((response) => {
        if (response && response.status === 200) {
          setProduct(response.data.data);
          getDistribution(response.data?.data?.catalog?.ean);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const getPurchaseHistory = (pagination = purchasePaginationParams.pagination) => {
    setPurchaseHistoryLoading(true);
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      product_id: id,
    };
    purchaseHttpService
      .purchaseList(data)
      .then((response) => {
        if (response && response.status === 200) {
          setPurchaseHistory(response.data.data?.data);
        }
        updatePurchasePaginationParams({
          pagination: {
            ...purchasePaginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        updatePurchasePaginationParams({
          pagination: {
            ...purchasePaginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setPurchaseHistoryLoading(false);
      });
  };
  const getDistribution = (ean, pagination = distributionPaginationParams.pagination) => {
    setDistributionLoading(true);
    const data = {
      ean: ean,
      page: pagination?.current,
    per_page: pagination?.pageSize,
    };
    distributionHttpService
      .distributionList(data)
      .then((response) => {
        if (response && response.status === 200) {
          setDistribution(response.data.data?.data);
        }
        updateDistributionPaginationParams({
          pagination: {
            ...distributionPaginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDistributionLoading(false);
      });
  };
  const handlePurchaseTableChange = async (pagination, filters, sorter) => {
    await updatePurchasePaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getPurchaseHistory(pagination);
  };
  const handleDistributionTableChange = async (pagination, filters, sorter) => {
    await updateDistributionPaginationParams({
      pagination,
      filters,
      sorter,
    });
    await getDistribution(product?.catalog?.ean, pagination);
  };
  
  useEffect(() => {
    if (id) {
      getProduct();
      getPurchaseHistory();
    }
  }, [id]);
  const columns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (purchasePaginationParams?.pagination?.current - 1) *
          purchasePaginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "order_no",
      render: (text, record) => {
        return record?.order_no ?? "";
      },
    },
    {
      title: t("tableColumnName.purchaseDate"),
      dataIndex: "purchase_date",
      render: (text) => {
        return moment(text).format("DD MMM YYYY");
      },
      sorter: true,
    },
    {
      title: t("tableColumnName.purchaseQuantity"),
      dataIndex: "initial_quantity",
      align: "right",
      render: (text, record) => {
        return formatNumber(record.item?.initial_quantity,selectedCountry);
      },
    },
    {
      title: t("tableColumnName.costPrice"),
      dataIndex: "cost_price",
      align: "right",
      render: (text, record) => formatNumber(record.item?.unit_cost,selectedCountry,2),
    },
    {
      title: t("tableColumnName.salePrice"),
      dataIndex: "cost_price",
      align: "right",
      render: (text, record) => formatNumber(record.item?.sale_price,selectedCountry,2),
    },
    {
      title: t("supplier.supplier"),
      dataIndex: "supplier",
      render: (text, record) => (
        <Link
          to={`/supplier/${record.supplier_id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.supplier?.image}
              alt="Contact"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record?.supplier?.name}
          </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.vendor"),
      dataIndex: "vendor.logo",
      hidden: isVendorOrOutlet,
      render: (text, record) => (
        <Link
          to={`/vendor/${record.vendor_id}/view`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              type="link"
              src={record.vendor?.logo}
              alt="Contact"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            {record?.vendor?.business_name}
          </div>
        </Link>
      ),
    },
    /*  {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    }, */
    {
      title: t("tableColumnName.actions"),
      key: "actions",
      hidden: !hasPermission(["purchase-view", "purchase-delete"]),
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {hasPermission(["purchase-view"]) && (
              <Link
                to={`/purchase/${record.id}/details`}
                className="me-2 edit-icon p-2"
              >
                <i className="feather-eye"></i>
              </Link>
            )}
          </div>
        </div>
      ),
    },
  ];
  const distributionColumns = [
    {
      title: t("tableColumnName.srNo"),
      dataIndex: "index",
      align: "center",
      render: (value, item, index) =>
        (distributionPaginationParams?.pagination?.current - 1) *
          distributionPaginationParams?.pagination?.pageSize +
        (index + 1),
    },
    {
      title: "Distribution Date",
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY") : "";
      },
    }, 
    {
      title: t("tableColumnName.initialQuantity"),
      dataIndex: "initial_quantity",
      align: "center",
      render:(text)=> formatNumber(text,selectedCountry)
    },
    {
      title: t("tableColumnName.availableQuantity"),
      dataIndex: "quantity",
      align: "center",
      render:(text)=> formatNumber(text,selectedCountry)
    },
    {
      title: t("outlet.outlet"),
      dataIndex: "outlet.name",
      hidden: isOutletRole,
      render: (text, record) => (
        <Link
          to={`/outlets/${record.outlet?.id}/details`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              src={record.outlet?.image_url}
              type="link"
              alt="Variant"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            <span>{record.outlet?.name}</span>
          </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.vendor"),
      dataIndex: "vendor.logo",
      hidden: isVendorOrOutlet,
      render: (text, record) => (
        <Link
          to={`/vendor/${record.vendor.id}/view`}
          className=" d-flex align-items-center edit-icon p-2"
        >
          <div className="d-flex align-items-center">
            <ImageWithBasePath
              src={record.vendor?.logo_url}
              type="link"
              alt="Variant"
              style={{ width: 30, height: 30, marginRight: 10 }}
            />
            <span>{record.vendor?.business_name}</span>
          </div>
        </Link>
      ),
    },
    {
      title: t("tableColumnName.purchaseRefernce"),
      dataIndex: "purchase_reference.id",
      render: (outlet, record) =>
        record.purchase ? record?.purchase?.order_number : null,
      sorter: true,
    },
    /*  {
      title: t("tableColumnName.createdBy"),
      dataIndex: "created_by",
      render: (text, record) => {
        return record.created_by?.name ?? "";
      },
    },
    {
      title: t("tableColumnName.createdAt"),
      dataIndex: "created_at",
      render: (text) => {
        return text ? moment(text).format("DD MMM YYYY - HH:mm:ss") : "";
      },
    }, */
  ];
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("product.product"), link: "/product-list" },
    { label: t("product.productDetails"), active: true },
  ];

  const onClickEditPrice = () => {
    const editPrice = document.querySelector(".btn-edit-price");
    if (editPrice) {
      editPrice.click();
    }
  };
  const onHideModal = async () => {
    await getProduct();
  };
  const calculateProfit = (salePrice, costPrice) => {
    const profitAmount = salePrice - costPrice;
    const profitPercentage = costPrice ? (profitAmount / costPrice) * 100 : 0;
    return {
      profitAmount: profitAmount.toFixed(2),
      profitPercentage: profitPercentage.toFixed(2),
    };
  };
  
  const salePrice = parseFloat(product?.price_line?.sale_price || 0);
  const costPrice = parseFloat(product?.cost_price || 0);
  
  const { profitAmount, profitPercentage } = calculateProfit(salePrice, costPrice);
  const data = [
    { field: t("inputField.label.EAN"), data: product?.catalog?.ean },
    { field: t("tableColumnName.sku"), data: product?.sku },
    { field: t("brand.brand"), data: product?.brand?.name },
    { field: t("inputField.label.productCategory"), data: product?.category?.name },
    {
      field: t("tableColumnName.minOrderQty"),
      data: product?.min_order_qty ?? 0,
    },
    {
      field: t("tableColumnName.availableQuantity"),
      data: product?.remaining_quantity ?? 0,
      classes:"fw-bold"
    },
    {
      field: t("tableColumnName.unitSalePrice"),
      data: formatNumber(product?.price_line?.sale_price,selectedCountry,2),
      update: product?.price_line ? true : false,
      onClick: () => onClickEditPrice(),
      classes:"fw-bold"
    },
    {
      field: t("tableColumnName.lastCostPrice"),
      data: formatNumber(product?.cost_price,selectedCountry,2),
    },
    {
      field: t("inputField.label.profitAmount"),
      data: formatNumber(profitAmount, selectedCountry, 2), // Profit as a fixed amount
    },
    {
      field: t("inputField.label.profitPercentage"),
      data: `${profitPercentage}%`, // Profit as a percentage
    },
    { field: t("inputField.label.receiptLabelText"), data: product?.receipt_label_text },
    { field: t("inputField.label.shortDescription"), data: product?.short_description },
    {
      field: t("inputField.label.productInformation"),
      data: product?.product_information,
    },
    { field: t("inputField.label.uomCode1"), data: product?.uom_code_1?.name },
    { field: t("inputField.label.uomCode1Value"),  data: product?.uom_code_1_value },
    { field: t("inputField.label.uomCode2"), data: product?.uom_code_2?.name },
    { field: t("inputField.label.uomCode2Value"), data: product?.uom_code_2_value },
    {
      field: t("inputField.label.VATableProduct"),
      data: product?.is_vat_product ? "Yes" : "No",
    },
    { field: t("inputField.label.vat" )+ " %", data: product?.vat },
    {
      field: t("inputField.label.securityableProduct"),
      data: product?.is_security_product ? "Yes" : "No",
    },
    { field: t("inputField.label.pledgeType"), data: product?.pledge_type?.material },
    { field: t("inputField.label.pledgePrice"), data: product?.pledge_type?.amount },
    {
      field: t("inputField.label.extraSecurityCheck"),
      data: product?.extra_security_check ? "Yes" : "No",
    },
    {
      field: t("inputField.label.compoundProduct"),
      data: product?.is_compound_product ? "Yes" : "No",
    },
    {
      field: t("inputField.label.DigitalTextLine") + " 1",
      data: product?.text_line_1,
    },
    {
      field: t("inputField.label.DigitalTextLine") + " 2",
      data: product?.text_line_2,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <Link
          style={{ visibility: "hidden" }}
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#edit-product-price"
          className="btn-edit-price"
        ></Link>
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex flex-column">
              <div className="page-title">
                <h3 className="text-black">{t("product.productDetails")}</h3>
              </div>
              <UnlimitBreadcrumb items={breadcrumbItems} />
            </div>
          </div>
          {pageLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            product?.catalog && (
              <>
                <div className="row">
                  <div className="col-md-9">
                    <div className="card container-fluid">
                      <div className="d-flex my-3 mx-2">
                        <h3 className="me-3">{product?.name}</h3>
                        <span
                          className={`badge ${
                            !isActive ? "badge-linesuccess" : "badge-danger"
                          }`}
                        >
                          <a>{!isActive ? "Active" : "Deactive"}</a>
                        </span>
                      </div>
                      {data.map(({ field, data, update, onClick, classes }) => (
                        <UnlimitFieldDetailsRow
                          key={field}
                          heading={field}
                          update={update}
                          details={data}
                          onClick={() => onClick()}
                          classes={classes}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card">
                      <UnlimitImageGallery
                        urls={{
                          file: product?.main_product_image,
                          is_active: 1,
                        }}
                        isDefaultValue={product?.main_product_image}
                        register={register}
                        disabled={true}
                        totalLength={product?.product_images?.length}
                        navigateTo={`/product/${product?.id}/image-details`}
                      />
                    </div>
                    <div className="card">
                    {Object.keys(product?.three_d_files || {}).length >= 3 && (
                      <ThreedFileWindowIphone height={"400px"} id={id} files={product?.three_d_files}/>
                    )}
                    </div>
                  </div>
                </div>
              </>
            )
          )}
          {purchaseHistoryLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            purchaseHistory && (
              <>
                <div className="page-title">
                  <h4 className="mb-2">{t("purchase.purchaseHistory")}</h4>
                </div>
                <div className="table-responsive">
                  <UnlimitTable
                    pagination={purchasePaginationParams.pagination}
                    columns={columns}
                    dataSource={purchaseHistory}
                    rowKey={(record) => record.id}
                    onChange={handlePurchaseTableChange}
                    />
                </div>
              </>
            )
          )}
          {distributionLoading ? (
            <div className="row d-flex justify-content-center">
              <UnlimitLoading width={50} height={50} />
            </div>
          ) : (
            distribution && (
              <>
                <div className="page-title">
                  <h4 className="my-3">
                    {t("distribution.stockdistributions")}
                  </h4>
                </div>
                <div className="table-responsive">
                  <UnlimitTable
                   pagination={distributionPaginationParams.pagination}
                    columns={distributionColumns}
                    dataSource={distribution}
                    rowKey={(record) => record.id}
                    onChange={handleDistributionTableChange}
                  />
                </div>
              </>
            )
          )}
        </div>
        <ProductSalePriceDialog
          onHide={onHideModal}
          salePrice={product?.price_line?.sale_price ?? 0}
          id={product?.price_line?.id}
        />
      </div>
    </>
  );
}
