import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

const UnlimitSelect = (props) => {
  const {
    label,
    id,
    rules,
    errors,
    options,
    placeholder,
    control,
    register,
    optionLabel,
    optionValue,
    onChange,
    defaultValue,
    isLoading,
    showLogo,
    ...attrs
  } = props;
  const { t } = useTranslation();
  const formatOptionLabel = ({ value, label, iso2, logo,image }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {showLogo && (
        <img
          src={logo ? logo : image}
          alt=""
          style={{ width: 20, height: 20, marginRight: 10 }}
        />
      )}
      <ReactCountryFlag
        style={{
          width: "24px",
          height: "16px",
        }}
        svg
        className="emojiFlag"
        countryCode={iso2}
      />
      <span style={{ marginLeft: 10 }}>{label}</span>
    </div>
  );
  const mappedOptions = options?.map((item) => {
    return {
      ...item,
      label: optionLabel ? item[optionLabel] : item.label,
      value: optionValue ? item[optionValue] : item.value,
    };
  });
  const handleSelectChange = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <div className="unlimit-uikit-select">
      {label && <label className="form-label" htmlFor={id}>
        {label}
      </label>
      }
      <Controller
        name={id}
        control={control}
        register={register}
        rules={rules}
        render={({ field }) => (
          <>
            <Select
              {...attrs}
              name={id}
              id={id}
              {...field}
              onChange={(value) => {
                handleSelectChange(value);
                field.onChange(value);
              }}
              className="select"
              options={mappedOptions}
              placeholder={placeholder}
              isLoading={isLoading}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={({inputValue}) => !inputValue ? t("general.prompts.noOptions") : t("general.prompts.noResultsFound")} 
            />
          </>
        )}
      />
      {errors[id] && <span className="error">{errors[id].message}</span>}
    </div>
  );
};

export default UnlimitSelect;
