import React, { useEffect, useRef, useState } from "react";
import UnlimitInput from "./ui-kit/unlimit-input";
import { useForm } from "react-hook-form";
import UnlimitToggle from "./ui-kit/unlimit-toggle";
import UnlimitSubmitButton from "./ui-kit/unlimit-submit-button";
import httpService from "../services/http/peoples/designation.service";
import notificationService from "../services/toaster.service";
import { store } from "../core/redux/store";
import { hydrateErrors } from "../utils/error.utils";
import { useTranslation } from "react-i18next";

const DesignationDetail = ({ onHide, designation }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, setValue, setError, reset } =
    useForm();
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef();
  const { auth } = store.getState();
  const onSubmitForm = async (data) => {
    const user = auth.user;
    try {
      setIsLoading(true);
      const requestData = {
        name: data.name,
        is_active: data.is_active ? 1 : 0,
        created_by: user.id,
      };
      if (designation) {
        requestData.id = designation.id;
        await updateDestination(requestData);
      } else {
        await createDesignation(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const createDesignation = async (formData) => {
    try {
      const response = await httpService.createDesignation(formData, true);
      if (response && response.status === 200) {
        notificationService.successMessage(
          t("designation.designationCreateSuccess")
        );
        onHide();
        reset();
        modalHide();
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const updateDestination = async (formData) => {
    try {
      const response = await httpService.updateDesignation(formData, true);
      if (response && response.status === 200) {
        notificationService.successMessage(
          t("designation.designationUpdateSuccess")
        );
        onHide();
        reset();
        modalHide();
      }
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };
  const modalHide = () => {
    const cancelButton = document.querySelector(".btn-cancel");
    if (cancelButton) {
      cancelButton.click();
    }
  };
  useEffect(() => {
    if (designation) {
      setValue("name", designation.name);
      setValue("is_active", designation.is_active == 1 ? true : false);
    }
  }, [designation]);

  useEffect(() => {
    const modalElement = modalRef.current;
    const handleShow = () => {
      console.log("Handle show");
    };
    const handleHide = () => {
      reset();
      onHide();
    };
    modalElement.addEventListener("show.bs.modal", handleShow);
    modalElement.addEventListener("hide.bs.modal", handleHide);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShow);
      modalElement.removeEventListener("hide.bs.modal", handleHide);
    };
  }, [onHide]);
  return (
    <div>
      <div className="modal fade" id="add-department" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("designation.addDesignation")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <UnlimitInput
                            label={t("inputField.label.name")}
                            id="name"
                            type="text"
                            placeholder={t("inputField.placeholder.enterName")}
                            register={register}
                            rules={{
                              required: t("inputField.validation.required"),
                              pattern: {
                                value: /^[a-zA-Z\s]*$/,
                                message: t("inputField.validation.pattern"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("inputField.validation.maxLength", {
                                  value: 50,
                                }),
                              },
                            }}
                            error={errors.name}
                            errorMessage={errors.name?.message}
                          />
                        </div>
                      </div>
                      <div className="input-blocks m-0">
                        <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                          <UnlimitToggle
                            isChecked={designation ? false : true}
                            label={t("inputField.label.status")}
                            id="is_active"
                            register={register}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        {t("general.button.cancel")}
                      </button>
                      <UnlimitSubmitButton
                        label={
                          designation
                            ? t("general.button.update")
                            : t("general.button.submit")
                        }
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignationDetail;
