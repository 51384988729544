export const all_routes = {
  dashboard: "/",
  salesdashboard: "/sales-dashboard",
  error403: "/error-403",
  brandlist: "/brand-list",
  brandcreate: "/brand/create",
  brandedit: "/brand/:id/edit",
  categorylist: "/category-list",
  categoryCreate: "/category-create",
  categoryEdit: "/category/:id/edit",
  priceLinelist: "/price-line-list",
  priceLineCreate: "/price-line-create",
  priceLineEdit: "/price-line/:id/edit",
  uomList: "/uom-list",
  uomCreate: "/uom-create",
  uomEdit: "/uom/:id/edit",
  notificationList: "/notification-list",
  notificationCreate: "/notification-create",
  notificationEdit: "/notification/:id/edit",
  roleList: "/role-list",
  roleCreate: "/role-create",
  roleEdit: "/role/:id/edit",
  attributeList: "/attribute-list",
  attributeCreate: "/attribute-create",
  attributeEdit: "/attribute/:id/edit",
  designationList: "/designation-list",
  tagTypeList: "/tag-type-list",
  ProductTypeList: "/product-type-list",
  supplierList: "/supplier-list",
  supplierCreate: "/supplier-create",
  supplierEdit: "/supplier/:id/edit",
  supplierDetails: "/supplier/:id/details",
  employeesList: "/employees-list",
  employeesCreate: "/employees-create",
  employeesEdit: "/employees/:id/edit",
  employeesDetails: "/employees/:id/details",
  campaignList: "/campaign-list",
  campaignCreate: "/campaign-create",
  campaignEdit: "/campaign/:id/edit",
  campaignDetails: "/campaign/:id/details",
  pledgePriceList: "/pledge-price-list",
  pledgePriceCreate: "/pledge-price-create",
  pledgePriceEdit: "/pledge-price/:id/edit",
  CountryList: "/country-list",
  CountryCreate: "/country-create",
  CountryEdit: "/country/:id/edit",
  CountryDetails: "/country/:id/details",
  catalogList: "/catalog-list",
  gs1List: "/gs1-list",
  liveStream: "/live-stream",
  logList: "/logs-list",
  catalogCreate: "/catalog-create",
  catalogEdit: "/catalog/:id/edit",
  catalogDetails: "/catalog/:id/details",
  objectupload: "/catalog/:id/object-upload",
  catalogImageDetails: "/catalog/:id/image-details",
  productImageDetails: "/product/:id/image-details",
  productList: "/product-list",
  ProductFromCatalog: "/product-from-catalog",
  PriceLineFromPurchase: "/price-line-from-purchase",
  MainStockFromPurchase: "/main-stock-from-purchase",
  DistributionFromMainStock: "/distribution-from-main-stock",
  AddProductGroupList: "/add-product-group-list",
  productCreate: "/product-create",
  productEdit: "/product/:id/edit",
  productDetails: "/product/:id/details",
  outletsList: "/outlets-list",
  outletsCreate: "/outlets-create",
  outletsEdit: "/outlets/:id/edit",
  outletsDetails: "/outlets/:id/details",
  outletsLayout: "/outlets/:id/layout",
  salesList: "/sales-list",
  salesDetails: "/sales/:id/details",
  purchaseList: "/purchase-list",
  purchaseCreate: "/purchase-create",
  purchaseUpload: "/purchase-upload",
  purchaseEdit: "/purchase/:id/edit",
  purchaseDetails: "/purchase/:id/details",
  priceLineDetails: "/price-line/:id/details",
  ticketDetails: "/ticket/:id/details",
  ticketList: "/ticket-list",
  thirdPartyList: "/third-party-list",
  loyaltySettingsList: "/loyalty-settings-list",
  contactList: "/contact-list",
  contactCreate: "/contact-create",
  contactEdit: "/contact/:id/edit",
  userList: "/user-list",
  userCreate: "/user-create",
  userEdit: "/user/:id/edit",
  distributionList: "/distribution-list",
  distributionCreate: "/distribution-create",
  distributionEdit: "/distribution/:id/edit",
  outletStockList: "/outlet-stock-list",
  mainStockList: "/main-stock-list",
  mainStockCreate: "/main-stock-create",
  mainStockEdit: "/main-stock/:id/edit",
  variantList: "/variant-list",
  variantCreate: "/variant-create",
  variantEdit: "/variant/:id/edit",
  variantDetails: "/variant/:id/details",
  vendorList: "/vendor-list",
  vendorCreate: "/vendor-create",
  vendorEdit: "/vendor/:id/edit",
  vendorDetail: "/vendor/:id/view",
  vatList: "/vat-list",
  currencyList: "/currency-list",
  timezoneList: "/timezone-list",
  systemLanguageList: "/system-language-list",
  systemLanguageJson: "/system-language-Json/:id/:type/:vendorId",
  productGroupList: "/product-group-list",
  productGroupListIndex: "/product-group-list-index",
  productGroupCreate: "/product-group-create",
  productGroupEdit: "/product-group/:id/edit",
  productGroupDetail: "/product-group/:id/details",
  threeDCatalogViewExpanded: "3d-sample-catalog/:id/view",
  units: "/units",
  variantyattributes: "/variant-attributes",
  warranty: "/warranty",
  barcode: "/barcode",
  alerts: "/ui-alerts",
  accordion: "/ui-accordion",
  avatar: "/ui-avatar",
  badges: "/ui-badges",
  borders: "/ui-borders",
  buttons: "ui-buttons",
  buttonsgroup: "/ui-buttons-group",
  breadcrumb: "/ui-breadcrumb",
  cards: "/ui-cards",
  dropdowns: "/ui-dropdowns",
  colors: "/ui-colors",
  carousel: "/ui-carousel",
  navtabs: "/ui-nav-tabs",
  grid: "/ui-grid",
  images: "/ui-images",
  lightbox: "/ui-lightbox",
  media: "/ui-media",
  modals: "/ui-modals",
  offcanvas: "/ui-offcanvas",
  pagination: "/ui-pagination",
  typography: "/ui-typography",
  tooltip: "/ui-tooltips",
  draganddrop: "/ui-drag-drop",
  departmentgrid: "/department-grid",
  departmentlist: "/department-list",
  designation: "/designation",
  shift: "/shift",
  attendanceemployee: "/attendance-employee",
  clipboard: "/ui-clipboard",
  popover: "/ui-popovers",
  tablebasic: "/tables-basic",
  datatable: "/data-tables",
  basicinput: "/form-basic-inputs",
  checkboxradio: "/form-checkbox-radios",
  gridgutters: "/form-grid-gutters",
  formselect: "/form-select",
  fileupload: "/form-fileupload",
  formmask: "/form-mask",
  formhorizontal: "/form-horizontal",
  formvertical: "/form-vertical",
  floatinglabel: "/form-floating-labels",
  formvalidation: "/form-validation",
  select2: "/form-select2",

  toasts: "/ui-toasts",
  apexchart: "/chart-apex",
  chartjs: "/chart-js",

  subcategories: "/sub-categories",
  productdetails: "/product-details",
  editproduct: "/edit-product",
  expensecategory: "/expense-category",
  calendar: "/calendar",
  variantattributes: "/variant-attributes",
  qrcode: "/qrcode",
  // purchaselist: "/purchase-list",
  // purchaseorderreport: "/purchase-order-report",
  // purchasereturn: "/purchase-returns",

  socialauthendication: "/social-authentication",
  languagesettings: "/language-settings",
  invoicesettings: "/invoice-settings",
  printersettings: "/printer-settings",
  possettings: "/pos-settings",
  customfields: "/custom-fields",
  emailsettings: "/email-settings",
  smssettings: "/sms-gateway",
  otpsettings: "/otp-settings",
  gdbrsettings: "/gdpr-settings",
  paymentgateway: "/payment-gateway-settings",
  banksettingslist: "/bank-settings-list",
  banksettingsgrid: "/bank-settings-grid",
  taxrates: "/tax-rates",
  currencysettings: "/currency-settings",
  storagesettings: "/storage-settings",
  banipaddress: "/ban-ip-address",
  generalsettings: "/general-settings",
  securitysettings: "/security-settings",
  notification: "/notification",
  connectedapps: "/connected-apps",
  systemsettings: "/system-settings",
  companysettings: "/company-settings",
  localizationsettings: "/localization-settings",
  prefixes: "/prefixes",
  preference: "/preference",
  saleslist: "/sales-list",
  invoicereport: "/invoice-report",
  salesreturn: "/sales-returns",
  quotationlist: "/quotation-list",
  pos: "/pos",
  attendanceadmin: "attendance-admin",
  payslip: "/payslip",
  holidays: "/holidays",
  customers: "/customers",
  customersList: "/customers-list",
  CustomersDetails: "/customers/:id/details",
  suppliers: "/suppliers",
  storelist: "/store-list",
  managestock: "/manage-stocks",
  stockadjustment: "/stock-adjustment",
  stocktransfer: "/stock-transfer",
  salesreport: "/sales-report",
  purchasereport: "/purchase-report",
  inventoryreport: "/inventory-report",
  supplierreport: "/supplier-report",
  customerreport: "/customer-report",
  expensereport: "/expense-report",
  incomereport: "/income-report",
  taxreport: "/tax-report",
  profitloss: "/profit-loss-report",
  notes: "/notes",
  filemanager: "/file-manager",
  profile: "/profile",
  signin: "/signin",
  signintwo: "/login",
  signinthree: "/signin-3",
  register: "/register",
  registerTwo: "/register-2",
  registerThree: "/register-3",
  forgotPassword: "/forgot-password",
  forgotPasswordTwo: "/forget-password",
  forgotPasswordThree: "/forgot-password-3",
  resetpassword: "/reset-password",
  verifyOtp: "/verify-otp",
  resetpasswordTwo: "/reset-password-2",
  resetpasswordThree: "/reset-password-3",
  emailverification: "/email-verification",
  emailverificationTwo: "/email-verification-2",
  emailverificationThree: "/email-verification-3",
  twostepverification: "/two-step-verification",
  twostepverificationTwo: "/two-step-verification-2",
  twostepverificationThree: "/two-step-verification-3",
  lockscreen: "/lock-screen",
  error404: "/error-404",
  error500: "/error-500",
  blankpage: "/blank-page",
  comingsoon: "/coming-soon",
  undermaintenance: "/under-maintenance",
  users: "/users",
  rolespermission: "/roles-permissions",
  permissions: "/permissions",
  deleteaccount: "/delete-account",
  employeegrid: "/employees-grid",
  addemployee: "/add-employee",
  editemployee: "/edit-employee",
  coupons: "/coupons",
  myProfile: "/profile",
};
