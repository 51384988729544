import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { base_path } from "../../environment";

const ImageWithBasePath = (props) => {
  const fullSrc =
    props.type === "link" ? props.src : `${base_path}${props.src}`;

  const placeholderSrc =
    "http://adminapi.stg.unlimitretail.dk/images/default.jpg";

  const handleError = (e) => {
    e.target.src = placeholderSrc;
  };

  return (
    <div
      className={props.className}
      id={props.id}
      style={{
        width: props.width,
        height: props.height,
        ...props.style,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={fullSrc ?? placeholderSrc}
        alt={props.alt}
        style={{ 
          maxWidth: "100%", 
          maxHeight: "100%", 
          objectFit: "contain" // Ensures the image fits within the container without stretching
        }}
        onError={handleError}
      />
    </div>
  );
};

ImageWithBasePath.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  id: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
};

export default ImageWithBasePath;
