import { React, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import notificationService from "../../../services/toaster.service";
import authService from "../../../services/http/auth.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import { ArrowLeft } from "feather-icons-react/build/IconComponents";
import LanguageSelector from "../../../components/LanguageSelecter";

const Resetpassword = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const { register, handleSubmit, formState, watch, reset } = useForm();
  const password = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  password.current = watch("password", "");
  const token = new URLSearchParams(location.search).get("token");
  const { errors } = formState;

  const submitData = async (data) => {
    const formData = new FormData();
    formData.append("confirm_password", data.confirm_password);
    formData.append("new_password", data.password);
    try {
      setIsLoading(true);
      const res = await authService.setNewPassword(formData, token);
      if (res) {
        navigate(`${route.signintwo}`);
        notificationService.successMessage(res?.data?.message);
      }
      reset();
    } catch (e) {
      // notificationService.errorMessage("Invalid email or password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-wrapper">
      <div className="language-change-dropdown">
          <ul className="nav user-menu">
            <LanguageSelector />
          </ul>
        </div>
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-img-text">
            <ImageWithBasePath
              src="assets/img/Logo01.png"
              className="login-logo-img"
              alt="img"
            />
            <p>
              Lorem Ipsum is simply dummy text of the printing and industry.
              Lorem Ipsum has been the industry&apos;s standar
            </p>
          </div>
          <div className="login-img">
            <ImageWithBasePath
              src="assets/img/authentication/login-screen.jpg"
              alt="img"
            />
          </div>
          <div className="login-content">
            <form
              className="form-login-width"
              onSubmit={handleSubmit(submitData)}
            >
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div className="login-userset">
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath
                      src="assets/img/logo-white.png"
                      alt="img"
                    />
                  </Link>
                  <div className="login-userheading">
                    <h3>Set New Password</h3>
                    <h4>Please enter your new password</h4>
                  </div>
                  <UnlimitInput
                    label="Password"
                    id="password"
                    type="password"
                    register={register}
                    rules={{
                      required: "You must specify a password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                    }}
                    error={errors.password}
                    errorMessage={errors.password?.message}
                  />
                  <UnlimitInput
                    label="Confirm Password"
                    id="confirm_password"
                    type="password"
                    register={register}
                    rules={{
                      validate: (value) =>
                        value === password.current ||
                        "The passwords do not match",
                    }}
                    error={errors.confirm_password}
                    errorMessage={errors.confirm_password?.message}
                  />
                  <div className="form-login">
                    <button className="btn btn-login" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
