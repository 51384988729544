import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/products/pledge-price.service";
import UOMHttpService from "../../../services/http/products/uom.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitTextarea from "../../../components/ui-kit/unlimit-textarea";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useTranslation } from "react-i18next";

const PledgePriceForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [UOMLoading, setUOMLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const [UOM, setUOM] = useState([]);

  async function fetchUOM() {
    const UOM = await UOMHttpService.uomList({is_active:1}).catch((error)=>{
      console.log(error)
    }).finally(()=>{
      setUOMLoading(false)
    });
    setUOM(UOM?.data?.data?.data);
  }

  const fetchPredefinedValues = async () => {
    if (id) {
      await getPledgePrice();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
    if (id) {
      getPledgePrice();
    }
    setUOMLoading(true)
    fetchUOM()
  }, [id]);

  const getPledgePrice = async () => {
    try {
      setPageLoading(true);
      const response = await httpService.getPledgePrice(id);
      if (response && response.status === 200) {
        const data = response.data.data;
        hydratePredefinedValues(data);
      }
    } finally {
      setPageLoading(false);
    }
  };

  const hydratePredefinedValues = (data) => {
    setValue("material", data.material);
    setValue("uom_value", data.uom_value);
    setValue("uom_id", {
      ...data.uom,
      label: data.uom?.name,
      value: data.uom?.id,
    });
    setValue("amount", data.amount);
    setValue("email", data.email);
    setValue("is_active", data.is_active == 1 ? true : false);
    setValue("description", data.description);
  };

  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const requestData = {};
      if (dirtyFields.material) requestData.material = data.material;
      if (dirtyFields.uom_value) requestData.uom_value = data.uom_value;
      if (dirtyFields.amount) requestData.amount = data.amount;
      requestData.is_active = data.is_active ? 1 : 0;
      if (dirtyFields.uom_id) requestData.uom_id = data.uom_id.id;

      if (id) {
        requestData.id = id;
        await httpService
          .updatePledgePrice(requestData,true)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(
                t("pledge.pledgeUpdateSuccess")
              );
              setTimeout(() => {
                navigate(all_routes.pledgePriceList);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createPledgePrice(requestData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createPledgePrice = async (formData) => {
    try {
      await httpService
        .createPledgePrice(formData,true)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(t("pledge.pledgeCreateSuccess"));
            navigate(all_routes.pledgePriceList);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
    } catch (error) {
      if (error.validation_error.errors) {
        hydrateErrors(error.validation_error.errors, setError);
      }
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("pledge.pledge"), link: "/pledge-price-list" },
    {
      label: id ? t("pledge.editPledge") : t("pledge.addPledge"),
      active: true,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">
                {id ? t("pledge.editPledge") : t("pledge.addPledge")}
              </h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("tableColumnName.material")}
                        id="material"
                        type="text"
                        placeholder={t(
                          "inputField.placeholder.enterMaterialName"
                        )}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          pattern: {
                            value: /^[0-9a-zA-Z,-\s]*$/,
                            message: t(
                              "inputField.validation.onlyNumaricValues"
                            ),
                          },
                        }}
                        error={errors.material}
                        errorMessage={errors.material?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      <UnlimitSelect
                        label={t("UOM.UOM")}
                        id="uom_id"
                        errors={errors}
                        control={control}
                        isLoading={UOMLoading}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                        }}
                        placeholder={t("inputField.placeholder.select")}
                        options={UOM}
                        optionLabel="name"
                        optionValue="id"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("tableColumnName.UOMValue")}
                        id="uom_value"
                        type="number"
                        placeholder={t("inputField.placeholder.enterUOMvalue")}
                        register={register}
                        rules={{
                          required: t("inputField.validation.required"),
                          maxLength: {
                            value: 50,
                            message: t("inputField.validation.maxLength", {
                              value: 50,
                            }),
                          },
                          min: {
                            value: 0,
                            message: t("inputField.validation.minValue", {
                              min: 0,
                            }),
                          },
                        }}
                        error={errors.uom_value}
                        errorMessage={errors.uom_value?.message}
                      />
                    </div>
                    <div className="col-md-6">
                      <UnlimitInput
                        label={t("tableColumnName.Amount")}
                        id="amount"
                        type="number"
                        placeholder={t("inputField.placeholder.enterAmount")}
                        register={register}
                        rules={{
                          required: "This Field is required.",
                          maxLength: {
                            value: 8,
                            message: t("inputField.validation.maxLength", {
                              value: 8,
                            }),
                          },
                          min: {
                            value: 0,
                            message: t("inputField.validation.minValue", {
                              min: 0,
                            }),
                          },
                        }}
                        step="0.01"
                        error={errors.amount}
                        errorMessage={errors.amount?.message}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="input-blocks m-0">
                      <div className="status-toggle modal-status d-flex align-items-center">
                        <UnlimitToggle
                          label={t("inputField.label.status")}
                          id="is_active"
                          register={register}
                          column={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.pledgePriceList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PledgePriceForm;
