import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import UnlimitSubmitButton from "./ui-kit/unlimit-submit-button";
import httpService from "../services/http/products/product.service";
import { store } from "../core/redux/store";
import { hydrateErrors } from "../utils/error.utils";
import UnlimitSelect from "./ui-kit/unlimit-select";
import UnlimitToggle from "./ui-kit/unlimit-toggle";
import { useTranslation } from "react-i18next";
import ImageWithBasePath from "../core/img/imagewithbasebath";
import UnlimitTable from "./ui-kit/unlimit-table";
import usePagination from "../custom-hooks/usePagination";

const SensorDetail = ({ onHide, systemLanguage }) => {
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError,
    reset,
    control,
    getValues,
  } = useForm();
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(false);
  const [countries, setCountries] = useState([]);
  const modalRef = useRef();
  const { auth, locations } = store.getState();
  const { t } = useTranslation();
  const { paginationParams, updatePaginationParams } = usePagination({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) =>
        `${range[0]}-${range[1]} ${t("pagination.of")} ${total} ${t(
          "pagination.items"
        )}`,
    },
  });
  const onSubmitForm = async (data) => {
    const user = auth.user;
    try {
      setIsLoading(true);
      const requestData = {
        name: data.name,
        is_active: data.is_active ? 1 : 0,
        created_by: user.id,
        short_code: data.short_code,
        country_iso2: data.country_iso2.iso2,
      };
    } finally {
      setIsLoading(false);
    }
  };
  const modalHide = () => {
    const cancelButton = document.querySelector(".btn-cancel");
    if (cancelButton) {
      cancelButton.click();
    }
  };
  useEffect(() => {}, [systemLanguage]);
  useEffect(() => {
    setCountries(locations.countries);
    getProductList();
  }, []);
  useEffect(() => {
    const modalElement = modalRef.current;
    const handleShow = () => {
      console.log("Handle show");
    };
    const handleHide = () => {
      reset();
      onHide();
    };
    modalElement.addEventListener("show.bs.modal", handleShow);
    modalElement.addEventListener("hide.bs.modal", handleHide);

    return () => {
      modalElement.removeEventListener("show.bs.modal", handleShow);
      modalElement.removeEventListener("hide.bs.modal", handleHide);
    };
  }, [onHide]);
  const getProductList = async (
    pagination = paginationParams.pagination,
    filters,
    sorter = {}
  ) => {
    const data = {
      page: pagination?.current,
      per_page: pagination?.pageSize,
      ...filters,
      ...sorter,
    };
    setLoading(true);
    await httpService
      .productList(data)
      .then((response) => {
        if (response.status == 200) {
          setProducts(response.data.data?.data);
        }
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
            current: response.data.data.current_page,
            total: response.data.data.total,
            pageSize: response.data.data.per_page,
          },
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        updatePaginationParams({
          pagination: {
            ...paginationParams.pagination,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      title: t("inputField.label.EAN"),
      dataIndex: "ean",
      render: (text, record) => record?.catalog?.ean,
    },
    {
      title: t("tableColumnName.productName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <ImageWithBasePath
            type="link"
            src={record?.catalog?.main_product_image}
            alt="Product"
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          {record.catalog?.name}
        </div>
      ),
    },
  ];
  const handleTableChange = async (pagination, filters, sorter) => {
    filters = {};
    const nonNullFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => !!value)
    );
    if (sorter && sorter.field) {
      sorter = {
        sort_by: sorter.field,
        sort_direction: sorter.order == "ascend" ? "ASC" : "DESC",
      };
    }
    await updatePaginationParams({
      pagination,
      sorter,
    });
    await getProductList(pagination, nonNullFilters, sorter);
  };
  return (
    <div>
      <div className="modal fade" id="add-sensorDetail" ref={modalRef} >
        <div className="modal-dialog modal-dialog-centered d-flex justify-content-center">
          <div className="modal-content" style={{minWidth:"60vw"}}>
            <div className="page-wrapper-new p-0">
              <div className="">
                <div className="modal-header border-0 custom-modal-header">
                  <div></div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="table-responsive">
                      <UnlimitTable
                        pagination={paginationParams.pagination}
                        columns={columns}
                        dataSource={products}
                        rowKey={(record) => record.id}
                        loading={loading}
                        onChange={handleTableChange}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        {t("general.button.cancel")}
                      </button>
                      <UnlimitSubmitButton
                        label={
                          systemLanguage
                            ? t("general.button.update")
                            : t("general.button.submit")
                        }
                        isLoading={isLoading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorDetail;
