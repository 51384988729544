import API from "../base.service";
import { ENDPOINT } from "../../constants.service";

const createCatalog = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.catalog.create}`,
    true,
    data,
    true
  );
};
const updateCatalog = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.catalog.update}`,
    true,
    data,
    true
  );
};
const add3DObject = async (id,data) => {
  return await API.postMethod(
    `${ENDPOINT.products.catalog.add3DObject}`.replace(":id", id),
    true,
    data,
    true
  );
};
const updateCatalogAttachment = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.catalog.updateAttachment}`,
    true,
    data,
    true
  );
};
const searchCatalog = async (data) => {
  return await API.postMethod(
    `${ENDPOINT.products.catalog.search}`,
    true,
    true,
    data
  );
};
const catalogList = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.catalog.list}`,
    true,
    true,
    data
  );
};
const catalogGS1List = async (data) => {
  return await API.getMethod(
    `${ENDPOINT.products.catalog.GS1list}`,
    true,
    true,
    data
  );
};
const deleteCatalog = async (id) => {
  const url = `${ENDPOINT.products.catalog.delete}`.replace(":id", id);
  return await API.deleteMethod(url, true);
};
const getCatalog = async (id) => {
  const url = `${ENDPOINT.products.catalog.show}`.replace(":id", id);
  return await API.getMethod(url, true);
};

const updateProductSalePrice = async (data, id) => {
  const url = `${ENDPOINT.products.catalog.updateSalePrice}`.replace(":id", id);
  return await API.patchMethod(url, true, data, false, "application/json");
};
export default {
  getCatalog,
  createCatalog,
  updateCatalog,
  catalogList,
  catalogGS1List,
  deleteCatalog,
  searchCatalog,
  add3DObject,
  updateCatalogAttachment,
  updateProductSalePrice,
};
