import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useForm } from "react-hook-form";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import authService from "../../../services/http/auth.service";
import notificationService from "../../../services/toaster.service";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/LanguageSelecter";

const ForgotpasswordTwo = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const { t,i18n  } = useTranslation();
  const { register, handleSubmit, formState, reset } = useForm();
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [captchaCompleted, setCaptchaCompleted] = useState(true);
  const submitData = async (data) => {
    const { email } = data;
    try {
      setIsLoading(true);
      const res = await authService.sendOtp(email);
      if (res) {
        localStorage.setItem("reset-password-email", email);
        navigate(`${route.verifyOtp}`);
        notificationService.successMessage(t('general.prompts.OTPsent'));
      }
      reset();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-wrapper">
      <div className="language-change-dropdown" style={{zIndex:99}}>
          <ul className="nav user-menu">
            <LanguageSelector />
          </ul>
        </div>
      <div className="account-content">
        <div className="login-wrapper">
          <div className="login-img-text">
            <ImageWithBasePath
              src="assets/img/Logo01.png"
              className="login-logo-img"
              alt={"img"}
            />
            <p>{t("general.dummyText")}</p>
          </div>
          <div className="login-img">
            <ImageWithBasePath
              src="assets/img/authentication/login-screen.jpg"
              alt="img"
            />
          </div>
          <div className="login-content">
            <form
              className="form-login-width"
              onSubmit={handleSubmit(submitData)}
            >
              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div className="login-userset">
                  <Link to={route.dashboard} className="login-logo logo-white">
                    <ImageWithBasePath src="assets/img/logo-white.png" alt />
                  </Link>
                  <div className="login-userheading">
                    <h3>{t('general.forgotPassword')}</h3>
                    <h4></h4>
                  </div>
                  <UnlimitInput
                    label={t('inputField.label.email')}
                    id="email"
                    type="email"
                    register={register}
                    rules={{
                      required: t("inputField.validation.required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("inputField.validation.emailPattern"),
                      },
                    }}
                    error={errors.email}
                    errorMessage={errors.email?.message}
                  />

                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={() => setCaptchaCompleted(true)}
                    onExpired={() => setCaptchaCompleted(false)}
                    asyncScriptOnLoad={() => setCaptchaCompleted(false)}
                    hl={i18n.language || 'en'}
                  />
                  <div className="form-login">
                    <button
                      type="submit"
                      className="btn btn-login"
                      disabled={isLoading || !captchaCompleted}
                    >
                      {t('general.button.submit')}
                    </button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="bg-white resend-otp-button border-0 mb-2 fs-6 fw-bold"
                      onClick={() => navigate(`${route.signintwo}`)}
                    >
                      {t('general.backToLogin')}
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotpasswordTwo;
