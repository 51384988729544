import React, { useState } from "react";
import { UploadCloud, X } from "feather-icons-react/build/IconComponents";
import httpService from "../../services/http/peoples/vendor.service";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useTranslation } from "react-i18next";
const UnlimitAttachments = (props) => {
  const {
    label,
    id,
    register,
    rules,
    error,
    errorMessage,
    accept,
    fileList,
    setFileList,
    existingImageUrlAttachments,
    setExistingImageUrlAttachments,
    showLogo,
    setButtonDisabled
  } = props;
  const { t } = useTranslation();
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    setFileList((prevFileList) => [...prevFileList, ...files]);
  };
  const MySwal = withReactContent(Swal);
  const handleRemoveExistingAttachment = async (index, id, event) => {
    setButtonDisabled(true)
    event.preventDefault();
    const shouldDelete = await showConfirmationAlert();
    if (shouldDelete) {
      try {
        await httpService.deletAttachment(id);
        const updatedList = [...existingImageUrlAttachments];
        updatedList.splice(index, 1);
        setExistingImageUrlAttachments(updatedList);
        Swal.fire({
          title: "Deleted!",
          text: "Attachment has been deleted successfully.",
          icon: "success",
        });
      } catch (error) {
        console.error("Error:", error);
        MySwal.fire({
          title: "Error!",
          text: "An error occurred while deleting the Attachment.",
          icon: "error",
        });
      } finally{
        setButtonDisabled(false)
      }
    }else(
      setButtonDisabled(false)
    )
  };

  const showConfirmationAlert = async () => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    });

    return result.isConfirmed;
  };
  const handleRemove = (index, event) => {
    event.preventDefault();
    const updatedList = [...fileList];
    updatedList.splice(index, 1);
    setFileList(updatedList);
  };

  return (
    <>
      <div className="unlimit-uikit-attachments">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        <div className="image-upload">
          <input
            id={id}
            {...register(id, rules)}
            type="file"
            accept={accept}
            onChange={handleChange}
            multiple
          />
          <div className="image-uploads">
            <UploadCloud className="text-primary" />
            <h4>{t("general.dragAndDrop")}</h4>
          </div>
        </div>
        <div>
          {error && (
            <span className="error" style={{ color: "red" }}>
              {errorMessage}
            </span>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-4 col-md-6">
          <ul className="file-list">
            {fileList?.length > 0 && (
              <>
                {fileList?.map((file, index) => (
                  <li key={index}>
                    <div className="file-info">
                    <div className="d-flex align-items-center">{showLogo && (
                        <ImageWithBasePath
                        type="link"
                          src={URL.createObjectURL(file)}
                          alt={file?.file?.split("attachment/")[1]}
                          style={{ width: 60, height: 60 }}
                        />
                      )}
                      <span className="ms-2">{file.name}</span></div>
                      <button
                        style={{ minWidth: "24px" }}
                        className="remove-button"
                        onClick={(event) => handleRemove(index, event)}
                      >
                        <X color="white" size={16} />
                      </button>
                    </div>
                  </li>
                ))}
              </>
            )}
            {existingImageUrlAttachments?.length > 0 && (
              <>
                {existingImageUrlAttachments?.map((file, index) => (
                  <li key={index}>
                    <div className="file-info">
                      <div className="d-flex align-items-center">{showLogo && (
                        <ImageWithBasePath
                          type="link"
                          src={file.file}
                          alt={file?.file?.split("attachment/")[1]}
                          style={{ width: 60, height: 60 }}
                        />
                      )}
                      <span className="ms-2">{file?.file?.split("attachment/")[1]}</span></div>{" "}
                      <button
                        className="remove-button"
                        style={{ minWidth: "24px" }}
                        onClick={(event) =>
                          handleRemoveExistingAttachment(index, file?.id, event)
                        }
                      >
                        <X color="white" size={16} />
                      </button>
                    </div>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UnlimitAttachments;
