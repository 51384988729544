import React, { useEffect, useState } from "react";
import httpService from "../../../services/http/user-managment/user.service";
import outletHttpService from "../../../services/http/stores/outlet.service";
import roleHttpService from "../../../services/http/user-managment/role.service";
import UnlimitInput from "../../../components/ui-kit/unlimit-input";
import UnlimitToggle from "../../../components/ui-kit/unlimit-toggle";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import notificationService from "../../../services/toaster.service";
import { Link } from "react-router-dom/dist";
import UnlimitSelect from "../../../components/ui-kit/unlimit-select";
import UnlimitSubmitButton from "../../../components/ui-kit/unlimit-submit-button";
import { hydrateErrors } from "../../../utils/error.utils";
import UnlimitLoading from "../../../components/ui-kit/unlimit-loading";
import UnlimitBreadcrumb from "../../../components/ui-kit/unlimit-breadcrumb";
import { useSelector } from "react-redux";
import UnlimitAttachments from "../../../components/ui-kit/unlimit-attachments";
import UnlimitProfilePicture from "../../../components/ui-kit/unlimit-profile-picture";
import { useTranslation } from "react-i18next";

const UserForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [existingImageUrl, setExistingImageUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    control,
    setError,
  } = useForm();
  const { errors } = formState;
  const [roles, setRoles] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const vendors = useSelector((state) => state.vendors.vendors);

  // Fetching countries and cities
  const fetchPredefinedValues = async () => {
    //   await getCountries();
    if (id) {
      await getUser();
    }
  };

  useEffect(() => {
    fetchPredefinedValues();
    if (id) {
      getUser();
    }
    fetchUserType();
    setRoleLoading(true)
  }, [id]);

  const getUser = async () => {
    try {
      setPageLoading(true);
      await httpService.getUser(id).then(async (response) => {
        if (response && response.status === 200) {
          const data = response.data.data;
          hydratePredefinedValues(data);
        }
      }).catch((error) => {
        console.error("Error:", error);
      })
    } finally {
      setPageLoading(false);
    }
  };

  // Hydrate form fields with predefined values
  const hydratePredefinedValues = (data) => {
    setValue("name", data.name);
    setValue("phone", data.phone);
    setValue("email", data.email);
    setValue("is_active", data.is_active == 1 ? true : false);
    if (data.roles[0]) {
      setValue("role", {
        ...data.roles[0],
        label: data.roles[0]?.name,
        value: data.roles[0]?.id,
      });
    }
    setExistingImageUrl(data.image);
  };

  // Handle form submission
  const onSubmitForm = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      !id && formData.append("password", data.password);
      !id &&
        formData.append("password_confirmation", data.password_confirmation);
      formData.append("role", data.role.id);
      formData.append("is_active", data.is_active ? 1 : 0);
      if (data.image[0]) {
        formData.append("image", data.image[0]);
      }
      if (id) {
        formData.append("id", id);
        await httpService
          .updateUser(formData)
          .then((response) => {
            if (response.status === 200) {
              notificationService.successMessage(t("user.userUpdateSuccess"));
              setTimeout(() => {
                navigate(all_routes.userList);
              }, 1000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            if (error.validation_error.errors) {
              hydrateErrors(error.validation_error.errors, setError);
            }
          });
      } else {
        await createUser(formData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Create new user
  const createUser = async (formData) => {
    try {
      await httpService
        .createUser(formData)
        .then((response) => {
          if (response && response.status === 200) {
            notificationService.successMessage(t("user.userCreateSuccess"));
            navigate(all_routes.userList);
          }
        })
        .catch((error) => {
          if (error.validation_error.errors) {
            hydrateErrors(error.validation_error.errors, setError);
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setImageUrl(URL.createObjectURL(files[0]));
    }
  };
  const handleRemoveImage = () => {
    setImageUrl("");
    const inputElement = document.getElementById("image");
    if (inputElement) {
      inputElement.value = "";
    }
    if (id) setExistingImageUrl("");
  };
  const breadcrumbItems = [
    { label: "", link: "/", icon: "feather-home" },
    { label: t("user.manageUsers"), link: "/user-list" },
    { label: id ? t("user.editUser") : t("user.addUser"), active: true },
  ];

  async function fetchUserType() {
    const roles = await roleHttpService.roleList().catch((error)=>console.log(error)).finally(()=>setRoleLoading(false));
    const activeRoles = roles?.data?.data?.data?.filter(
      (item) => item.is_active == 1,
    );
    setRoles(activeRoles);
  }

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex flex-column">
            <div className="page-title">
              <h3 className="text-black">{t("user.userManagment")}</h3>
            </div>
            <UnlimitBreadcrumb items={breadcrumbItems} />
          </div>
        </div>
        {/* User Form */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="card">
            <div className="card-body pb-0">
              {pageLoading ? (
                <div className="row d-flex justify-content-center">
                  <UnlimitLoading width={50} height={50} />
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <UnlimitInput
                            label={t("inputField.label.userName")}
                            id="name"
                            type="text"
                            placeholder={t(
                              "inputField.placeholder.enterUserName",
                            )}
                            register={register}
                            rules={{
                              required: t("inputField.validation.required"),
                              maxLength: {
                                value: 50,
                                message: t("inputField.validation.maxLength", {
                                  value: 50,
                                }),
                              },
                            }}
                            autoComplete="off"
                            error={errors.name}
                            errorMessage={errors.name?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <UnlimitInput
                            label={t("inputField.label.mobile")}
                            id="phone"
                            type="text"
                            placeholder={t(
                              "inputField.placeholder.enterMobileNo",
                            )}
                            register={register}
                            rules={{
                              required: t("inputField.validation.required"),
                              pattern: {
                                value: /^\+?\d+$/,
                                message: t(
                                  "inputField.validation.phonePattern",
                                ),
                              },
                              maxLength: {
                                value: 20,
                                message: t("inputField.validation.maxLength", {
                                  value: 20,
                                }),
                              },
                            }}
                            error={errors.phone}
                            errorMessage={errors.phone?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <UnlimitInput
                            label={t("inputField.label.email")}
                            id="email"
                            type="email"
                            placeholder={t("inputField.placeholder.enterEmail")}
                            register={register}
                            disabled={id === user.id}
                            rules={{
                              required: t("inputField.validation.required"),
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t(
                                  "inputField.validation.emailPattern",
                                ),
                              },
                            }}
                            error={errors.email}
                            errorMessage={errors.email?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <UnlimitSelect
                            label={t("role.role")}
                            id="role"
                            errors={errors}
                            control={control}
                            isLoading={roleLoading}
                            register={register}
                            options={roles}
                            isDisabled={id === user.id}
                            rules={{
                              required: t("inputField.validation.required"),
                            }}
                            placeholder={t("inputField.placeholder.ChooseRole")}
                            optionLabel="name"
                            optionValue="id"
                          />
                        </div>
                        {!id && (
                          <>
                            <div className="col-md-6">
                              <UnlimitInput
                                label={t("inputField.label.password")}
                                id="password"
                                type="password"
                                placeholder={t(
                                  "inputField.placeholder.enterPassword",
                                )}
                                register={register}
                                rules={{
                                  required: t("inputField.validation.required"),
                                  minLength: {
                                    value: 8,
                                    message: t(
                                      "inputField.validation.minLength",
                                      { value: 8 },
                                    ),
                                  },
                                }}
                                error={errors.password}
                                errorMessage={errors.password?.message}
                              />
                            </div>
                            <div className="col-md-6">
                              <UnlimitInput
                                label={t("inputField.label.confirmPassword")}
                                id="password_confirmation"
                                type="password"
                                placeholder={t(
                                  "inputField.placeholder.enterConfirmPassword",
                                )}
                                register={register}
                                rules={{
                                  required: t("inputField.validation.required"),
                                  minLength: {
                                    value: 8,
                                    message: t(
                                      "inputField.validation.minLength",
                                      { value: 8 },
                                    ),
                                  },
                                  validate: (value) =>
                                    value === getValues("password") ||
                                    t("inputField.validation.matchPassword"),
                                }}
                                error={errors.password_confirmation}
                                errorMessage={
                                  errors.password_confirmation?.message
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <UnlimitProfilePicture
                        label={t("inputField.label.profilePicture")}
                        id="image"
                        register={register}
                        rules={{
                          required: !id && t("inputField.validation.required"),
                        }}
                        error={errors.image}
                        errorMessage={errors.image?.message}
                        onChange={handleImageChange}
                        handleRemoveImage={handleRemoveImage}
                        eid={id}
                        imageUrl={imageUrl}
                        accept=".jpeg, .png, .jpg"
                        existingImageUrl={existingImageUrl}
                      />
                    </div>
                  </div>
                  <div className="input-blocks m-0 mt-3">
                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                      <UnlimitToggle
                        isChecked={id ? false : true}
                        label={t("tableColumnName.status")}
                        id="is_active"
                        register={register}
                        disabled={id === user.id}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4 mt-3">
                    <Link to={all_routes.userList} className="me-2">
                      <button type="button" className="btn btn-cancel">
                        {t("general.button.cancel")}
                      </button>
                    </Link>
                    <UnlimitSubmitButton
                      label={
                        id
                          ? t("general.button.update")
                          : t("general.button.submit")
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* User Form End */}
      </div>
    </div>
  );
};

export default UserForm;
