import React, { useState, useEffect, useCallback } from "react";
import UnlimitLoading from "./unlimit-loading";
import UnlimitToggle from "./unlimit-toggle-2";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { truncateMiddle } from "../../core/text/text.operations";

const UnlimitImageGallery = ({
  urls,
  updateImageStatus,
  totalLength,
  navigateTo,
  updateDefaultImage,
  isDefaultValue,
  disabled,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsActive(urls.is_active == 1 ? true : false);
    setIsDefault(isDefaultValue === urls.file ? true : false);
    // setIsLoading(true);
  }, [urls]);

  const handleStatusUpdate = (imageId, status, type) => {
    updateImageStatus(imageId, status, type);
  };

  const toggleActive = () => {
    const newActive = !isActive;
    setIsActive(newActive);
    handleStatusUpdate(urls.id, newActive, "isActive");
  };
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = useCallback(() => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to change this image as default?",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Confirm",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        toggleDefault();
      } else {
        MySwal.close();
      }
    });
  }, []);
  const toggleDefault = () => {
    const newDefault = !isDefault;
    setIsDefault(newDefault);
    updateDefaultImage(urls.id, newDefault, "isDefault");
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  const fileName = urls.name ? urls.name : urls.file.split("attachment/")[1];
  const truncatedFileName = fileName ? truncateMiddle(fileName, 20) : "";
  return (
    <div style={{ height: "100%" }}>
      {urls ? (
        <div
          className="d-flex flex-column justify-content-between"
          style={{ height: "100%" }}
        >
          <div className="d-flex justify-content-between px-3 pt-2">
            {!(disabled || isDefault) ? (
              <div className="d-flex align-items-center">
                <UnlimitToggle
                  id={`is_active_${urls.id}`}
                  column={true}
                  isActive={isActive}
                  toggleActive={toggleActive}
                  disabled={disabled || isDefault}
                />
                <p className="ms-2">Active</p>
              </div>
            ) : (
              <span
                className={`badge d-flex align-items-center justify-content-center py-2 ${
                  isActive ? "badge-linesuccess" : "badge-danger"
                }`}
              >
                <a>{isActive ? "Active" : "Deactive"}</a>
              </span>
            )}
            {!(disabled || isDefault) ? (
              <div className="d-flex align-items-center">
                <UnlimitToggle
                  id={`default_${urls.id}`}
                  column={true}
                  isActive={isDefault}
                  toggleActive={showConfirmationAlert}
                  disabled={disabled || isDefault}
                />
                <p className="ms-2">Default</p>
              </div>
            ) : (
              <span
                className={`badge d-flex align-items-center justify-content-center ${
                  isDefault ? "badge-linesuccess py-2" : ""
                }`}
              >
                <a>{isDefault ? "Default" : "non default"}</a>
              </span>
            )}
          </div>
          {isLoading && (
            <div className="d-flex justify-content-center my-5">
              <UnlimitLoading width={50} height={50} />
            </div>
          )}
          <div
            style={{ display: isLoading ? "none" : "flex", height: "100%" }}
            className="p-3 align-items-center justify-content-center"
          >
            <img
              src={urls.file}
              alt="Image"
              onLoad={handleImageLoad}
              style={{ display: isLoading ? "none" : "block",maxHeight:"30vh" }}
            />
          </div>
          <p className="d-flex align-items-center justify-content-center fw-bold fs-6 m-3 flex-wrap ">
            {truncatedFileName || "Default Image"}
          </p>
          {totalLength ?
            <p
              onClick={() => navigate(navigateTo)}
              className="text-center text-primary mb-2 cursor-pointer"
            >
              <u>View All ({totalLength})</u>
            </p>
          :<></>}
        </div>
      ) : (
        <p>No images available</p>
      )}
    </div>
  );
};

export default UnlimitImageGallery;
